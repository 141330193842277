import React from 'react'
export default function Datebox(props) {
  return (
    
    <div className='date-box border p-3 mt-4'>
        <div className='mb-3 label-headeing'>{props.datelabel}</div>
        <div className='d-flex alingn-items-center justify-content-center'>
        <span className='border p-2 d-grid border-box me-2'>
          <span>Clients</span>
          {props.numberlabel1}</span>
        <span className='border p-2 d-grid border-box me-2'>
        <span>NA</span>
          {props.numberlabel2}</span>
        <span className='p-2 d-grid border border-box'>
        <span>PD</span>
          {props.numberlabel3}</span>
          </div>
    </div>
  )
}
