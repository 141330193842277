import React, { useState, useEffect, useRef } from 'react'
import './assignmentstyle.css';
import { APIRequest, ACTUALSTOCK_MAPBOOKSTOCK } from '../../api';
import { toast } from 'react-toastify';
import { useNavigate, useParams } from 'react-router-dom';
import * as Constants from '../../Component/common/Global/constants';
import { logout, credit } from '../../redux/action';
import { useDispatch, useSelector } from 'react-redux';
import ReusableModal from '../Modal/reusablemodel';
import Bookstockitem from './Detailspage/bookstockitem';
import { isEmptyArray, isEmptyVariable } from '../../Component/common/Global/commonFunctions';
import { Col, Divider, Modal, Row } from 'antd';
import CommonButton from '../../commonComponent/button/commonButton';
import CommonTable from '../../commonComponent/table/commonTable';
import {CheckOutlined, UploadOutlined, DeleteOutlined, InfoCircleOutlined} from '@ant-design/icons';
import EditableTable from '../../commonComponent/table/editableTable';
import CommonModal from '../../commonComponent/modal/commonModal';

const ActualStockModal = ({ showActualStockModal, handleActualStockModalClose, bookStockDetails, clientName }) => {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const user = useSelector((state) => state?.user);
    const token = useSelector((state) => state.token);
    const { assignmentId } = useParams();
    const actualStockId = isEmptyArray(bookStockDetails?.ActualStockMap) ? bookStockDetails?.actualStockId : bookStockDetails?.ActualStockMap[0]?.actualStockId;
    const [actualStocksData, setActualStocksData] = useState({});
    const filesdata = actualStocksData?.uploadedFiles;
    const [submitButtonLoading, setSubmitButtonLoading] = useState(false);
    const [deleteButtonLoading, setDeleteButtonLoading] = useState(false);
    const [selectedFiles, setSelectedFiles] = useState([]);
    const [bookStockDetailsShow, setBookStockDetailsShow] = useState(false);
    const [uploadedFileId, setUploadedFileId] = useState();
    const [deleteUplodedFilesConfirmDialog, setDeleteUplodedFilesConfirmDialog] = useState(false);
    const [addActualStockConfirmDialog, setAddActualStockConfirmDialog] = useState(false);
    const [addActualStockValue, setAddActualStockValue] = useState({});
    const [creditCheckMessage, setCreditCheckMessage] = useState("");
    const [deleteActualStockConfirm, setDeleteActualStockConfirmDialog] = useState(false);
    const [actualQty, setActualQty] = useState('');
    const [remarks, setRemarks] = useState('');
    const [showConfirmModal, setShowConfirmModal] = useState(false);
    const [deleteFileLoading, setDeleteFileLoading] = useState(false);
    const [confirmModalLoading, setConfirmModalLoading] = useState(false);
    const [actualQtyObj, setActualQtyObj] = useState({
        oldValue: '',
        newValue: null,
        tempValue: null
    });
    const [remarkObj, setRemarkObj] = useState({
        oldValue: '',
        newValue: null,
        tempValue: null
    });
    const [uploadedFileDescriptionArray, setUploadedFileDescriptionArray] = useState([{
        oldValue: '',
        newValue: null,
        tempValue: null
    }]);
    const [addDescriptionArray, setAddDescriptionArray] = useState([{
        oldValue: '',
        newValue: null,
        tempValue: null
    }]);
    const [editable, setEditable] = useState({
        dataIndex: '',
        key: ''
    });
    const actualStockValueRef = useRef();
    actualStockValueRef.current = addActualStockValue;

    // Fetch actual stock data when the modal is opened
    useEffect(() => {
        if (showActualStockModal){ 
            if((!isEmptyArray(bookStockDetails?.ActualStockMap) && bookStockDetails?.ActualStockMap[0]?.actualStockId) ||
            bookStockDetails?.actualStockId){
                handleClearData();
                getActualStocksData();
            }
        }
    }, [showActualStockModal]);

    // Fetch actual stock data from the API
    const getActualStocksData = () => {
        fetch(Constants.API_URL.getActualStocksOne, {
            method:"POST",
            mode:"cors",
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${token}`,
            },
            body: JSON.stringify({
                "bookStockId": bookStockDetails?.bookStockId,
                "actualStockId": actualStockId
            })
        })
        .then(response => { return response.json(); } )
        .then(data => {
            if(data.status === Constants.status.codeAccessTokenUnauthorized){
                localStorage.clear();
                navigate("/Login");
                dispatch(logout());
            }else if(data.status === Constants.status.success){
                let actualStockData = data?.data;
                setActualStocksData(actualStockData)
                setActualQty(actualStockData.actualStockQuantity);
                setRemarks(actualStockData.remarks);
                setActualQtyObj({
                    ...actualQtyObj,
                    oldValue: actualStockData.actualStockQuantity
                })
                setRemarkObj({
                    ...remarkObj,
                    oldValue: actualStockData.remarks
                })
                const updatedArray = actualStockData.uploadedFiles.map((item, index) => {
                    return { 
                        description: item.description,
                        uploadedFileId: item?.uploadedFileId ? item.uploadedFileId : '-',
                    };
                }).map(item => ({
                    description: item.description,
                    uploadedFileId: item.uploadedFileId,
                    oldValue: item.description,
                    newValue: null,
                    tempValue: null
                }));
                setUploadedFileDescriptionArray(updatedArray);
            }else{
                toast.error(`${data?.message}`);
            }
        });
    }

    // Delete uploaded file by uploadedFileId
    const deleteUplodedFiles = () => {
        setDeleteFileLoading(true);
        fetch(Constants.API_URL.uploadedFiles + `${uploadedFileId}`, {
            method:"DELETE",
            mode:"cors",
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${token}`,
            },
            body: JSON.stringify({
                "bookStockId": bookStockDetails?.bookStockId,
                "actualStockId": actualStockId,
            })
        })
        .then(response => { return response.json(); } )
        .then(data => {
            if(data.status === Constants.status.codeAccessTokenUnauthorized){
                localStorage.clear();
                navigate("/Login");
                dispatch(logout());
            }else if(data.status === Constants.status.success){
                toast.success(`${data?.data}`);
                setDeleteUplodedFilesConfirmDialog(false);
                getActualStocksData();
                setDeleteFileLoading(false);
            }else{
                toast.error(`${data?.message}`);
                setDeleteUplodedFilesConfirmDialog(false);
                setDeleteFileLoading(false);
            }
        });
    }

    // Delete actual stock
    const deleteActualStock = () => {
        setDeleteButtonLoading(true);
        fetch(Constants.API_URL.deleteActualStock, {
            method:"POST",
            mode:"cors",
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${token}`,
            },
            body: JSON.stringify({
                userId: user.userId,
                assignmentId: parseInt(assignmentId),
                bookStockId: bookStockDetails?.bookStockId,
                actualStockId: actualStockId
            })
        })
        .then(response => { return response.json(); } )
        .then(data => {
            if(data.status === Constants.status.codeAccessTokenUnauthorized){
                localStorage.clear();
                navigate("/Login");
                dispatch(logout());
            }else if(data.status === Constants.status.success){
                toast.success(`${data?.message}`);
                setDeleteButtonLoading(false);
                setDeleteActualStockConfirmDialog(false);
                handleActualStockModalClose(true);
                handleClearData();
            }else{
                setDeleteButtonLoading(false);
                toast.error(`${data?.message}`);
            }
        });
    }

    // Generate file URL for download
    const generateFileUrl = (fileName) => {
        fetch(Constants.API_URL.generateFileUrl, {
            method:"POST",
            mode:"cors",
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${token}`,
            },
            body: JSON.stringify({
                fileName:fileName,
                type:Constants.fileType.actuals
            })
        })
        .then(response => { return response.json(); } )
        .then(data => {
            if(data.status === Constants.status.codeAccessTokenUnauthorized){
                localStorage.clear();
                navigate("/Login");
                dispatch(logout());
            }else if(data.status === Constants.status.success){
                const link = document.createElement('a');
                link.href = data.data;
                document.body.appendChild(link);
                link.click();
                document.body.removeChild(link);
            }else{
                toast.error(`${data?.message}`);
            }
        });
    }

    // Handle submit disable/enable logic based on validation criteria
    const handleSubmitDisabled = () => {
        let isValidate = false;
        if(actualQtyObj.tempValue != null){
            isValidate = true;
        }else if(remarkObj.tempValue != null){
            isValidate = true;
        }else if(uploadedFileDescriptionArray.filter(item => item.tempValue != null).length > 0){
            isValidate = true;
        }else if(addDescriptionArray.filter(item => item.tempValue != null).length > 0){
            isValidate = true;
        }

        if(isValidate){
            return true;
        }
    }

    // Handle form submission
    const handleSubmit = (flag) => {
        let isError = false;
        let errorMessage = "";

        if(isEmptyVariable(actualQty)){
            isError = true;
            errorMessage = "Enter Actual Stock";
        }
        // If there's an error, show it and stop the process
        if (isError) {
            toast.error(errorMessage);
        }else{
            if(!flag){
                setSubmitButtonLoading(true);
            }
            let finalValue = {
                userId: user.userId, 
                assignmentId: parseInt(assignmentId), 
                organisationId : user?.organisationId,
                actualStockQuantity: actualQty
            }
            if(!isEmptyVariable(actualStocksData?.actualStockId)){
                finalValue.actualStockId = actualStocksData?.actualStockId
            }
            setAddActualStockValue(finalValue);
            organisationCreditCheck(finalValue);
        }
    }

    // Organization credit check before adding actual stock
    const organisationCreditCheck = (finalValue) => {
        fetch(Constants.API_URL.organisationCreditCheck, {
            method:"POST",
            mode:"cors",
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${token}`,
            },
            body: JSON.stringify(finalValue)
        })
        .then(response => { return response.json(); } )
        .then(data => {
            if(data.status === Constants.status.codeAccessTokenUnauthorized){
                localStorage.clear();
                navigate("/Login");
                dispatch(logout());
            }else if(data.status === Constants.status.success){
                setSubmitButtonLoading(false);
                setConfirmModalLoading(false);
                if(data?.isDisplay == "Y"){
                    setAddActualStockConfirmDialog(true);
                }else if(data?.isDisplay == "N"){
                    addActualStockData();
                }
                setCreditCheckMessage(data.message);
            }else{
                setSubmitButtonLoading(false);
                setConfirmModalLoading(false);
                handleClearData();
                setAddActualStockConfirmDialog(false);
                toast.error(`${data?.message}`);
            }
        });
    }
    
    // Add actual stock data to the API
    const addActualStockData = () => {
        let values = actualStockValueRef.current;
        const filteredDescriptions = uploadedFileDescriptionArray.filter(item => item.description !== "" || item.uploadedFileId !== "");
        const mergedArray = [...addDescriptionArray, ...filteredDescriptions];
        const description = mergedArray.map(({ description, uploadedFileId }) => ({
            description,
            uploadedFileId
        }));
        var stockFormData = new FormData();
        stockFormData.append('userId', user?.userId);
        stockFormData.append('assignmentId', assignmentId);
        stockFormData.append('bookStockId', bookStockDetails?.bookStockId);
        stockFormData.append('actualStockQuantity', values.actualStockQuantity ?? "");
        stockFormData.append('remarks', remarks);
        selectedFiles.forEach((file) => {
            stockFormData.append('actualStockFile', file);
        });
        stockFormData.append("descriptions", JSON.stringify(description));
        stockFormData.append('isSample', bookStockDetails?.isSample);
        if(!isEmptyVariable(values?.actualStockId)){
            stockFormData.append('actualStockId', values.actualStockId);
        }
        stockFormData.append('organisationId', user?.organisationId)
        
        new APIRequest.Builder()
            .post()
            .setReqId(ACTUALSTOCK_MAPBOOKSTOCK)
            .jsonParams(stockFormData)
            .reqURL("bookstock/addActualstock")
            .response(onResponse)
            .error(onError)
            .build()
            .doRequest();
    }

    const onResponse = (response, reqId) => {
        switch (reqId) {
            case ACTUALSTOCK_MAPBOOKSTOCK:
                setSubmitButtonLoading(false);
                toast.success(`${response.data?.message}`);
                dispatch(credit(true));
                setAddActualStockConfirmDialog(false);
                handleActualStockModalClose(true);
                handleClearData();
                break;
            default:
                break;
        }
    };

    const onError = (response, reqId) => {
        switch (reqId) {
            case ACTUALSTOCK_MAPBOOKSTOCK:
                if (response.data?.status === Constants.status.codeAccessTokenUnauthorized) {
                    localStorage.clear();
                    navigate("/Login");
                    dispatch(logout());
                } else {
                    setSubmitButtonLoading(false);
                    setAddActualStockConfirmDialog(false);
                    toast.error(`${response?.data?.message}`)
                }
                break;
            default:
                break;
        }
    };

    // Handles updates the state variables based on the data index and record title.
    const handleValueChange = (dataIndex, tempValue, record) => {
        if(record.title == 'Actual Qty'){
            setActualQtyObj({
                ...actualQtyObj,
                tempValue: tempValue
            })
            setActualQty(tempValue);
        }
        if(record.title == "Remarks"){
            setRemarkObj({
                ...remarkObj,
                tempValue: tempValue
            });
            setRemarks(tempValue);
        }
        if(dataIndex == 'description'){
            const updatedArray = [...uploadedFileDescriptionArray];
            updatedArray[record.index].tempValue = tempValue;
            updatedArray[record.index].description = tempValue;
            setUploadedFileDescriptionArray(updatedArray);
        }
        if(dataIndex == 'addDescription'){
            const updatedArray = [...addDescriptionArray];
            updatedArray[record.index].tempValue = tempValue;
            updatedArray[record.index].description = tempValue;
            setAddDescriptionArray([...updatedArray]);
        }
    }

    // Handles value change in the editable table
    const handleSaveValue = (dataIndex, value, record) => {
        setEditable({
            dataIndex: '',
            key: ''
        })
        // Update state variables based on the cell value change
        if(record.title == 'Actual Qty'){
            setActualQtyObj({
                ...actualQtyObj,
                newValue: value,
                tempValue: null
            });
            setActualQty(value);
        }
        if(record.title == "Remarks"){
            setRemarkObj({
                ...remarkObj,
                newValue: value,
                tempValue: null
            });
            setRemarks(value);
        }
        if(dataIndex == 'description'){
            const updatedArray = [...uploadedFileDescriptionArray];
            updatedArray[record.index].description = value;
            updatedArray[record.index].newValue = value;
            updatedArray[record.index].tempValue = null;
            setUploadedFileDescriptionArray(updatedArray);
        }
        if(dataIndex == 'addDescription'){
            const updatedArray = [...addDescriptionArray];
            updatedArray[record.index].description = value;
            updatedArray[record.index].uploadedFileId = null;
            updatedArray[record.index].newValue = value;
            updatedArray[record.index].tempValue = null;
            setAddDescriptionArray(updatedArray);
        }
    };

    // Handles the close icon click event for the editable table
    const handleCloseIconClick = (dataIndex, record) => {
        setEditable({
            dataIndex: '',
            key: ''
        });
        if (record.title === 'Actual Qty') {
            setActualQtyObj({
                ...actualQtyObj,
                tempValue: null
            });
            if(actualQtyObj.newValue == null){
                setActualQty(actualQtyObj.oldValue);
            }else{
                setActualQty(actualQtyObj.newValue);
            }
        }
        if(record.title == "Remarks"){
            setRemarkObj({
                ...remarkObj,
                tempValue: null
            });
            if(remarkObj.newValue == null){
                setRemarks(remarkObj.oldValue);
            }else{
                setRemarks(remarkObj.newValue);
            }
        }
        if(dataIndex == 'description'){
            const updatedArray = [...uploadedFileDescriptionArray];
            updatedArray[record.index].tempValue = null;
            if(updatedArray[record.index].newValue == null){
                updatedArray[record.index].description = updatedArray[record.index].oldValue;
            }else{
                updatedArray[record.index].description = updatedArray[record.index].newValue;
            }
            setUploadedFileDescriptionArray(updatedArray);
        }
        if(dataIndex == 'addDescription'){
            const updatedArray = [...addDescriptionArray];
            updatedArray[record.index].tempValue = null;
            if(updatedArray[record.index].newValue == null){
                updatedArray[record.index].description = updatedArray[record.index].oldValue;
            }else{
                updatedArray[record.index].description = updatedArray[record.index].newValue;
            }
            setAddDescriptionArray(updatedArray);
        }
    }

    // Updates the state of the `editable` object.
    const handleEditableField = (dataIndex, key) => {
        setEditable({
            dataIndex: dataIndex,
            key: key
        })
    }
    
    // Remove unwanted characters from the file name
    function removeValueAfterHash(fileName) {
        const cleanedFilename = fileName?.replace(/-_-.+?\./, '.');
        return cleanedFilename;
    }

    // Delete uploaded file by uploadedFileId
    const deleteUploadedFile = (uplodedFileId) => {
        setUploadedFileId(uplodedFileId);
        setDeleteUplodedFilesConfirmDialog(true);
    }
    
    // Handle file upload change
    const handleFileUploadChange = (event) => {
        for (let i = 0; i < event.target.files.length; i++)
        addDescriptionArray.push({
            description: "",
            uploadedFileId: "",
            oldValue: "",
            newValue: null,
            tempValue: null
        });
        setSelectedFiles([...selectedFiles, ...event.target.files]);
    }

    // Handle selected file deletion
    const handleSelectedDeleteFile = (i) => {
        const selectedRow = [...selectedFiles];
        selectedRow.splice(i, 1);
        const description = [...addDescriptionArray];
        description.splice(i, 1);
        setAddDescriptionArray(description);
        setSelectedFiles(selectedRow);
    }

    // Clear all input fields
    const handleClearData = () => {
        setActualQty('');
        setRemarks('');
        setSelectedFiles([]);
        setAddDescriptionArray([]);
        setActualStocksData({});
        setUploadedFileDescriptionArray([]);
    }

    // Handle modal close
    const handleCloseModal = () => {
        // Check if there are unsaved changes and show confirmation dialog if necessary
        let isValidate = false;
        
        if(actualQtyObj.tempValue != null){
            isValidate = true;
        }else if(!isEmptyVariable(actualQtyObj.newValue) && (actualQtyObj.oldValue != actualQtyObj.newValue)){
            isValidate = true;
        }else if(remarkObj.tempValue != null){
            isValidate = true;
        }else if(!isEmptyVariable(remarkObj.newValue) && (remarkObj.oldValue != remarkObj.newValue)){
            isValidate = true;
        }else if(uploadedFileDescriptionArray.filter(item => item.tempValue != null).length > 0){
            isValidate = true;
        }else if(uploadedFileDescriptionArray.some(item => !isEmptyVariable(item.newValue) && item.oldValue !== item.newValue)){
            isValidate = true;
        }else if(addDescriptionArray.filter(item => item.tempValue != null).length > 0){
            isValidate = true;
        }else if(addDescriptionArray.some(item => !isEmptyVariable(item.newValue) && item.oldValue !== item.newValue)){
            isValidate = true;
        }
        
        if(isValidate){
            setShowConfirmModal(true);
        }else{
            handleCancel();
        }
    }

    // Handle cancel button click
    const handleCancel = () => {
        handleClearData();
        handleActualStockModalClose();
    }

    // Handles the confirmation modal's OK button click event.
    const handleConfirmModalOkButton = () => {
        setConfirmModalLoading(true);
        setShowConfirmModal(false);
        handleSubmit(true);
    }

    // Handles the confirmation modal's Cancel button click event.
    const handleConfirmModalCancelButton = () => {
        setShowConfirmModal(false);
        handleClearData();
        handleActualStockModalClose();
    }

    // Checks if a row is editable or not.
    const isRowEditable = (record) => {
        return bookStockDetails?.isActive == "true" && record.key != '2' && record.key != '3' &&
        (editable.key == '' || (editable.key == record.key && editable.dataIndex == "value"));
    };

    // Checks if a description is editable or not.
    const isDescriptionEditable = (record) => {
        return bookStockDetails?.isActive == "true" && 
        (editable.key == '' || (editable.key == record.key && editable.dataIndex == 'description'));
    };
    
    // Checks if a description is editable or not.
    const isAddDescriptionEditable = (record) => {
        return editable.key == '' || (editable.key == record.key && editable.dataIndex == 'addDescription');
    };

    const clientDataSource = [
        {
            key: '1',
            title: 'Client',
            value: clientName
        },
        {
            key: '2',
            title: 'Part Number',
            value: !isEmptyVariable(bookStockDetails?.partNo1) ? 
            bookStockDetails?.partNo1 + "  " : " - "
        },
        {
            key: '3',
            title: 'Material',
            value: bookStockDetails?.materialName,
            isLast: true
        }
    ];

    const bookQtyDataSource = [
        {
            key: '1',
            title: 'Book Qty',
            value: bookStockDetails?.quantity,
            bold: true
        },
        {
            key: '2',
            title: 'Sample',
            value: bookStockDetails?.isSample === "true" ?
            <CheckOutlined style={{color: '#552b84'}}/>: '-'
        }
    ];

    const actualQtyDataSource = [
        {
            key: '1',
            title: 'Actual Qty',
            value: actualQty,
            inputType: 'number',
            bold: true,
        },
        {
            key: '2',
            title: 'Reconciled Quantity',
            value: isEmptyVariable(actualStocksData?.reconcilationValue) 
            ? "-" : actualStocksData?.reconcilationValue
        },
        {
            key: '4',
            title: 'Remarks',
            inputType: 'text',
            value: remarks,
            editableTagName: 'textarea',
            width: 172
        }
    ];

    if(!isEmptyVariable(bookStockDetails?.quantity)){
        actualQtyDataSource.splice(2, 0, {
            key: '3',
            title: 'Difference',
            value: isEmptyVariable(actualQty) ? "-" : (actualQty - (bookStockDetails?.quantity) + 
            (isEmptyVariable(actualStocksData?.reconcilationValue) ? 0 : actualStocksData?.reconcilationValue))
        })
    }

    const columns = [
        {
            title: 'Title',
            dataIndex: 'title',
            key: 'title',
            width:'40%',
            render: (text, record) => (
                <div style={{display:'flex'}}>
                    <div className={record.bold ? 'heading-item-bold' : 'heading-item'}>
                        {record.title}
                    </div>
                    <span className='separator'>:</span>
                </div>
            )
        },
        {
            title: 'Value',
            dataIndex: 'value',
            key: 'value',
            editable: bookStockDetails?.isActive == "true",
            render: (text, record) => (
                <span className={record.bold ? 'value-bold' : ''} style={{flex:1}}>
                    {record.value} 
                    {record.isLast && 
                        <InfoCircleOutlined
                            onClick={() => setBookStockDetailsShow(true)}
                            style={{ 
                                marginLeft: 5,
                                verticalAlign: 'middle',
                                color: '#552b84',
                                fontSize: 14
                            }} 
                        />
                    }
                </span>
            ),
        },
    ];

    const actualQtyEditableColumn = columns.map((col) => {
        // Check if the current column is editable
        if (!col.editable) {
          return col;
        }
          // If the column is editable, return a new object
        return {
          ...col,
          onCell: (record, index) => ({
            record,
            editable: col.editable,
            dataIndex: col.dataIndex,
            title: col.title
          }),
        };
    });

    const uploadedFilesColumn = [
        {
            title:  'Uploaded File Name',
            key: 'fileName',
            width: '35%',
            ellipsis: true,
            render: (record) => {
                return(
                    <a
                        href="javascript:void(0);" 
                        onClick={() => {
                            generateFileUrl(record.uploadedFilePath)
                        }}
                        style={{ fontSize: "12px", color: "purple", textDecoration: "none", wordWrap: "break-word", textAlign: "left"}}>
                        {removeValueAfterHash(record.uploadedFilePath)}
                    </a>
                )
            }
        },
        {
            title:  'Description',
            key: 'description',
            dataIndex: 'description',
            editable: bookStockDetails?.isActive == "true",
            width: '50%'
        },
        {
            title: 'Action',
            key: 'delete',
            width: '15%',
            render: (record) => {
                return(
                    <CommonButton
                        style={{
                            backgroundColor: 'transparent',
                            boxShadow: '0 0 0 rgba(0, 0, 0, 0.045)'
                        }}
                        onClick={() => deleteUploadedFile(record.uploadedFileId)}
                        icon={<DeleteOutlined
                            style={{
                                verticalAlign: 'middle',
                                paddingLeft: 10,
                                color: '#000',
                                paddingRight: 10
                            }}
                        />}
                    />
                )
                
            }
        }
    ]

    const uploadedFileDescriptionColumn = uploadedFilesColumn.map((col) => {
        if (!col.editable) {
          return col;
        }
        return {
          ...col,
          onCell: (record) => ({
            record,
            editable: col.editable,
            dataIndex: col.dataIndex,
            title: col.title,
          }),
        };
    });

    const selectedFilesColumn = [
        {
            title:  'File Name',
            dataIndex: 'fileName',
            key: 'fileName',
            ellipsis: true,
            width: '35%',
        },
        {
            title:  'Description',
            key: 'addDescription',
            width: '50%',
            dataIndex:'addDescription',
            editable: true
        },
        {
            title:  'Action',
            key: 'delete',
            width: '15%',
            render: (record) => (
                <CommonButton
                    style={{
                        backgroundColor: 'transparent',
                        boxShadow: '0 0 0 rgba(0, 0, 0, 0.045)'
                    }}
                    onClick={() => handleSelectedDeleteFile(record?.index)}
                    icon={<DeleteOutlined
                        style={{
                            verticalAlign: 'middle',
                            paddingLeft: 10,
                            color: '#000',
                            paddingRight: 10
                        }}
                    />}
                />
            )
        }
    ]

    const selectedFileDecriptionColumn = selectedFilesColumn.map((col) => {
        if (!col.editable) {
          return col;
        }
        return {
          ...col,
          onCell: (record) => ({
            record,
            editable: col.editable,
            dataIndex: col.dataIndex,
            title: col.title,
          }),
        };
    });

    return (
        <>
            <Modal 
                open={showActualStockModal}
                title={"Actual Stock"}
                className="custom-modal"
                onCancel={handleCloseModal}
                footer={bookStockDetails?.isActive == "true" && [
                    <>
                        <CommonButton
                            label={'Cancel'}
                            onClick={() => handleCancel()}
                        />
                        <CommonButton
                            loading={submitButtonLoading}
                            label={'Submit'}
                            style={{opacity: handleSubmitDisabled() ? 0.7 : 1}}
                            disabled={handleSubmitDisabled()}
                            onClick={() => handleSubmit()}
                        />
                        {((!isEmptyArray(bookStockDetails?.ActualStockMap) && bookStockDetails?.ActualStockMap[0]?.actualStockId) || 
                            bookStockDetails?.actualStockId) &&
                        <CommonButton
                            label={'Delete'}
                            onClick={() => setDeleteActualStockConfirmDialog(true)}
                        />}
                    </>,
                ]}>
                <div className="table-container">
                    <CommonTable
                        dataSource={clientDataSource} 
                        columns={columns}
                        pagination={false}
                        showHeader={false}
                        size="small"
                        style={{ width: '280px' }}
                        className={'custom-borderless-table'}
                    />
                </div>
                <Divider type="horizontal"/>
                <div className="table-container">
                    <CommonTable
                        dataSource={bookQtyDataSource} 
                        columns={columns}
                        pagination={false}
                        showHeader={false}
                        size="small"
                        style={{ width: '280px' }}
                        className={'custom-borderless-table'}
                    />
                </div>
                <Divider type="horizontal" />
                <div className="table-container">
                    <EditableTable
                        dataSource={actualQtyDataSource} 
                        columns={actualQtyEditableColumn}
                        showHeader={false}
                        isRowEditable={isRowEditable}
                        handleValueChange={handleValueChange}
                        handleSaveValue={handleSaveValue}
                        handleCloseIconClick={handleCloseIconClick}
                        handleEditableField={handleEditableField}
                        style={{ width: '280px' }}
                        className={'custom-borderless-table custom-table-height'}
                    />
                </div>
                <Divider type="horizontal" />
                {bookStockDetails?.isActive == "true" && ( 
                    <Row style={{marginTop: 10}}>
                        <Col style={{marginLeft:'auto'}}>
                            <label htmlFor="uploadPhotoDocument">
                            <span className='btn btn-primary'>
                                <UploadOutlined
                                    style={{
                                        fontSize: '15px',
                                        verticalAlign: 'middle',
                                        marginRight:10
                                    }}
                                />Upload Photo / Document</span>
                            </label>
                            <input
                                type="file"
                                id="uploadPhotoDocument"
                                multiple
                                style={{ display: "none" }}
                                onChange={handleFileUploadChange}
                            />
                        </Col>
                    </Row>
                )}
                {!isEmptyArray(selectedFiles) && (
                    <EditableTable
                        dataSource={selectedFiles?.map((file, index) => {
                            return {
                                ...file,
                                key: index + 1,
                                index:index,
                                fileName: file.name,
                                addDescription: addDescriptionArray.find((item, itemIndex) => itemIndex == index)?.description,
                            }
                        })}
                        columns={selectedFileDecriptionColumn}
                        isRowEditable={isAddDescriptionEditable}
                        handleValueChange={handleValueChange}
                        handleSaveValue={handleSaveValue}
                        handleCloseIconClick={handleCloseIconClick}
                        handleEditableField={handleEditableField}
                        style={{marginTop: 15}}
                        className={'custom-table-height'}
                    />
                )}
                {!isEmptyArray(filesdata) &&
                    <EditableTable
                        dataSource={filesdata?.map((file, index) => {
                            return {
                                ...file,
                                key: index + 1,
                                index:index,
                                description: uploadedFileDescriptionArray.find((item, itemIndex) => itemIndex == index)?.description,
                            }
                        })}
                        columns={uploadedFileDescriptionColumn}
                        isRowEditable={isDescriptionEditable}
                        handleValueChange={handleValueChange}
                        handleSaveValue={handleSaveValue}
                        handleCloseIconClick={handleCloseIconClick}
                        handleEditableField={handleEditableField}
                        style={{marginTop: 15}}
                        className={'custom-table-height'}
                    />
                }
            </Modal>
            {bookStockDetailsShow &&
                <Bookstockitem
                    bookStockDetailsShow={bookStockDetailsShow}
                    setBookStockDetailsShow={setBookStockDetailsShow}
                    details={isEmptyVariable(bookStockDetails?.actualStockId) ? bookStockDetails : bookStockDetails.BookStock}
                    actualquantity={bookStockDetails?.actualquantity}
                />
            }
            {deleteUplodedFilesConfirmDialog &&
                <ReusableModal
                    show={deleteUplodedFilesConfirmDialog}
                    onClose={() => setDeleteUplodedFilesConfirmDialog(false)}
                    loading={deleteFileLoading}
                    onConfirm={deleteUplodedFiles}
                    message={"Are you sure, you want to delete this file?"}
                />
            }
            {addActualStockConfirmDialog &&
                <ReusableModal
                    show={addActualStockConfirmDialog}
                    onClose={() => {
                        setAddActualStockConfirmDialog(false);
                        handleActualStockModalClose();
                        handleClearData();
                    }}
                    buttonOk={"OK"}
                    buttonCancel={"Cancel"}
                    onConfirm={addActualStockData}
                    message={creditCheckMessage}
                />
            }
            {deleteActualStockConfirm &&
                <ReusableModal
                    show={deleteActualStockConfirm}
                    onClose={() => setDeleteActualStockConfirmDialog(false)}
                    loading={deleteButtonLoading}
                    onConfirm={deleteActualStock}
                    message={"Are you sure, You want to delete the actual stock? Data including Reco and uploaded files cannot be retrieved after deletion."}
                />
            }
            {showConfirmModal &&
                <CommonModal
                    onOpen={showConfirmModal}
                    handleRightButton={handleConfirmModalOkButton}
                    handleLeftButton={handleConfirmModalCancelButton}
                    rightButtonLoading={confirmModalLoading}
                    className={"custom-common-modal"}
                    content={"Changes has been made, Do you want to update the form?"}
                    rightButtonName={"Yes"}
                    leftButtonName={"No"}
                />
            }
        </>
    )
}

export default ActualStockModal;