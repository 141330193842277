import React from "react";
// import { useSelector } from "react-redux";
import { Navigate } from "react-router-dom";
import AppStore from "../../redux/store";

const PrivateRoute = ({ children }) => {

  const Token = AppStore.store.getState().token;

  return Token ? children : <Navigate to="/Login" />;
};
export default PrivateRoute;