import React from 'react'
export default function Monthbox(props) {
  return (
    
    <div className='month-box border p-3 mt-4'>
        <div className='mb-3 label-headeing'>{props.monthlabel}</div>
        <div className='d-flex alingn-items-center justify-content-center'>
        <span className='border border-box p-2 d-grid me-2'><span>Clients</span>{props.numberlabel1}</span>
        <span className='border p-2 d-grid border-box me-2'><span>NA</span>{props.numberlabel2}</span>
        <span className='p-2 d-grid border border-box'><span>PD</span>{props.numberlabel3}</span>
        </div>
    </div>
  )
}
