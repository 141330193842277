import React, { useEffect, useRef } from 'react';
import {Dropdown} from 'react-bootstrap';

const RegularDropDown = ({placeholder, dropdownArr, labelParam, onDropDownItemClick, isFocusRequired, show, onToggle, defaultPlaceholderDropDown}) => {
    const dropdownRef = useRef();

    const dropDownItemClick = (item) => {
        onDropDownItemClick(item);
    }

    useEffect(() => {
        dropdownRef.current.focus();
    },[isFocusRequired])

    return(
        <div className="regular-dropdown-layout">
            <Dropdown show={show} onToggle={onToggle}>
                <Dropdown.Toggle id="dropdown-basic" ref={dropdownRef}>
                    {
                        placeholder === defaultPlaceholderDropDown &&
                        <p className="placeholder-text default-val">{placeholder}</p>
                    }
                    {
                        placeholder !== defaultPlaceholderDropDown &&
                        <p className="placeholder-text" style={{overflow:'hidden'}}>{placeholder}</p>
                    }
                    <i className="fa fa-angle-down" aria-hidden="true" style={{fontSize: "18px"}}></i>
                </Dropdown.Toggle>
                <Dropdown.Menu>
                {
                    dropdownArr != "" &&
                    dropdownArr.map((item, i)=>{
                        return <Dropdown.Item onClick={() => dropDownItemClick(item)} href="javascript:void(0)" eventKey={item[labelParam]}>
                            <p className="dropdown-text">{item[labelParam]}</p>
                        </Dropdown.Item>
                    })
                }
                {
                    dropdownArr.length == 0 &&
                    <Dropdown.Item href="javascript:void(0)">
                        <p style={{textAlign:"center"}} className="dropdown-text">No Records</p>
                    </Dropdown.Item>
                }
                </Dropdown.Menu>
            </Dropdown>
            </div>
        )
}

export default RegularDropDown;