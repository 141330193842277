import React, { useState, useRef, useEffect } from 'react';
import CommonTable from './commonTable';
import { CheckCircleFilled, CloseCircleOutlined } from '@ant-design/icons';
import CommonButton from '../button/commonButton';

const EditableCell = ({
    title,
    editable,
    children,
    dataIndex,
    record,
    handleSaveValue,
    handleValueChange,
    handleCloseIconClick,
    handleEditableField,
    ...restProps
}) => {
    const inputType = record?.inputType || 'text';
    const [editing, setEditing] = useState(false);
    const [inputValue, setInputValue] = useState(record && record[dataIndex] !== undefined && record[dataIndex] !== "-" ? record[dataIndex] : '');
    const inputRef = useRef(null);
    const [hovered, setHovered] = useState(false);
    const [editedValue, setEditedValue] = useState('');

    useEffect(() => {
        if (editing && inputRef.current) {
            inputRef.current.focus();
        }
    }, [editing]);

    const handleEdit = () => {
        setEditing(true);
        handleEditableField(dataIndex, record.key);
        if (record[dataIndex] != "-") {
            setInputValue(record[dataIndex]);
            setEditedValue(record[dataIndex]);
        }
    };

    const handleInputChange = e => {
        const { value } = e.target;
        setInputValue(value);
        handleValueChange(dataIndex, value, record);
    };

    const save = () => {
        setEditing(false);
        setHovered(false);
        handleSaveValue(dataIndex, inputValue, record);
        setEditedValue('');
    };

    const handleClose = () => {
        setInputValue(editedValue || record[dataIndex]);
        handleCloseIconClick(dataIndex, record);
        setEditing(false);
        setHovered(false);
    }

    let childNode = children;
    if(editable){
        childNode = editing ? (
            <div style={{display:'flex', alignItems:'center'}}>
                {record?.editableTagName ?
                    <textarea 
                        className="form-control"
                        rows="3"
                        style={{width: 172, display:'flex'}}
                        name={dataIndex}
                        value={inputValue}
                        autoFocus={editing}
                        onChange={handleInputChange}
                    />
                :
                <input
                    type={inputType}
                    className="form-control"
                    name={dataIndex}
                    ref={inputRef}
                    style={{width: 150, display:'flex'}}
                    onWheel={e => e.currentTarget.blur()}
                    value={inputValue}
                    autoFocus={editing}
                    onChange={handleInputChange}
                />
                }
                <CommonButton
                    style={{
                        backgroundColor: 'transparent',
                        boxShadow: '0 0 0 rgba(0, 0, 0, 0.045)',
                        marginRight: 5,
                        marginLeft: 5
                    }}
                    onClick={save}
                    icon={<CheckCircleFilled
                        style={{
                            verticalAlign: 'middle',
                            color: '#552B84'
                        }}
                    />}
                />
                <CommonButton
                    style={{
                        backgroundColor: 'transparent',
                        boxShadow: '0 0 0 rgba(0, 0, 0, 0.045)'
                    }}
                    onClick={handleClose}
                    icon={<CloseCircleOutlined
                        style={{
                            verticalAlign: 'middle',
                            color: '#552B84'
                        }}
                    />}
                />
            </div>
        ) : (
            <td
                className={`editable-cell-value${record.editableTagName ? ' textarea' : ' input'}`}
                onMouseEnter={() => setHovered(true)}
                onMouseLeave={() => setHovered(false)}
            >
                <div style={{width: record.editableTagName? 172 : 150, justifyContent:'space-between', display:'flex', alignItems: 'center'}}>
                    <span style={{flex:1}}>{children}</span>
                    {hovered && !editing && (
                        <CommonButton
                            style={{
                                marginLeft:5,
                                marginRight:5,
                                backgroundColor: 'transparent',
                                boxShadow: '0 0 0 rgba(0, 0, 0, 0.045)'
                            }}
                            disabled={editing}
                            onClick={handleEdit}
                            icon={<i className="fa fa-pencil me-1" aria-hidden="true"
                                style={{
                                    verticalAlign: 'middle',
                                    height: 20,
                                    color: '#552B84'
                                }}
                                ></i>
                            }
                        />
                    )}
                </div>
            </td>
        );
    }
    return (
        <td {...restProps}>
            {childNode}
        </td>
    )
};

const EditableTable = ({ columns, dataSource, isRowEditable, handleSaveValue, handleValueChange, handleEditableField, handleCloseIconClick, pagination, showHeader, size, style, className }) => {
    const components = {
        body: {
            cell: EditableCell,
        },
    };

    const mergedColumns = columns.map(col => {
        if (!col.editable) {
            return col;
        }
        return {
            ...col,
            onCell: (record) => ({
                record,
                editable: (typeof isRowEditable === 'function' ? isRowEditable(record) : false),
                dataIndex: col.dataIndex,
                title: col.title,
                handleSaveValue: handleSaveValue,
                handleValueChange: handleValueChange,
                handleCloseIconClick: handleCloseIconClick,
                handleEditableField: handleEditableField
            }),
        };
    });

    return (
        <CommonTable
            components={components}
            rowClassName={'editable-row'}
            dataSource={dataSource}
            columns={mergedColumns}
            pagination={pagination ?? false}
            showHeader={showHeader}
            size={size ?? "small"}
            style={style}
            className={className}
        />
    );
};

export default EditableTable;
