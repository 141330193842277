import React from 'react';
import '../UploadBookStock/bookstyle.css'
import { useState } from 'react';
import {
    APIRequest, ADD_BOOKSTOCK_FILE_IN_QUEUE, ASSIGNMENT_STORE_LIST, STOCK_TYPE_LIST, COMMON_CREATE, COMMON_EDIT, CHECK_FILE_PROCESS_STATUS
} from '../../api'
import { toast } from 'react-toastify';
import { Button, Modal } from "react-bootstrap";
import { useFormik } from "formik";
import * as Yup from "yup";
import { useEffect } from 'react';
import { useNavigate, useParams, useLocation } from 'react-router-dom';
import moment from "moment";
import { useDispatch, useSelector } from 'react-redux';
import { logout } from '../../redux/action';
import Spinner from "react-bootstrap/Spinner";
import ReusableModal from '../Modal/reusablemodel';
import * as Constants from "../../Component/common/Global/constants";
import { isEmptyVariable } from '../../Component/common/Global/commonFunctions';
import { Col, Table, Row } from 'antd';
import Excel from "exceljs";
import { PlusCircleFilled, CloudUploadOutlined, ArrowRightOutlined, InfoCircleOutlined } from '@ant-design/icons';
import CommonButton from '../../commonComponent/button/commonButton';
import CommonDropdown from '../../commonComponent/dropdown/commonDropdown';
import Select from 'react-select';
import Bookstocktable from './bookstocktable';
import CommonLoader from '../../commonComponent/loader/commonLoader';
import CommonAlert from '../../commonComponent/alert/commonAlert';
import CommonModal from '../../commonComponent/modal/commonModal';

function UploadBookStock({assignment}) {
    const mappingOptions = {
        "Part Number": "partNo1",
        "Sub Part No. 1": "partNo2",
        "Sub Part No. 2": "partNo3",
        "Sub Part No. 3": "partNo4",
        "Sub Part No. 4": "partNo5",
        "Material Name": "materialName",
        "Material Sub Name 1": "materialName1",
        "Material Sub Name 2": "materialName2",
        "Material Sub Name 3": "materialName3",
        "Material Sub Name 4": "materialName4",
        "Location 1": "location1",
        "Location 2": "location2",
        "Location 3": "location3",
        "Location 4": "location4",
        "Location 5": "location5",
        "Last Receipt Date": "lastReceiptDate",
        "Last Issue Date": "lastIssueDate",
        "Expiry Date": "expiryDate",
        "Quantity": "quantity",
        "Rate": "rate",
        "Value": "value",
        "Remarks": "remarks",
        "Unit of Measurement": "uom",
    };
    const initialMappingValue = ["Part Number", "Material Name", "Location 1", "Unit of Measurement", "Quantity", "Rate", "Value", "Expiry Date", "Last Receipt Date", "Last Issue Date", "Remarks"]
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const sortedMapdata = initialMappingValue.map(value => (
        { 
            label: value, 
            value: mappingOptions[value] 
        }
    ))
    .sort((a, b) => a.label.localeCompare(b.label));
    const defaultFormatOption = Constants.format.find(e => e.value === 'dot');
    const defaultDateFormatOption = Constants.dateFormat.find(e => e.value === 'dd/mm/yyyy');
    const user = useSelector((state) => state.user);
    const token = useSelector((state) => state.token);
    const clientId = assignment.clientId;
    const { assignmentId } = useParams();
    const [mappingFieldForm, setMappingFieldForm] = useState(false)
    const [mappingHeader, setMappingHeader] = useState([]);
    const [mapData, setMapData] = useState(sortedMapdata);
    const [assignmentStoreList, setAssignmentStoreList] = useState([]);
    const [stockList, setStockList] = useState([]);
    const [rateId, setRateId] = useState();
    const [quantityId, setQuantityId] = useState();
    const [valueId, setValueId] = useState();
    const [lastRecipetFormat, setLastRecipetFormat] = useState();
    const [lastIssueFormat, setLastIssueFormat] = useState();
    const [expiryFormat, setExpiryFormat] = useState();
    const [selectedColumns, setSelectedColumns] = useState({});
    const [oldSelectedColumns, setOldSelectedColumns] = useState({});
    const [showStockTypeModal, setShowStockTypeModal] = useState(false);
    const [excelFile, setExcelFile] = useState({})
    const [stockId, setStockId] = useState('');
    const [loading, setLoading] = useState(false);
    const [uploadFileDataLoading, setUploadFileDataLoading] = useState(false);
    const [storeloading, setStoreLoading] = useState(false);
    const [disabled, setDisabled] = useState(false);
    const [isQtySelectedDialog, setIsQtySelectedDialog] = useState(false);
    const [bookStockMappingValue, setBookStockMappingValue] = useState({});
    const [modalButtonLoading, isModalButtonLoading] = useState(false);
    const [exampleRowData, setExampleRowData] = useState([]);
    const [errorAlertShow, isErrorAlertShow] = useState(false);
    const [bookStockFileInQueueSuccessMessage, isBookStockFileInQueueSuccessMessage] = useState(false);
    const [errors, setErrors] = useState("");
    const [storeId, setStoreId] = useState('');
    const [storeTypeName, setStoreTypeName] = useState('Select Store');
    const [stockTypeName, setStockTypeName] = useState('Select Stock');
    const [checkFileProcessStatusData, setCheckFileProcessStatusData] = useState({});
    const [componentDidMountFlag, isComponentDidMountFlag] = useState(false);
    const [dataLoading, isDataLoading] = useState(false);
    const [rulesModal, setRulesModal] = useState(false);
    const [tableHeight, setTableHeight] = useState(0);
    const [uploadFileAlertModal, setUploadFileAlertModal] = useState(false);

    // Fetches the list of assignments stores for a given assignmentId.
    const getAssignmentsStoreList = () => {
        new APIRequest.Builder()
            .get()
            .setReqId(ASSIGNMENT_STORE_LIST)
            .reqURL(`assignments/getAssignmentsStoreList/${assignmentId}`)
            .response(onResponse)
            .error(onError)
            .build()
            .doRequest()
    }

    // Retrieves a list of all stock types.
    const allStockTypeList = () => {
        new APIRequest.Builder()
            .get()
            .setReqId(STOCK_TYPE_LIST)
            .reqURL('stockType/allStockTypeList')
            .response(onResponse)
            .error(onError)
            .build()
            .doRequest();
    }

    // Checks the file processing status of an assignment.
    const checkFileProcessStatus = () => {
        isDataLoading(true);
        new APIRequest.Builder()
            .post()
            .setReqId(CHECK_FILE_PROCESS_STATUS)
            .reqURL('bookstock/checkFileProcessStatus')
            .jsonParams({ assignmentId: JSON.parse(assignmentId) })
            .response(onResponse)
            .error(onError)
            .build()
            .doRequest();
    }

    // Retrieves the total book stock count
    const getTotalBookStockCount = (excelFile) => {
        fetch(Constants.API_URL.getTotalBookStockCount, {
          method:"POST",
          mode:"cors",
          headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${token}`,
          },
          body: JSON.stringify({
            assignmentId: parseInt(assignmentId),
            userId: user.userId
          })
        })
        .then(response => { return response.json(); } )
        .then(data => {
          if(data.status === Constants.status.codeAccessTokenUnauthorized){
            localStorage.clear();
            navigate("/Login");
            dispatch(logout());
          }else if(data.status === Constants.status.success){
            excelFileExtract(excelFile,data.data.allBookStockDataCount);
          }else{
            toast.error(`${data?.message}`)
          }
        });
    }

    // Fetch the file process status, store list and stock type list data
    useEffect(() => {
        checkFileProcessStatus();
        getAssignmentsStoreList();
        allStockTypeList();
    }, []);

    // Function to extract data from an excel file
    const excelFileExtract = (excelfile, allBookStockDataCount) => {
        const workbook = new Excel.Workbook();
        workbook.xlsx.load(excelfile).then(() => {
            const worksheet = workbook.getWorksheet(1);
            let rowCount = 0;
            let header = [];
            let isFormulaContain = false;
            const exampleRowData = [];

            worksheet.eachRow((row) => {
                // Check if the row has any values
                if (row.hasValues) {
                    rowCount++;
                }
                // Store the header row data
                if(rowCount == 1){
                    let rowData = row.values.map((cell) => cell);
                    rowData.shift();
                    header = rowData;
                }
                // Store the example row data
                if(rowCount == 2 || rowCount == 3){
                    let rowData =[];
                    row.eachCell({ includeEmpty: true }, (cell, colNumber) => {
                        if (typeof cell.value === "object" && cell.value instanceof Date){
                            let excelFormat = cell.style.numFmt.toLocaleLowerCase();
                            if(excelFormat.includes("]")){
                                excelFormat = excelFormat.split("]")[1];
                                excelFormat = excelFormat.split('@')[0]
                                if(excelFormat.includes("/")){
                                    rowData.push(moment(new Date(cell.value)).format(excelFormat.replace(/\//g, "-").toLocaleUpperCase()).replace(/-/g, "/"))
                                }else{
                                    rowData.push(moment(new Date(cell.value)).format(excelFormat.toLocaleUpperCase()))
                                }
                            }else{
                                if(excelFormat.includes("/")){
                                    rowData.push(moment(new Date(cell.value)).format(excelFormat.replace(/\//g, "-").toLocaleUpperCase()).replace(/-/g, "/"))
                                }else{
                                    rowData.push(moment(new Date(cell.value)).format(excelFormat.toLocaleUpperCase()))
                                }
                            }
                        }else if (typeof cell.value === "object" && cell.value?.formula) {
                            isFormulaContain = true;
                        }else{
                             rowData.push(cell.value)
                        }
                        
                    })
                    exampleRowData.push(rowData)
                }
            });
            let totalRow = rowCount + allBookStockDataCount;

            // Check if the Excel file contains any formulas and display an error message if necessary
            if(isFormulaContain){
                setUploadFileDataLoading(false);
                clearUploadFileData();
                toast.warning("The uploaded Excel file contains formulas. Please upload an Excel file without any formulas.");
            }
            // Check if the Excel file contains at least two rows of data and display a warning if necessary
            else if(rowCount < 2){
                setUploadFileDataLoading(false);
                clearUploadFileData();
                toast.warning("The Excel file must contain at least two line items.")
            }
            // Check if the Excel file contains more than 30000 rows of data
            else if(totalRow > 30001){
                setUploadFileDataLoading(false);
                setUploadFileAlertModal(false);
                clearUploadFileData();
                toast.warning("Total line items exceeds the limit set for total number of inventory items. You cannot upload more than 30000 line items."); // Show a warning toast
            }
            // Set the header, example row data, and selected columns for mapping
            else{
                let columns = {};
                if (header.length) {
                    setMappingHeader([...header]);
                    header.forEach(h => {
                        columns[h] = "";
                    });
                }
                setSelectedColumns({ ...columns });
                setOldSelectedColumns({ ...columns });
                setUploadFileAlertModal(false);
                setMappingFieldForm(true);
                setUploadFileDataLoading(false);
            }
            setExampleRowData(exampleRowData);

        }).catch(error => {
            toast.warning("The file is corrupt and we are unable to upload the file. Kindly upload another file.");
            clearUploadFileData();
            setUploadFileDataLoading(false);
        });
    }

    // Function to clear the upload file data
    const clearUploadFileData = () => {
        setExcelFile({});
        setStoreTypeName('Select Store');
        setStockTypeName('Select Stock');
    }

    // Function to handle file upload for Excel files
    const fileUpload = () => {
        const input = document.createElement('input');
        input.type = 'file';
        input.accept = '.xlsx, .xls';
        input.click();

        input.addEventListener('change', (e) => {
            setMappingFieldForm(false);
            setUploadFileDataLoading(true);
            setMappingHeader([]);
            setSelectedColumns({});
            setDisabled(false);
        
            setMapData(initialMappingValue.map(value => ({
                label: value, value: mappingOptions[value] 
            })).sort((a, b) => a.label.localeCompare(b.label)));
            
            setRateId('');
            setValueId('');
            setQuantityId('');
            setExpiryFormat('');
            setLastIssueFormat('');
            setLastRecipetFormat('');
            e.preventDefault();
        
            var excelfile = e.target.files[0];
            const fileSizeInBytes = excelfile.size;
            const fileSizeInMB = fileSizeInBytes / (1024 * 1024);
            if (excelfile) {
                const allowExcelFile = ['xls', 'xlsx'];
                const fileExtension = e.target.files[0]?.name.split('.').pop().toLowerCase();
                // Check if the file is an allowed Excel file format
                if (!allowExcelFile.includes(fileExtension)) {
                    e.target.value = null;
                    setMappingHeader([]);
                    setSelectedColumns({});
                    clearUploadFileData();
                    setMappingFieldForm(false);
                    setUploadFileDataLoading(false);
                    toast.warning("Please upload a excel file.");
                    return;
                }else if (fileSizeInMB < 4) {
                    setExcelFile(excelfile);
                    getTotalBookStockCount(excelfile);
                }else if (fileSizeInMB >= 4 && fileSizeInMB <= 6) {
                    setUploadFileDataLoading(false);
                    setUploadFileAlertModal(true);
                    setExcelFile(excelfile);
                } else if (fileSizeInMB > 6) {
                    setUploadFileDataLoading(false);
                    clearUploadFileData();
                    toast.warning("A file bigger than 6mb cannot be accepted. Reduce the file size and keep the number of line items below 30,000 in total.");
                }
            } else {
                setUploadFileDataLoading(false);
            }
        });
    }

    const onResponse = (response, reqId) => {
        switch (reqId) {
            case ADD_BOOKSTOCK_FILE_IN_QUEUE:
                setLoading(false);
                setDisabled(false);
                setMappingFieldForm(false);
                isModalButtonLoading(false);
                setIsQtySelectedDialog(false);
                clearUploadFileData();
                isBookStockFileInQueueSuccessMessage(true);
                isComponentDidMountFlag(true);
                checkFileProcessStatus();
                break;
            case ASSIGNMENT_STORE_LIST:
                setAssignmentStoreList(response.data?.StoreData)
                break;
            case STOCK_TYPE_LIST:
                setStockList(response.data?.data?.records)
                break;
            case COMMON_CREATE:
                setStoreLoading(false);
                toast.success(response?.data?.message);
                setShowStockTypeModal(false);
                allStockTypeList();
                generalForm.resetForm();
                break;
            case CHECK_FILE_PROCESS_STATUS:
                if(response.data.data?.status == 'inQueue' || response.data.data?.status == 'inProcess' || response.data.data?.status == 'error'){
                    isBookStockFileInQueueSuccessMessage(true);
                }
                setCheckFileProcessStatusData(response.data.data);
                isComponentDidMountFlag(true);
                isDataLoading(false);
                break;
            default:
                break;
        }
    };

    const onError = (response, reqId) => {
        switch (reqId) {
            case ADD_BOOKSTOCK_FILE_IN_QUEUE:
                if (response.data?.status === Constants.status.codeAccessTokenUnauthorized) {
                    localStorage.clear();
                    navigate("/Login");
                    dispatch(logout());
                } else {
                    setLoading(false);
                    setDisabled(false);
                    isModalButtonLoading(false);
                    setIsQtySelectedDialog(false);
                    toast.error(`${response?.data?.message}`)
                }
                break;
            case ASSIGNMENT_STORE_LIST:
                if (response.data?.status === Constants.status.codeAccessTokenUnauthorized) {
                    localStorage.clear();
                    navigate("/Login");
                    dispatch(logout());
                } else {
                    
                }
                break;
            case STOCK_TYPE_LIST:
                if (response.data?.status === Constants.status.codeAccessTokenUnauthorized) {
                    localStorage.clear();
                    navigate("/Login");
                    dispatch(logout());
                } else {
                    
                }
                break;
            case COMMON_CREATE:
                if (response.data?.status === Constants.status.codeAccessTokenUnauthorized) {
                    localStorage.clear();
                    navigate("/Login");
                    dispatch(logout());
                    response?.data?.message && toast.error(`${response?.data?.message}`);
                    setStoreLoading(false);
                }else{
                    setStoreLoading(false);
                    setShowStockTypeModal(false);
                    response?.data?.message && toast.error(`${response?.data?.message}`);
                }
                break;
            case CHECK_FILE_PROCESS_STATUS:
                if (response.data?.status === Constants.status.codeAccessTokenUnauthorized) {
                    localStorage.clear();
                    navigate("/Login");
                    dispatch(logout());
                    response?.data?.message && toast.error(`${response?.data?.message}`);
                }else{
                    isDataLoading(false);
                    response?.data?.message && toast.error(`${response?.data?.message}`);
                }
                break;
            default:
                break;
        }
    };

    const generalForm = useFormik({
        enableReinitialize: true,
        initialValues: {
            stockTypeShortName: '',
            stockTypeName: '',
            clientId: clientId,
            organisationId: user?.organisationId,
            createdBy: user.userId,
            updatedBy: user.userId
        },
        validationSchema: Yup.object().shape({
            stockTypeShortName: Yup.string().required("Enter ShortName"),
            stockTypeName: Yup.string().required("Enter Name"),
        }),
        onSubmit: values => {
            setStoreLoading(true);
            let pageInfo = {};
            pageInfo.title = 'Stock';
            pageInfo.createApi = 'stockType/addStockType';
            pageInfo.editApi = 'stockType/updateStockType/:id';
            pageInfo.listApi = 'stockType/allStockTypeList';
            pageInfo.deleteApi = 'stockType/changeStockTypeIsActiveStatus/:id'
            const editUrl = false ? pageInfo.editApi.replace(":id", values.id) : '';
            new APIRequest.Builder()
                .post()
                .setReqId(true ? COMMON_CREATE : COMMON_EDIT)
                .jsonParams(values)
                .reqURL(true ? pageInfo.createApi : editUrl)
                .response(onResponse)
                .error(onError)
                .build()
                .doRequest();
        },
    });

    // This function handles the submission of the book stock form.
    const handleSubmit = (event) => {
        event.preventDefault();
        setDisabled(true);
        setLoading(true);

        let mappingColumns = {};
        Object.keys(selectedColumns).forEach(key => {
            mappingColumns[selectedColumns[key]] = key;
        })

        // Create an object to store the mapping of column names to their values
        var mapping = {
            "partNo1": mappingColumns["partNo1"] || "",
            "partNo2": mappingColumns["partNo2"] || "",
            "partNo3": mappingColumns["partNo3"] || "",
            "partNo4": mappingColumns["partNo4"] || "",
            "partNo5": mappingColumns["partNo5"] || "",
            "materialName": mappingColumns["materialName"] || "",
            "materialName1": mappingColumns["materialName1"] || "",
            "materialName2": mappingColumns["materialName2"] || "",
            "materialName3": mappingColumns["materialName3"] || "",
            "materialName4": mappingColumns["materialName4"] || "",
            "location1": mappingColumns["location1"] || "",
            "location2": mappingColumns["location2"] || "",
            "location3": mappingColumns["location3"] || "",
            "location4": mappingColumns["location4"] || "",
            "location5": mappingColumns["location5"] || "",
            "uom": mappingColumns["uom"] || "",
            "quantity": mappingColumns["quantity"] || "",
            "quantityformat": selectedColumns["quantityformat"],
            "rate": mappingColumns["rate"] || "",
            "rateformat": selectedColumns["rateformat"],
            "value": mappingColumns["value"] || "",
            "valueformat": selectedColumns["valueformet"],
            "expiryDate": mappingColumns["expiryDate"] || "",
            "expiryDateFormat": selectedColumns["expiryDateFormat"] ?? "",
            "lastReceiptDate": mappingColumns["lastReceiptDate"] || "",
            "lastReceiptDateFormat": selectedColumns["lastReceiptDateFormat"] ?? "",
            "lastIssueDate": mappingColumns["lastIssueDate"] || "",
            "lastIssueDateFormat": selectedColumns["lastIssueDateFormat"] ?? "",
            "remarks": mappingColumns["remarks"] || ""
        }

        var formData = new FormData();
        formData.append("excelFile", excelFile);
        formData.append("organisationId", user?.organisationId);
        formData.append("createdBy",  user.userId);
        formData.append("assignmentId", assignmentId);
        formData.append("stockTypeId", stockId ?? null);
        formData.append("storeTypeId", storeId);
        formData.append("header", JSON.stringify(mappingHeader));
        formData.append("mapping", JSON.stringify(mapping));


        let isError = false;
        let errorMessage = "";

        if (isEmptyVariable(storeId)) {
            isError = true;
            errorMessage = "Store is required";
        }else if (mapping.materialName === "" && mapping.partNo1 === "") {
            isError = true;
            errorMessage = "Material name or Part number is required";
        }else if (mapping.uom === "") {
            isError = true;
            errorMessage = "UOM is required";
        }

        // Handle form submission and display error message if applicable
        if (isError) {
            setErrors(errorMessage)
            setTimeout(() => {
                setErrors('');
                isErrorAlertShow(false);
            },Constants.timeOut.errorMsgTimeout);
            handleShowAlertAgain()
            setDisabled(false);
            setLoading(false);
        } else if(mapping.quantity === ""){
            // If quantity is not selected, display the dialog box
            setLoading(false);
            setBookStockMappingValue(formData)
            setIsQtySelectedDialog(true);
        }else {
           addBookStockFileInQueue(formData);
        }
    }

    // Add a book stock file in the queue.
    const addBookStockFileInQueue = (formData) => {
        isModalButtonLoading(true);
        new APIRequest.Builder()
            .post()
            .setReqId(ADD_BOOKSTOCK_FILE_IN_QUEUE)
            .jsonParams(formData)
            .reqURL("bookstock/addBookStockFileInQueue")
            .response(onResponse)
            .error(onError)
            .timeout(180000)
            .build()
            .doRequest();
    }

    // Handle change event for the dropdown menu
    const handleChange = (e, item, key) => {
        let data = e?.value;
        selectedColumns[item] = data;
        selectedColumns["rateformat"] = selectedColumns["rateformat"] ? Constants.format.find(e => e.value === selectedColumns["rateformat"]).value : defaultFormatOption.value;
        selectedColumns["valueformet"] = selectedColumns["valueformet"] ? Constants.format.find(e => e.value === selectedColumns["valueformet"]).value : defaultFormatOption.value;
        selectedColumns["quantityformat"] = selectedColumns["quantityformat"] ? Constants.format.find(e => e.value === selectedColumns["quantityformat"]).value : defaultFormatOption.value;
        selectedColumns["expiryDateFormat"] = selectedColumns["expiryDateFormat"] ? Constants.dateFormat.find(e => e.value === selectedColumns["expiryDateFormat"]).value : defaultDateFormatOption.value;
        selectedColumns["lastIssueDateFormat"] = selectedColumns["lastIssueDateFormat"] ? Constants.dateFormat.find(e => e.value === selectedColumns["lastIssueDateFormat"]).value : defaultDateFormatOption.value;
        selectedColumns["lastReceiptDateFormat"] = selectedColumns["lastReceiptDateFormat"] ? Constants.dateFormat.find(e => e.value === selectedColumns["lastReceiptDateFormat"]).value : defaultDateFormatOption.value;
        
        let values = Object.values(selectedColumns);
        if (!values.includes("rate")) {
            delete selectedColumns["rateformat"];
            setRateId();
        }
        if (!values.includes("value")) {
            delete selectedColumns["valueformet"];
            setValueId();
        }
        if (!values.includes("quantity")) {
            delete selectedColumns["quantityformat"];
            setQuantityId();
        }
        if (!values.includes("expiryDate")) {
            delete selectedColumns["expiryDateFormat"];
            setExpiryFormat();
        }
        if (!values.includes("lastIssueDate")) {
            delete selectedColumns["lastIssueDateFormat"];
            setLastIssueFormat();
        }
        if (!values.includes("lastReceiptDate")) {
            delete selectedColumns["lastReceiptDateFormat"];
            setLastRecipetFormat();
        }
        setSelectedColumns({ ...selectedColumns });

        if (data === mappingOptions["Part Number"]) {
            let newValues = [
                { label: "Sub Part No. 1", value: mappingOptions["Sub Part No. 1"] },
                ...mapData,
            ].map(JSON.stringify);
            newValues = Array.from(new Set(newValues)).map(JSON.parse);
            setMapData([...newValues]);
        }
        else if (data === mappingOptions["Sub Part No. 1"]) {
            let newValues = [
                { label: "Sub Part No. 2", value: mappingOptions["Sub Part No. 2"] },
                ...mapData,
            ].map(JSON.stringify);
            newValues = Array.from(new Set(newValues)).map(JSON.parse);
            setMapData([...newValues]);
        } else if (data === mappingOptions["Sub Part No. 2"]) {
            let newValues = [
                { label: "Sub Part No. 3", value: mappingOptions["Sub Part No. 3"] },
                ...mapData,
            ].map(JSON.stringify);
            newValues = Array.from(new Set(newValues)).map(JSON.parse);
            setMapData([...newValues]);
        } else if (data === mappingOptions["Sub Part No. 3"]) {
            let newValues = [
                { label: "Sub Part No. 4", value: mappingOptions["Sub Part No. 4"] },
                ...mapData,
            ].map(JSON.stringify);
            newValues = Array.from(new Set(newValues)).map(JSON.parse);
            setMapData([...newValues]);
        }
        else if (data === mappingOptions["Material Name"]) {
            let newValues = [
                { label: "Material Sub Name 1", value: mappingOptions["Material Sub Name 1"] },
                ...mapData,
            ].map(JSON.stringify);
            newValues = Array.from(new Set(newValues)).map(JSON.parse);
            setMapData([...newValues]);
        } else if (data === mappingOptions["Material Sub Name 1"]) {
            let newValues = [
                { label: "Material Sub Name 2", value: mappingOptions["Material Sub Name 2"] },
                ...mapData,
            ].map(JSON.stringify);
            newValues = Array.from(new Set(newValues)).map(JSON.parse);
            setMapData([...newValues]);
        } else if (data === mappingOptions["Material Sub Name 2"]) {
            let newValues = [
                { label: "Material Sub Name 3", value: mappingOptions["Material Sub Name 3"] },
                ...mapData,
            ].map(JSON.stringify);
            newValues = Array.from(new Set(newValues)).map(JSON.parse);
            setMapData([...newValues]);
        } else if (data === mappingOptions["Material Sub Name 3"]) {
            let newValues = [
                { label: "Material Sub Name 4", value: mappingOptions["Material Sub Name 4"] },
                ...mapData,
            ].map(JSON.stringify);
            newValues = Array.from(new Set(newValues)).map(JSON.parse);
            setMapData([...newValues]);
        }
        else if (data === mappingOptions["Location 1"]) {
            let newValues = [
                { label: "Location 2", value: mappingOptions["Location 2"] },
                ...mapData,
            ].map(JSON.stringify);
            newValues = Array.from(new Set(newValues)).map(JSON.parse);
            setMapData([...newValues]);
        } else if (data === mappingOptions["Location 2"]) {
            let newValues = [
                { label: "Location 3", value: mappingOptions["Location 3"] },
                ...mapData,
            ].map(JSON.stringify);
            newValues = Array.from(new Set(newValues)).map(JSON.parse);
            setMapData([...newValues]);
        } else if (data === mappingOptions["Location 3"]) {
            let newValues = [
                { label: "Location 4", value: mappingOptions["Location 4"] },
                ...mapData,
            ].map(JSON.stringify);
            newValues = Array.from(new Set(newValues)).map(JSON.parse);
            setMapData([...newValues]);
        } else if (data === mappingOptions["Location 4"]) {
            let newValues = [
                { label: "Location 5", value: mappingOptions["Location 5"] },
                ...mapData,
            ].map(JSON.stringify);
            newValues = Array.from(new Set(newValues)).map(JSON.parse);
            setMapData([...newValues]);
        }else if (data === mappingOptions["Last Receipt Date"]) {
            setLastRecipetFormat(key)
        } else if (data === mappingOptions["Last Issue Date"]) {
            setLastIssueFormat(key)
        } else if (data === mappingOptions["Expiry Date"]) {
            setExpiryFormat(key)
        }else if (data === mappingOptions["Quantity"]) {
            setQuantityId(key)
        } else if (data === mappingOptions["Rate"]) {
            setRateId(key)
        } else if (data === mappingOptions["Value"]) {
            setValueId(key)
        }
    }

    const headerOption = mapData?.filter(e => !Object.values(selectedColumns).includes(e.value));
    const storeOptions = assignmentStoreList?.map((item, i) => { return { "key": item.storeTypeId, "label": item.storeTypeName } });
    const stockOptions = stockList?.map((item, i) => { return { "key": item.stockTypeId, "label": item.stockTypeName } });

    // An array of column objects for a table
    const bookStockMappingColumns = [
        {
            title: `Excel file's headers`,
            dataIndex: 'header',
            key: 'header',
            width: '20%'
        },
        {
            title: '',
            dataIndex: 'arrow',
            key: 'action',
            width: '4%'
        },
        {
            title: 'Map to fields',
            key: 'mapField',
            render: (record) => (
                <div className='select_box'>
                    <Select
                        placeholder="Select"
                        className='reduceFonts'
                        key={`${record.item}`}
                        options={headerOption}
                        name={`${record.item}`}
                        isClearable
                        menuPortalTarget={document.body} 
                        styles={{ menuPortal: base => ({ ...base, zIndex: 9999, fontSize:"12px" }) }}
                        menuPlacement="auto"
                        value={mapData.find(header => header.value === selectedColumns[record.item]) ?? null}
                        onChange={(e) => handleChange(e, record.item, record.key)}
                    />
                </div>
            ),
            width: '22%'
        },
        {
            title: 'Select decimal separator format used in excel file',
            key: 'format',
            render: (record) => {
                let formKey = "valueformet";
                if (valueId === record.key){
                    formKey = "valueformet";
                }else if (rateId === record.key){
                    formKey = "rateformat";
                }else if (quantityId === record.key){
                    formKey = "quantityformat";
                }else if (lastIssueFormat === record.key){
                    formKey = "lastIssueDateFormat";
                }else if (lastRecipetFormat === record.key){
                    formKey = "lastReceiptDateFormat";
                }else if (expiryFormat === record.key){
                    formKey = "expiryDateFormat";
                }
                return (
                    <>
                    {valueId === record.key || rateId === record.key || quantityId === record.key ? 
                    <div className='select_box'>
                        <Select
                            placeholder={"Select Format"}
                            className='reduceFonts'
                            key={`${record.item}`}
                            options={Constants.format}
                            name={`${record.item}`}
                            isClearable
                            menuPortalTarget={document.body}
                            styles={{ menuPortal: base => ({ ...base, zIndex: 9999, fontSize:"12px" }) }}
                            menuPlacement="auto"
                            value={Constants.format.find(e => e.value === selectedColumns[formKey]) ?? null}
                            onChange={e => {
                                let data = e?.value;
                                selectedColumns[formKey] = data;
                                setSelectedColumns({ ...selectedColumns })
                            }}
                        />
                    </div>
                    : <></>}
                    {lastRecipetFormat === record.key || lastIssueFormat === record.key || expiryFormat === record.key ?
                    <div className='select_box'>
                        <Select
                            placeholder={"Select Date Format"}
                            className='reduceFonts'
                            key={`${record.item}`}
                            options={Constants.dateFormat}
                            name={`${record.item}`}
                            isClearable
                            menuPortalTarget={document.body}
                            styles={{ menuPortal: base => ({ ...base, zIndex: 9999, fontSize:"12px" }) }}
                            menuPlacement="auto"
                            value={Constants.dateFormat.find(e => e.value === selectedColumns[formKey]) ?? null}
                            onChange={e => {
                                let data = e?.value;
                                selectedColumns[formKey] = data;
                                setSelectedColumns({ ...selectedColumns })
                            }}
                        />
                    </div> :
                    <></>}
                    </>
                )
            },
            width: '18%'
        },
        {
            title: '',
            dataIndex: 'equal',
            key: 'equal',
            width: '2%'
        },
        {
            title: 'Excel Data',
            key: 'example',
            width: '40%',
            render: (record) => (
                <Row className='cell-row' style={{flex:1}}>
                    {record.example.map(cell => {
                        return <Col className='cell-border'>{cell}</Col>
                    })}
                </Row>
            ),
        },
    ];

    // Function to handle the store dropdown selection
    const handleStoreDropdown = (e) => {
        if (e && e.item) {
            setStoreId(e.item.props.id);
            setStoreTypeName(e.item.props.value);
        }
    }

    // Function to handle the stock dropdown selection
    const handleStockDropdown = (e) => {
        if (e && e.item) {
            setStockId(e.item.props.id);
            setStockTypeName(e.item.props.value);
        }
    }

    // Function to handle the closing of an alert
    const handleAlertClose = () => {
        isErrorAlertShow(false);
    };

    // This function handles the display of an alert again.
    const handleShowAlertAgain = () => {
        isErrorAlertShow(true);
    };

    useEffect(() => {
        const calculateTableHeight = () => {
            const headerMarginTop = 92;
            const tabHeight = 29;
            const headerHeight = 87;
            const marginTop = 25;
            const errorBorder = 1;
            const errorMargin = 12;
            const errorPadding = 16;
            const errorFontSize = 14;
            const footerHeight = 32;
            const footerPadding = 10;

            // Calculate remaining height based on window height and subtracting various components
            let remainingHeight = window.innerHeight - headerMarginTop - headerHeight - tabHeight - marginTop - footerHeight - footerPadding;

            // Subtract additional height if error alert is shown
            if (errorAlertShow) {
                remainingHeight -= errorBorder + errorPadding + errorFontSize + errorMargin;
            }
            setTableHeight(remainingHeight);
        };
    
        // Call the function to calculate table height
        calculateTableHeight();
    
        window.addEventListener('resize', calculateTableHeight);
        return () => {
            window.removeEventListener('resize', calculateTableHeight);
        };
    }, [errorAlertShow]);
    
    // This function returns a Table component with the uploaded Excel file data.
    const uploadedExcelFileDataTable = () => {
        return(
            mappingFieldForm &&
            <div style={{ height: tableHeight, overflowY: 'auto', marginTop: 10 }} className="scrollable-div">
                <Table 
                    columns={bookStockMappingColumns}
                    dataSource={mappingHeader.map((item, index) => {
                        let tempExampleRowData = [];
                        for(var i = 0; i < 2; i++){
                            if(exampleRowData[i]){
                                tempExampleRowData.push(exampleRowData[i][index]);
                            }
                        }
                        return {
                            ...{item},
                            key: index,
                            header: item,
                            arrow: <ArrowRightOutlined />,
                            equal: '=',
                            example: tempExampleRowData,
                        }
                    })}
                    pagination={false}
                    className="custom-table"
                />
            </div>
        )
    }

    // This function, checks if the user should be allowed to upload a file or not.
    const handleButtonDisabled = () => {
        if(componentDidMountFlag && (bookStockFileInQueueSuccessMessage || (Object.keys(checkFileProcessStatusData).length != 0 && checkFileProcessStatusData?.createdBy != user.userId))){
            return true;
        }else {
            return false;
        }
    }

    // The function is used to display the file upload form
    const uploadExcelFileContainer = () => {
        return(
            <>
                <div className='btn-container'>
                    <Row justify="space-between" align="middle">
                        <Col>
                            <b>Uploaded Filename:</b> {excelFile.name}
                        </Col>
                    </Row>
                    <hr style={{ color: '#cbcbcd',boxShadow: '0 2px 4px rgba(0, 0, 0, 0.1)', marginTop: 5, marginBottom: 5 }}/>
                    <Row className='upload-bookstock-btn'>
                        <Col>
                            <CommonButton
                                label={"Upload File"}
                                disabled={handleButtonDisabled()}
                                onClick={fileUpload}
                                style={{ marginRight: 10, borderRadius: 5, height: 32, opacity: handleButtonDisabled() ? 0.7 : 1 }}
                                icon={<CloudUploadOutlined 
                                    style={{
                                        verticalAlign: 'middle'
                                    }}
                                />}
                            />
                            <a
                                href='javascript:void(0);' 
                                onClick={() => setRulesModal(true)}
                                style={{ fontSize: "12px", color: "#000", wordWrap: "break-word", borderBottom: '1px solid #000'}}
                            >
                                Read Upload File Rules
                            </a>
                        </Col>
                        <Row className='dropdown-container'>
                            <Col>
                                <label className='form-label dropdown-lable'>Store Type</label>
                            </Col>
                            <Col>
                                <CommonDropdown
                                    items={storeOptions}
                                    disabled={handleButtonDisabled()}
                                    handleMenuClick={handleStoreDropdown}
                                    selected={storeTypeName}
                                    style={{width: '140px', height: 34, marginRight: 10, opacity: handleButtonDisabled() ? 0.7 : 1 }}
                                />
                            </Col>
                            <Col>
                                <label className='form-label dropdown-lable'>Stock Type</label>
                            </Col>
                            <Col>
                                <CommonDropdown
                                    items={stockOptions}
                                    disabled={handleButtonDisabled()}
                                    handleMenuClick={handleStockDropdown}
                                    selected={stockTypeName}
                                    style={{width: '140px', height: 34, marginRight: 10, opacity: handleButtonDisabled() ? 0.7 : 1 }}
                                />
                            </Col>
                            <Col>
                                <CommonButton
                                    label={"Stock Type"}
                                    disabled={handleButtonDisabled()}
                                    onClick={() => {
                                        setShowStockTypeModal(true);
                                    }}
                                    style={{ borderRadius: 5, height: 32, opacity: handleButtonDisabled() ? 0.7 : 1 }}
                                    icon={<PlusCircleFilled 
                                        style={{
                                            verticalAlign: 'middle'
                                        }}
                                    />}
                                />
                            </Col>
                        </Row>
                    </Row>
                </div>
            </>
        )
    }

    // Determines the appropriate UI based on the current status of the file
    const handleUploadBookstockBasedOnStatus = () => {
        if(componentDidMountFlag && 
            Object.keys(checkFileProcessStatusData).length == 0 || 
            checkFileProcessStatusData?.createdBy != user.userId || 
            checkFileProcessStatusData?.status == 'inQueue' || 
            checkFileProcessStatusData?.status == 'inProcess' || 
            checkFileProcessStatusData?.status == 'error'
        ){
            return uploadExcelFileContainer();
        }else if(checkFileProcessStatusData?.createdBy == user.userId && checkFileProcessStatusData?.status == 'completed'){
            return <Bookstocktable checkFileProcessStatusData={checkFileProcessStatusData} assignment={assignment}/>
        }else{
            return uploadExcelFileContainer();
        }
    }

    // Function to handle the cancel button in the confirm modal
    const handleConfirmModalCancelButton = () => {
        setUploadFileAlertModal(false);
        clearUploadFileData();
    }

    // Function to handle the ok button in the confirm modal
    const handleConfirmModalOkButton = () => {
        setUploadFileAlertModal(false);
        setUploadFileDataLoading(true);
        getTotalBookStockCount(excelFile);
    }

    return (
        <>
            {dataLoading && 
                <CommonLoader loading={dataLoading} />
            }
            {componentDidMountFlag && handleUploadBookstockBasedOnStatus()}

            {bookStockFileInQueueSuccessMessage && 
                <CommonAlert
                    message={Constants.message.addBookStockFileInQueueSuccessMessage}
                    type="success"
                />
            }
            {errorAlertShow && errors && 
                <CommonAlert 
                    message={errors}
                    type="error"
                    closable
                    onClose={handleAlertClose}
                />
            }
            {componentDidMountFlag && Object.keys(checkFileProcessStatusData).length != 0 && checkFileProcessStatusData?.createdBy != user.userId &&
                <CommonAlert 
                    message={'Another user is in the process of uploading bookStock. Kindly wait for a while.'} 
                    type="info"
                />
            }
            {uploadFileDataLoading && 
                <CommonLoader loadingMessage="Processing File..." loading={uploadFileDataLoading} />
            }

            {uploadedExcelFileDataTable()}
                
            {mappingFieldForm && 
                <div className='bottom-btn'>
                    <CommonButton
                        label={"Submit"}
                        disabled={disabled}
                        onClick={handleSubmit}
                        style={{ marginRight: 10, borderRadius: 5, height: 32 }}
                        loading={loading}
                    />
                    <CommonButton
                        label={"Clear"}
                        disabled={disabled}
                        onClick={() => {
                            setSelectedColumns({...oldSelectedColumns})
                            setQuantityId();
                            setValueId();
                            setRateId();
                        }}
                        style={{ borderRadius: 5, height: 32 }}
                    />
                </div>
            }

            {showStockTypeModal &&
                <Modal show={showStockTypeModal}>
                    <form onSubmit={generalForm.handleSubmit}>
                        <Modal.Header>
                            <Modal.Title>Stock Type</Modal.Title>
                            <div
                                onClick={() => {
                                    generalForm.resetForm();
                                    setShowStockTypeModal(false);
                                }}
                                data-dismiss="modal">
                                <i className="fa fa-close" aria-hidden="true"></i>
                            </div>
                        </Modal.Header>
                        <Modal.Body className='pb-1'>
                            <div className="">
                                <label htmlFor="stockTypeShortName" className="form-label">Short Name*</label>
                                <input
                                    className="form-control"
                                    id="stockTypeShortName"
                                    name="stockTypeShortName"
                                    type="text"
                                    onChange={generalForm.handleChange}
                                    value={generalForm.values.stockTypeShortName}
                                />
                                {generalForm.touched.stockTypeShortName && generalForm.errors.stockTypeShortName ? (
                                    <span className="error">{generalForm.errors.stockTypeShortName}</span>
                                ) : null}
                            </div>
                            <div className="">
                                <label htmlFor="name" className="form-label">Name*</label>
                                <input
                                    className="form-control"
                                    id="stockTypeName"
                                    name="stockTypeName"
                                    type="text"
                                    onChange={generalForm.handleChange}
                                    value={generalForm.values.stockTypeName}
                                />
                                {generalForm.touched.stockTypeName && generalForm.errors.stockTypeName ? (
                                    <span className="error">{generalForm.errors.stockTypeName}</span>
                                ) : null}
                            </div>
                            <div className="Mandotary">
                                {/* <p>* Fields Are Mandatory Reqired</p> */}
                            </div>
                        </Modal.Body>
                        <Modal.Footer>
                            <Button variant="secondary" onClick={() => {
                                generalForm.resetForm();
                                setShowStockTypeModal(false);
                            }}>
                                Cancel
                            </Button>
                            <Button variant="primary" type="submit">
                                {storeloading ? <Spinner animation="border" variant="light" className='spinner-border-sm' /> : "Save"}
                            </Button>
                        </Modal.Footer>
                    </form>
                </Modal>
            }
            {rulesModal &&
                <Modal show={rulesModal}>
                    <Modal.Header>
                        <Modal.Title>Read Upload File Rules</Modal.Title>
                        <div
                            onClick={() => {
                                setRulesModal(false);
                            }}
                            data-dismiss="modal">
                            <i className="fa fa-close" aria-hidden="true"></i>
                        </div>
                    </Modal.Header>
                    <Modal.Body className='pb-1'>
                        <div className='rules-list'>
                            <ul>
                                <li>The header of the inventory data should be in the first row i.e. Row 1.</li>
                                <li>Inventory data should start from row number 2.</li>
                                <li>The data should start from first column i.e. Column A.</li>
                                <li>There should not be any empty line between the inventory data.</li>
                                <li>There should not be any formula in the excel sheet.</li>
                                <li>There should not be any merged cells.</li>
                                <li>If still upload does not work, then copy the data and paste it in new file and upload it.</li>
                                <li>Please choose an appropriate date format as used in excel, else it may impact the inventory analysis.</li>
                                <li>Duplicate stock can be identified, if only the mapping of the part number and material name including sub part number and material sub name is carried out in same manner everytime.</li>
                            </ul>
                        </div>
                    </Modal.Body>
                </Modal>
            }
            {isQtySelectedDialog &&
                <ReusableModal
                    show={isQtySelectedDialog}
                    buttonCancel={"No"}
                    onClose={() => {
                        isModalButtonLoading(false);
                        setIsQtySelectedDialog(false)
                        setDisabled(false);
                    }}
                    loading={modalButtonLoading}
                    onConfirm={() => addBookStockFileInQueue(bookStockMappingValue)}
                    message={"You have not selected quantity. Are you sure, you want to proceed without selection of quantity?"}
                />
            }
            {uploadFileAlertModal &&
                <CommonModal
                    onOpen={uploadFileAlertModal}
                    onClose={handleConfirmModalCancelButton}
                    handleRightButton={handleConfirmModalOkButton}
                    handleLeftButton={handleConfirmModalCancelButton}
                    className={"custom-common-modal"}
                    content={"The uploaded file size is on bigger size, Kindly check there should not be more than 30,000 line items."}
                    rightButtonName={"Yes"}
                    leftButtonName={"No"}
                    closable={true}
                />
            }
        </>
    );
}

export default UploadBookStock;