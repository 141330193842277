import React from 'react'
import { Button, Modal } from "react-bootstrap";
import { Table } from "../../.././Table/Table";
import { useEffect } from 'react';
import { useState } from 'react';
import { toast } from 'react-toastify';
import { APIRequest, SUBSCRIBER_ADMIN_LIST } from '../../../../api';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { logout } from '../../../../redux/action';
import * as Constants from '../../../../Component/common/Global/constants';
import { isEmptyArray, isEmptyVariable } from '../../../../Component/common/Global/commonFunctions';

export const SubscriberAdmin = ({ subscriber, subscriberAdmin, organisationId, setSubscriber, subscriberadmindata, setSubscriberAdminData, componentDidMountFlag }) => {
  // let subscriberadmindata = subscriberadmindata.records;
  const [search, setSearch] = useState('');
  const [sort, setSort] = useState('');
  const [sortOrder, setSortOrder] = useState('');
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const onResponse = (response, reqId) => {
    switch (reqId) {
      case SUBSCRIBER_ADMIN_LIST:
        setSubscriberAdminData(response.data?.data)
        break;
      default:
        break;
    }
  }

  const onError = (response, reqId) => {
    switch (reqId) {
      case SUBSCRIBER_ADMIN_LIST:
        if (response.data?.status === Constants.status.codeAccessTokenUnauthorized) {
          localStorage.clear();
          navigate("/Login");
          dispatch(logout());
        } else {
          toast.error(`${response?.data?.message}`)
        }
        break;
      default:
        break;
    }
  }
  const handleTableChange = (type, newState) => {
    let url = `user/getSubscriberByOrg/${organisationId}?page=${type === "search" || type === "sort" ? "1" : newState.page}`;
    if (newState.sizePerPage) {
      url = url + `&limit=${newState.sizePerPage}`;
    }
    if (!isEmptyVariable(newState?.searchText)) {
      setSearch(newState.searchText);
      url = url + `&search=${encodeURIComponent(newState.searchText)}`;
    }else{
      setSearch('');
    }
    if (!isEmptyVariable(newState?.sortField)) {
      setSort(newState?.sortField);
      if (newState.sortField === "name") {
        url = url + `&sortColumn=city`
      }
      if (newState.sortOrder) {
        setSortOrder(newState?.sortOrder);
        url = url + `&sortType=${newState.sortOrder}`
      }
    }else{
      setSort('');
      setSortOrder('');
  }

    new APIRequest.Builder()
      .get()
      .setReqId(SUBSCRIBER_ADMIN_LIST)
      .reqURL(url)
      .response(onResponse)
      .error(onError)
      .build()
      .doRequest()
  };
  var rowEvents = {
    onClick: (row, e) => {
      // navigate(`ongoingassignment/${e.id}`)
    }
  }

  const clearFilter = () => {
    setSearch('');
    setSort('');
    setSortOrder('');
    subscriberAdmin(organisationId);
  }

  return (
    <>
      <Modal show={subscriber}>
        <Modal.Header>
          <Modal.Title>User Details</Modal.Title>
          <div
            onClick={() => setSubscriber(false)}
            data-dismiss="modal">
            <i className="fa fa-close" aria-hidden="true"></i>
          </div>
        </Modal.Header>
        <Modal.Body className='pb-0'>
          <div className="master_box">
            <div className="master_boxinfo">
              <div className="table-responsive pt-2 pb-5">
              <div className="add_btns">
                {(search != "" || sort != "") && 
                  <button
                    type="button"
                    className="btn btn-primary"
                    variant="primary"
                    onClick={() => clearFilter()}
                  >
                    <i
                      className="fa fa-filter"
                      aria-hidden="true"
                    ></i>{" "}
                    Clear
                  </button>
                }
              </div>
                <i className="fa fa-search search_icon" aria-hidden="true"></i>
                {componentDidMountFlag && subscriberadmindata?.records && (
                  <Table
                    data={subscriberadmindata?.records?.map((item, index) => {
                      return {
                        ...item,
                        key: index,
                        index: (subscriberadmindata?.page - 1) * subscriberadmindata.limit + index + 1,
                        id: item.userId,
                        name: item?.title + "" + item?.firstName + " " + item.lastName ?? "",
                        mobileNumber: item?.mobileNumber ?? "",
                        email: item.email ?? "",
                      };
                    })}
                    columns={[
                      {
                        dataField: "index",
                        text: "No",
                        headerClasses: "sr_no",
                      },
                      {
                        dataField: "name",
                        text: "Name",
                        style: { cursor: "pointer" },
                        sort: true,
                        onclick: true,
                      },
                      {
                        dataField: "mobileNumber",
                        text: "Mobile",
                        style: { cursor: "pointer" },
                        sort: true,
                      },
                      {
                        dataField: "email",
                        text: "Email",
                        style: { cursor: "pointer" },
                        sort: true,
                      },
                    ]}
                    handleTableChange={handleTableChange}
                    totalSize={subscriberadmindata?.total ?? 0}
                    currentPage={subscriberadmindata?.page ?? 1}
                    sizePerPage={subscriberadmindata?.limit ?? 15}
                    rowEvents={rowEvents}
                  />
                )}
              </div>
                {componentDidMountFlag && isEmptyArray(subscriberadmindata?.records) && 
                  <div className="no-items-layout">
                    <div className="userdetail-no-items-card no-items-card">
                      <h6>
                        {
                          Constants.message.noRecordsWarning
                        }
                      </h6>
                    </div>
                  </div>
                }
            </div>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button
            variant="secondary"
            onClick={() => setSubscriber(false)}
          >
            Cancel
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  )
}
