import React, { Component } from "react";
import { Dropdown, Menu, Space } from "antd";
import '../dropdown/commonDropdown.css';
import { DownOutlined } from '@ant-design/icons';

class CommonDropdown extends Component {
  constructor(props) {
    super(props);
    // Define the default style properties for the dropdown
    this.dropdownStyle = {
      alignItems: 'center',
      borderRadius: 5,
      border: '1px solid #552B84',
      color: '#444',
      padding: '8px 12px',
      width: '100px',
      display:'flex'
    };
  }

  // This function takes an object 'styleProperties' as an argument and returns the same object with additional properties.
  addStylePropertiesToDropdown(styleProperties) {
    // Iterate over the entries in the 'this.buttonStyle' object using 'Object.entries()' method.
    Object.entries(this.dropdownStyle).map(([key, value]) => {
        // Check if the current key does not exist in the 'styleProperties' object.
        if(!styleProperties[key]){
            // If it doesn't exist, add the key-value pair from 'this.buttonStyle' object to 'styleProperties' object.
            styleProperties[key] = value
        }
    });
    // Finally, return the updated 'styleProperties' object.
    return styleProperties;
  }

  getDropdownCustomStyle() {
    // If the dropdown has a prop 'style' then check if it exists
    if(this.props.style){
        // If the prop 'style' exists then add the style properties to the dropdown
        return this.addStylePropertiesToDropdown(this.props.style);
    }else{
        // If the prop 'style' does not exist then use the default style
        return this.dropdownStyle;
    }
  }
  render() {
    const { items, handleMenuClick, selected, disabled } = this.props;
    
    const menu = (
      <Menu onClick={handleMenuClick}>
        {items.map((item, index) => {
          return (
            <Menu.Item style={{fontSize: 10}} key={index} value={item.label} id={item.key}>
              {item.label}
            </Menu.Item>
          );
        })}
      </Menu>
    );

    return (
        <Dropdown
          overlay={menu}
          trigger={['click']}
          disabled={disabled ? disabled : false}
        >
          <a onClick={(e) => e.preventDefault()} style={this.getDropdownCustomStyle()}>
              <span style={{ marginRight: 'auto' }}>{selected}</span>
              <DownOutlined style={{ marginLeft: 10 }} />
          </a>
        </Dropdown>
    );
  }
}

export default CommonDropdown;