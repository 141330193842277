import React, { useState } from 'react'
import Datebox from '../../Component/Datebox/datebox'
import Monthbox from '../../Component/Monthbox/monthbox'
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Slider from "react-slick";
import Spinner from "react-bootstrap/Spinner";
import { APIRequest, GET_DATA_DAY_WISE, GET_DATA_MONTH_WISE } from '../../api';
import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { toast } from 'react-toastify';
import * as Constants from '../../Component/common/Global/constants';
import { logout } from '../../redux/action';

const Maindashboard = () => {

  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [startdate, setstartdate] = useState("");
  const [todate, settodate] = useState("");
  const [DayWiseData, setDayWiseData] = useState([]);
  const [MonthWiseData, setMonthWiseData] = useState([]);
  const [loading, setLoading] = useState(false);
  const monthNames = [
    { month: 1, name: "Jan" },
    { month: 2, name: "Feb" },
    { month: 3, name: "Mar" },
    { month: 4, name: "Apr" },
    { month: 5, name: "May" },
    { month: 6, name: "Jun" },
    { month: 7, name: "Jul" },
    { month: 8, name: "Aug" },
    { month: 9, name: "Sep" },
    { month: 10, name: "Oct" },
    { month: 11, name: "Nov" },
    { month: 12, name: "Dec" }
  ];

  const getData = () => {
    new APIRequest.Builder()
      .get()
      .setReqId(GET_DATA_MONTH_WISE)
      .reqURL(`dashboard/getDataMonthWise`)
      .response(onResponse)
      .error(onError)
      .build()
      .doRequest()
    new APIRequest.Builder()
      .get()
      .setReqId(GET_DATA_DAY_WISE)
      .reqURL(`dashboard/getDataDateWise`)
      .response(onResponse)
      .error(onError)
      .build()
      .doRequest()
  }
  const onResponse = (response, reqId) => {
    switch (reqId) {
      case GET_DATA_MONTH_WISE:
        setMonthWiseData(response.data?.data);
        break;
      case GET_DATA_DAY_WISE:
        setDayWiseData(response.data?.data)
        setLoading(false);
        break;
      default:
        break;
    }
  }
  const onError = (response, reqId) => {
    switch (reqId) {
      case GET_DATA_MONTH_WISE:
        if (response.data?.status === Constants.status.codeAccessTokenUnauthorized) {
          localStorage.clear();
          navigate("/Login");
          dispatch(logout());
        } else {
          toast.error(`${response.data.message}`);
        }
        break;
      case GET_DATA_DAY_WISE:
        if (response.data?.status === Constants.status.codeAccessTokenUnauthorized) {
          localStorage.clear();
          navigate("/Login");
          dispatch(logout());
        } else {
          toast.error(`${response.data.message}`);
          setLoading(false);
        }
        break;
      default:
        break;
    }
  }
  useEffect(() => {
    getData();
  }, [])

  const handleSubmit = () => {
    setLoading(true);
    new APIRequest.Builder()
      .get()
      .setReqId(GET_DATA_DAY_WISE)
      .reqURL(`dashboard/getDataDateWise?startDate=${startdate}&endDate=${todate}`)
      .response(onResponse)
      .error(onError)
      .build()
      .doRequest()
  }
  var settings = {
    dots: true,
    infinite: false,
    speed: 500,
    slidesToShow: 4,
    slidesToScroll: 4,
    initialSlide: 0,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 3,
          infinite: true,
          dots: true
        }
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
          initialSlide: 2
        }
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1
        }
      }
    ]
  };
  const isButtonDisabled = !startdate || !todate;
  return (
    <>
      <div className="tab-content" id="v-pills-tabContent">
        <div className='sub-admin-box text-center'>
          <h5 className='sub-admin-tittle'>Upcoming Audit</h5>
          <h6 className='sub-admin-sub-tittle mb-3'>Clients / Number of Assignment/ Persondays</h6>

        {/*  date filter  */}

          <div className='add_btns d-flex'>
            <span className='mx-2' style={{ fontSize: "14px" }} >From</span> <input className='form-control border-style' id='assignmentStartDate' type='date'
              onChange={(e) => { setstartdate(e.target.value); }}
            ></input>
            <span className='mx-2' style={{ fontSize: "14px" }} >To</span><input className='form-control border-style' id='assignmentEndDate' type='date'
              onChange={(e) => { settodate(e.target.value); }}
            ></input>

            <button type="button" className='searchButton' disabled={isButtonDisabled}
              style={{ width: "20px", marginRight: "30px" }} onClick={handleSubmit}>
              {loading ? <Spinner animation="border" variant="light" className='spinner-border-sm' /> : <i className="fa fa-search" aria-hidden="true"></i>}
            </button>
          </div>
          <div className='row mt-5 mx-0 p-0'>

             {/*  day wise data  */}

            <Slider {...settings}>
              {DayWiseData.map(item => {
                const date = Object.keys(item)[0];
                const data = item[date];
                return (
                  <div key={date} className='p-2'>
                    <Datebox datelabel={date} numberlabel1={data?.clients} numberlabel2={data?.assignments} numberlabel3={data?.persons} />
                  </div>
                );
              })}
            </Slider>

             {/*  month wise data  */}

            <Slider {...settings}>
              {MonthWiseData.map((item, index) => {
                const month = Object.keys(item)[0];
                const monthNumber = parseInt(Object.keys(item)[0].split('/')[0], 10);
                const year = Object.keys(item)[0].split('/')[1];
                const monthData = item[Object.keys(item)[0]];
                const monthObject = monthNames.find(monthObj => monthObj.month === monthNumber);
                const monthNameLabel = monthObject ? monthObject?.name : month;
                const monthLabel = `${monthNameLabel}/${year}`;

                return (
                  <div key={index} className='p-2'>
                    <Monthbox
                      monthlabel={monthLabel}
                      numberlabel1={monthData.clients}
                      numberlabel2={monthData.assignments}
                      numberlabel3={monthData.persons}
                    />
                  </div>
                );
              })}
            </Slider>
          </div>
        </div>

      </div>
    </>
  )
}

export default Maindashboard