import React, {useState, useEffect} from 'react';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import * as FileSaver from 'file-saver';
import * as XLSX from 'xlsx';
import { Table } from '../../../../../Table/Table';
import { logout } from '../../../../../../redux/action';
import CommonLoader from '../../../../../../commonComponent/loader/commonLoader';
import * as Constants from '../../../../../../Component/common/Global/constants';
import RegularDropDown from '../../../../../../Component/common/DropdownMenus/RegularDropdown';
import AssignmentListDetails from '../../../../../../Component/common/assignmentListDetails/assignmentListDetails';
import { isEmptyArray, isEmptyVariable, randomStringGenerator } from '../../../../../../Component/common/Global/commonFunctions';
import Sampleitem from '../../../../../OngoingAssignment/Detailspage/sampleitem';

const SampleDetails = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const location = useLocation();
  const assignment = location?.state?.assignmentListDetails;
  const user = useSelector((state) => state?.user);
  const token = useSelector((state) => state.token);
  const { assignmentId } = useParams();
  const formatter = new Intl.NumberFormat('en-IN');
  const [sampleList, setSampleList] = useState([]);
  const [downloadReportData, setDownloadReportData] = useState([]);
  const [sampleDetails, setSampleDetails] = useState()
  const [sampleDetailsShow, setSampleDetailsShow] = useState(false);
  const [storeId, setStoreId] = useState('');
  const [stockId, setStockId] = useState('');
  const [search, setSearch] = useState('');
  const [sort, setSort] = useState('');
  const [sortOrder, setSortOrder] = useState('');
  const [stockData, setStockData] = useState([])
  const [selectedStoreType, setSelectedStoreType] = useState("Select Store Type");
  const [isShowStoreTypeDropDownItem, showStoreTypeDropdownItem] = useState(false);
  const [selectedStockType, setSelectedStockType] = useState("Select Stock Type");
  const [isShowStockTypeDropDownItem, showStockTypeDropdownItem] = useState(false);
  const [componentDidMountFlag, isComponentDidMountFlag] = useState(false);
  const [sampleDetailsLoading, setSampleDetailsLoading] = useState(false);
  const [fileName, setFileName] = useState("Sample Reports");
  const store = storeId;
  const fileType = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
  const fileExtension = '.xlsx';
  const stockOptions = [];
  const storeOptions = stockData?.map((item, i) => { return { "value": item?.storeTypeId, "label": item?.storeTypeName } }) ?? [];
  const filteredItems = stockData?.filter((e) => e?.storeTypeId == store);
  filteredItems?.forEach((item) => {
    item?.stockType?.forEach((stockItem) => {
      stockOptions.push({ "value": stockItem?.stockTypeId, "label": stockItem?.stockTypeName });
    });
  });

  // Handle the Store Stock filter options based on storeStockTypeData
  useEffect(() => {
    if (assignment?.storeStockTypeData != undefined) {
      const storeStockData = assignment?.storeStockTypeData;
      setStockData(storeStockData)
    }
  }, [assignment])

  useEffect(() => {
    getCompletedSamplingBookStockData();
  }, [])

  // Update the downloadReportData state with the mapped sample list data for report
  useEffect(() => {
    setDownloadReportData(sampleList?.records?.map((item, index) => {
      return {
        No: index + 1,
        key: index,
        PartNumer: item?.partNo1 ?? "",
        SubPartNo1: item?.partNo2 ?? "",
        SubPartNo2: item?.partNo3 ?? "",
        SubPartNo3: item?.partNo4 ?? "",
        SubPartNo4: item?.partNo5 ?? "",
        StockType: item?.stockType ?? "",
        Materialname: item?.materialName ?? "",
        MaterialSubname1: item?.materialName1 ?? "",
        MaterialSubname2: item?.materialName2 ?? "",
        MaterialSubname3: item?.materialName3 ?? "",
        MaterialSubname4: item?.materialName4 ?? "",
        Location: item?.location1 ?? "",
        Location1: item?.Location2 ?? "",
        Location2: item?.location3 ?? "",
        Location3: item?.location4 ?? "",
        Location4: item?.location5 ?? "",
        UOM: item?.uom ?? "",
        BookQuantity: item?.quantity ?? "-",
        Rate: item?.rate ?? "-",
        Value: item?.value ?? "",
        ExpiryDate: item?.expiryDate ?? "",
        LastReceiptDate: item?.lastReceiptDate ?? "",
        LastIssueDate: item?.lastIssueDate ?? "",
        Remarks: item?.remarks ?? ""
      }
    }))
  }, [sampleList])

  // Fetch the completed sampling bookstock data
  const getCompletedSamplingBookStockData = () => {
    setSampleDetailsLoading(true);
    fetch(Constants.API_URL.getCompletedSamplingBookStockData, {
      method:"POST",
      mode:"cors",
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${token}`,
      },
      body: JSON.stringify({
        assignmentId: parseInt(assignmentId),
        userId: user.userId,
        page: 1,
        limit: 15
      })
    })
    .then(response => { return response.json(); } )
    .then(data => {
      if(data.status === Constants.status.codeAccessTokenUnauthorized){
        localStorage.clear();
        navigate("/Login");
        dispatch(logout());
      }else if(data.status === Constants.status.success){
        setSampleList(data?.data)
        isComponentDidMountFlag(true);
        setSampleDetailsLoading(false);
      }else if(data?.errorStatus === Constants.status.codeNotAccess){
        setSampleDetailsLoading(false);
        navigate(`/Client/completedassignment/${assignmentId}/sampledetails/${randomStringGenerator(30)}`);
      }else{
        setSampleDetailsLoading(false);
        toast.error(`${data?.message}`)
      }
    });
  }

  // Function that fetches the sample list based on the provided storeId and stockId
  const getStoreStockFilterList = (storeId, stockId) => {
    let body = {
      assignmentId: parseInt(assignmentId),
      userId: user.userId,
      page: 1,
      limit: 15
    }
    if (storeId != "" && stockId == "") {
      body.storeType = storeId;
    }else if (stockId != "" && storeId == "") {
      body.stockType = stockId;
    } else if (storeId != "" && stockId != "") {
      body.storeType = storeId;
      body.stockType = stockId;
    }
    if(search) {
      body.search = encodeURIComponent(search);
    }
    if(!isEmptyVariable(sort)) {
      body.sortColumn = sort;
    }
    if(!isEmptyVariable(sortOrder)) {
      body.sortType = sortOrder;
    }
    fetch(Constants.API_URL.getCompletedSamplingBookStockData, {
      method:"POST",
      mode:"cors",
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${token}`,
      },
      body: JSON.stringify(body)
    })
    .then(response => { return response.json(); } )
    .then(data => {
      if(data.status === Constants.status.codeAccessTokenUnauthorized){
        localStorage.clear();
        navigate("/Login");
        dispatch(logout());
      }else if(data.status === Constants.status.success){
        setSampleList(data?.data)
        isComponentDidMountFlag(true);
        setSampleDetailsLoading(false);
      }else if(data?.errorStatus === Constants.status.codeNotAccess){
        setSampleDetailsLoading(false);
        navigate(`/Client/completedassignment/${assignmentId}/sampledetails/${randomStringGenerator(30)}`);
      }else{
        setSampleDetailsLoading(false);
        toast.error(`${data?.message}`)
      }
    });
  };

  // Handles changes in the table, such as search, sort, and pagination
  const handleTableChange = (type, newState) => {
    let body = {
      assignmentId: parseInt(assignmentId),
      userId: user.userId,
    }
    // storeType and stockType to the request body based on storeId and stockId
    if (storeId != "" && stockId == "") {
      body.storeType = storeId;
    }else if (stockId != "" && storeId == "") {
      body.stockType = stockId;
    } else if (storeId != "" && stockId != "") {
      body.storeType = storeId;
      body.stockType = stockId;
    }
    // Reset the page to 1 when search or sort action is triggered
    if(type === 'search' || type === 'sort'){
        body.page = 1
    }else{
        body.page = newState.page;
    }
    // Set the limit in the request body if sizePerPage exists in the newState
    if (newState.sizePerPage) {
        body.limit = newState.sizePerPage;
    }
    // Encode and add the search text to the request body if it exists in the newState
    if (!isEmptyVariable(newState?.searchText)) {
      setSearch(newState.searchText);
      body.search = encodeURIComponent(newState.searchText);
    }else{
      setSearch('');
    }
    // Conditionally set the sortColumn and sortOrder in the request body based on newState
    if (!isEmptyVariable(newState?.sortField)) {
      setSort(newState?.sortField);
      if (newState.sortField === 'PartNo') {
          body.sortColumn = 'partNo1';
      }
      if (newState.sortField === 'Materialname') {
          body.sortColumn = 'materialName';
      }
      if (newState.sortField === 'uom') {
        body.sortColumn = 'uom';
      }
      if (newState.sortField === 'rate') {
        body.sortColumn = 'rate';
      }
      if (newState.sortField === 'value') {
        body.sortColumn = 'value';
      }
      if (newState.sortField === 'bookstockquantity') {
        body.sortColumn = 'quantity';
      }
      if (newState?.sortOrder) {
        setSortOrder(newState?.sortOrder);
        body.sortType = newState.sortOrder;
      }
    }else{
      setSort('');
      setSortOrder('');
    }

    fetch(Constants.API_URL.getCompletedSamplingBookStockData, {
      method:"POST",
      mode:"cors",
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${token}`,
      },
      body: JSON.stringify(body)
    })
    .then(response => { return response.json(); } )
    .then(data => {
      if(data.status === Constants.status.codeAccessTokenUnauthorized){
        localStorage.clear();
        navigate("/Login");
        dispatch(logout());
      }else if(data.status === Constants.status.success){
        setSampleList(data?.data)
        isComponentDidMountFlag(true);
        setSampleDetailsLoading(false);
      }else if(data?.errorStatus === Constants.status.codeNotAccess){
        setSampleDetailsLoading(false);
        navigate(`/Client/completedassignment/${assignmentId}/sampledetails/${randomStringGenerator(30)}`);
      }else{
        setSampleDetailsLoading(false);
        toast.error(`${data?.message}`)
      }
    });
  };

  // Function to export data to a excel file
  const exportToCSV = () => {
    const ws = XLSX.utils.json_to_sheet(downloadReportData);
    const wb = { Sheets: { 'Sampledata': ws }, SheetNames: ['Sampledata'] };
    const excelBuffer = XLSX.write(wb, {
      bookType: 'xlsx', type: 'array'
    });
    const data = new Blob([excelBuffer], { type: fileType });
    FileSaver.saveAs(data, fileName + fileExtension);
  }

  // Handles changes to the store dropdown menu
  const handleStoreChange = (selected) => {
    setStockId('');
    setSelectedStockType('Select Stock Type')
    if (selected.value !== '') {
      setStoreId(selected.value);
      getStoreStockFilterList(selected.value, "");
      setSelectedStoreType(selected.label);
    }
    showStoreTypeDropdownItem(false);
  };
  
  // Handles changes to the stock dropdown menu
  const handleStockChange = (e) => {
    if (e.value !== '') {
      setStockId(e.value);
      getStoreStockFilterList(storeId, e.value);
      setSelectedStockType(e.label)
    }
    showStockTypeDropdownItem(false);
  };

  // Function to clear all filters and set default values
  const clearFilter = () => {
    isComponentDidMountFlag(false);
    getCompletedSamplingBookStockData();
    setStockId('');
    setSelectedStockType('Select Stock Type');
    setStoreId('');
    setSelectedStoreType('Select Store Type');
    setSearch('');
    setSort('');
    setSortOrder('');
  }

  const sampleColumn = [
    {
      dataField: "index",
      text: "No",
      headerClasses: "sr_no",
      style: { cursor: "pointer" },
      events: {
        onClick: (e, column, columnIndex, row, rowIndex) => {
          setSampleDetails(row); setSampleDetailsShow(true)
        },
      }
    },
    {
      dataField: "PartNo",
      text: "Part No.",
      sort: true,
      style: { cursor: "pointer" },
      events: {
        onClick: (e, column, columnIndex, row, rowIndex) => {
          setSampleDetails(row); setSampleDetailsShow(true)
        },
      }
    },
    {
      dataField: "Materialname",
      text: "Name",
      sort: true,
      style: { cursor: "pointer" },
      events: {
        onClick: (e, column, columnIndex, row, rowIndex) => {
          setSampleDetails(row); setSampleDetailsShow(true)
        },
      }
    },
    {
      dataField: "uom",
      text: "UOM",
      sort: true,
      style: { cursor: "pointer" },
      events: {
        onClick: (e, column, columnIndex, row, rowIndex) => {
          setSampleDetails(row); setSampleDetailsShow(true)
        },
      }
    },
    {
      dataField: "rate",
      text: "Rate",
      sort: true,
      style: { cursor: "pointer" },
      events: {
        onClick: (e, column, columnIndex, row, rowIndex) => {
          setSampleDetails(row); setSampleDetailsShow(true)
        },
      }
    },
    {
      dataField: "value",
      text: "Value",
      sort: true,
      style: { cursor: "pointer" },
      events: {
        onClick: (e, column, columnIndex, row, rowIndex) => {
          setSampleDetails(row); setSampleDetailsShow(true)
        },
      }
    },
    {
      dataField: "samplequantity",
      text: "Book Qty",
      sort: true,
      style: { cursor: "pointer" },
      events: {
        onClick: (e, column, columnIndex, row, rowIndex) => {
          setSampleDetails(row); setSampleDetailsShow(true)
        },
      }
    }
  ]

  return (
    <>
      {assignment && 
        <AssignmentListDetails
          assignment={assignment}
        />
      }
      {sampleDetailsLoading &&
        <CommonLoader loding={sampleDetailsLoading} />
      }
      <div className="main_tabs">
        <div className="master_box">
          <div className="clients_menu my-3">
          <a className='btn btn-primary me-2' 
              onClick={() => {
                navigate(`/Client/completedassignment/${assignmentId}`, {
                  state: {
                    assignmentListDetails: assignment
                  }
                })
              }}
            >
              <i className="fa fa-arrow-left" aria-hidden="true" /> </a>
            <a className='btn btn-primary' href="sampledetails"> Sample Details </a>
          </div>
          <div className="master_boxinfo">
            <div className="table-responsive pt-2 pb-5 table-height">
            {componentDidMountFlag &&
              <>
                <div className="add_btns bookstock_dropdown_width">
                  {(search != "" || sort != "" || storeId != "" || stockId != "") && 
                    <button
                      type="button"
                      className="btn btn-primary"
                      variant="primary"
                      onClick={() => clearFilter()}
                    >
                      <i
                        className="fa fa-filter"
                        aria-hidden="true"
                      ></i>{" "}
                      Clear
                    </button>
                  }
                  <RegularDropDown 
                    placeholder={selectedStoreType}
                    dropdownArr={storeOptions}
                    labelParam="label"
                    onDropDownItemClick={handleStoreChange}
                    isFocusRequired={true}
                    show={isShowStoreTypeDropDownItem}
                    onToggle={(isOpen) => showStoreTypeDropdownItem(isOpen)}
                    defaultPlaceholderDropDown={"Select Store Type"}
                  />
                  <RegularDropDown 
                    placeholder={selectedStockType}
                    dropdownArr={stockOptions}
                    labelParam="label"
                    onDropDownItemClick={handleStockChange}
                    isFocusRequired={true}
                    show={isShowStockTypeDropDownItem}
                    onToggle={(isOpen) => showStockTypeDropdownItem(isOpen)}
                    defaultPlaceholderDropDown={"Select Stock Type"}
                  />
                  <button
                    type="button"
                    className="btn btn-primary"
                    variant="primary"
                    onClick={(e) => exportToCSV()}
                  >
                    <i
                      className="fa fa-download"
                      aria-hidden="true"
                    ></i>{" "}
                    Download
                  </button>
                </div>
                <i className="fa fa-search search_icon" aria-hidden="true"></i>
              </>
              }
              {componentDidMountFlag && sampleList?.records && (
                <Table
                  data={sampleList?.records?.map((item, index) => {
                    return {
                      ...item,
                      key: index,
                      index: (sampleList?.page - 1) * sampleList.limit + index + 1,
                      id: item.id,
                      PartNo: item?.partNo1 ?? "-",
                      Materialname: item?.materialName ?? "-",
                      uom: item?.uom ?? "-",
                      rate: isEmptyVariable(item?.rate) ? "-" : formatter.format(item?.rate),
                      samplequantity: isEmptyVariable(item?.quantity) ? "-" : item?.quantity,
                      value:isEmptyVariable(item?.value) ? "-" : formatter.format(item?.value),
                    };
                  }) ?? []}
                  columns={sampleColumn}
                  handleTableChange={handleTableChange}
                  totalSize={sampleList?.totalRecords ?? 0}
                  currentPage={sampleList?.page ?? 1}
                  sizePerPage={sampleList?.limit ?? 15}
                />
              )}
              {componentDidMountFlag && isEmptyArray(sampleList?.records) && 
                <div className="no-items-layout">
                  <div className="no-items-card">
                    <h6>
                      {
                        Constants.message.noRecordsWarning
                      }
                    </h6>
                  </div>
                </div>
              }
            </div>
          </div>
        </div>
      </div>
      {sampleDetailsShow &&
        <Sampleitem
          sampledetails={sampleDetails}
          Setshowdetails={setSampleDetailsShow}
          showdetails={sampleDetailsShow}
        />
      }
    </>
  )
}

export default SampleDetails;