import { isDisabled } from '@testing-library/user-event/dist/utils';
import React, { Component } from 'react';
import Select from 'react-select';

class CommonSelect extends Component {
    constructor(props) {
        super(props);
        // Define the default style properties for the Select
        this.selectStyle = {
            width: 200
        };
    }

    // This function takes an object 'styleProperties' as an argument and returns the same object with additional properties.
    addStylePropertiesToSelect(styleProperties) {
        // Iterate over the entries in the 'this.buttonStyle' object using 'Object.entries()' method.
        Object.entries(this.selectStyle).map(([key, value]) => {
            // Check if the current key does not exist in the 'styleProperties' object.
            if(!styleProperties[key]){
                // If it doesn't exist, add the key-value pair from 'this.buttonStyle' object to 'styleProperties' object.
                styleProperties[key] = value
            }
        });
        // Finally, return the updated 'styleProperties' object.
        return styleProperties;
    }

    getSelectCustomStyle() {
        // If the dropdown has a prop 'style' then check if it exists
        if(this.props.style){
            // If the prop 'style' exists then add the style properties to the dropdown
            return this.addStylePropertiesToSelect(this.props.style);
        }else{
            // If the prop 'style' does not exist then use the default style
            return this.selectStyle;
        }
    }

    render() {
        const { options, name, placeholder, defaultMenuIsOpen, onChange, onBlur, isMulti, defaultValue, menuPlacement, isLoading, isDisabled, isSearchable , isClearable, autoFocus, value, className} = this.props;
        return (
            <Select
                options={options}
                name={name}
                placeholder={placeholder}
                onChange={onChange}
                onBlur={onBlur}
                defaultMenuIsOpen={defaultMenuIsOpen}
                isMulti={isMulti}
                defaultValue={defaultValue ? defaultValue : null}
                menuPlacement={menuPlacement ? menuPlacement : 'auto'}
                isLoading={isLoading ? isLoading : false}
                isDisabled={isDisabled ? isDisabled : false}
                isSearchable={isSearchable ? isSearchable : false}
                isClearable={isClearable ? isClearable : false}
                autoFocus={autoFocus ? autoFocus : false}
                value={value ? value : null}
                className={className}
                style={this.getSelectCustomStyle()}
            />
        );
    }
}

export default CommonSelect;
