import React, {useState, useEffect} from 'react'
import { useNavigate, useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { Modal, Dropdown } from 'react-bootstrap'
import { toast } from 'react-toastify';
import Select from 'react-select';
import { logout } from '../../../redux/action';
import '../../OngoingAssignment/assignmentstyle.css';
import "../../OngoingAssignment/reconcilation.css";
import Bookstockitem from './bookstockitem';
import * as Constants from '../../../Component/common/Global/constants';
import { isEmptyArray, isEmptyVariable } from '../../../Component/common/Global/commonFunctions';
import CommonButton from '../../../commonComponent/button/commonButton';
import { ACTUALSTOCK_FILE_DELETE, APIRequest, DELETE_COMMENT, DELETE_RECONCILATION, RECONCILATION_CREATE, RECONCILATION_LIST } from '../../../api';
import ReusableModal from '../../Modal/reusablemodel';

const ReconciliationDetail = ({ reconcilation, handleOnClose, reconcilationitem, assignment, reconcilationData }) => {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const user = useSelector((state) => state?.user);
    const token = useSelector((state) => state.token);
    const { assignmentId } = useParams();
    const [uplodedData, setUplodedData] = useState([]);
    const [stockDetailsShow, setStockDetailsShow] = useState(false);
    const [reconcilationComment, setReconcilationComment] = useState([]);
    const [fieldsDisabled, setFieldsDisabled] = useState(false);
    const [validation, setValidation] = useState({
        commentQuntityMessage: "",
        commentMessage: "",
        quntityMessage: "",
    });
    const [loading, setLoading] = useState(false);
    const [plusValue, setPlusValue] = useState(0);
    const [minusValue, setMinusValue] = useState(0);
    const [addCommetOptions, setAddCommetOptions] = useState([]);
    const [addValueData, setAddValueData] = useState([]);
    const [addSelectedData, setAddSelectedData] = useState([]);
    const [addSelectOption, setAddSelectOption] = useState([]);
    const [subCommentOptions, setSubCommentOptions] = useState([]);
    const [subValueData, setSubValueData] = useState([]);
    const [subSelectedData, setSubSelectedData] = useState([]);
    const [subSelectOption, setSubSelectOption] = useState([]);
    const [editDescriptionArray, setEditDescriptionArray] = useState([]);
    const [addDescriptionArray, setAddDescriptionArray] = useState([]);
    const [finalData, setFinalData] = useState([]);
    const [isValidate, setIsValidate] = useState(true);
    const [descriptionArray, setDescriptionArray] = useState([]);
    const [confrimDialog, setConfrimDialog] = useState(false);
    const [selectedFiles, setSelectedFiles] = useState([]);
    const [isDeleteComment, setDeleteComment] = useState(false);
    const [deleteRecoConfirm, setDeleteRecoConfirmDialog] = useState(false);
    const [deleteRecociliationId, setDeleteReconcilationId] = useState([]);
    const [deleteRecoLoading, setDeleteRecoLoading] = useState(false);
    const [deleteFileLoading, setDeleteFileLoading] = useState(false);
    const [fileId, setFileId] = useState();
    const [addComment, setAddComment] = useState([{
        actualStockId: reconcilationitem?.actualStockId,
        bookStockId: reconcilationitem?.bookStockId,
        reconcilationCommentId: "",
        reconcilationObservationsId: "",
        reconcilationCommentOther: "",
        operator: "add",
        quantity: 0,
    }])
    const [subComment, setSubComment] = useState([{
        actualStockId: reconcilationitem?.actualStockId,
        bookStockId: reconcilationitem?.bookStockId,
        reconcilationCommentId: "",
        reconcilationObservationsId: "",
        reconcilationCommentOther: "",
        operator: "substract",
        quantity: 0
    }]);
    
    const [addOtherComment, setAddOtherComment] = useState([{
        actualStockId: reconcilationitem?.actualStockId,
        bookStockId: reconcilationitem?.bookStockId,
        reconcilationObservationsId: "",
        reconcilationCommentOther: "",
        operator: "add",
        quantity: 0
    }]);
    const [subOtherComment, setSubOtherComment] = useState([{
        actualStockId: reconcilationitem?.actualStockId,
        bookStockId: reconcilationitem?.bookStockId,
        reconcilationObservationsId: "",
        reconcilationCommentOther: "",
        operator: "substract",
        quantity: 0
    }]);

    useEffect(() => {
        new APIRequest.Builder()
            .get()
            .setReqId(RECONCILATION_LIST)
            .reqURL("reconcilation/getReconcilationCommentsList?page=1&limit=15")
            .response(onResponse)
            .error(onError)
            .build()
            .doRequest();
        // if(!isEmptyArray(reconcilationData)){
            getOngoingActualReconcilationData();
        //     setFieldsDisabled(true);
        // }else{
        //     setFieldsDisabled(false);
        // }
    }, [])

    useEffect(() => {
        for (let i = 0; i < uplodedData?.length; i++) editDescriptionArray.push({ description: "", uploadedFileId: "" });
    }, [uplodedData])

    useEffect(() => {
        const addCommentDefaultValue = reconcilationComment?.filter((item, id) => item?.operator === "add" && item?.reconcilationCommentId != null)
        if (addCommentDefaultValue?.length > 0) {
            setAddComment(addCommentDefaultValue?.map((item, index) => {
                return ({
                    actualStockId: item?.actualStockId,
                    bookStockId: item?.bookStockId,
                    reconcilationCommentId: item?.reconcilationCommentId,
                    reconcilationCommentOther: item?.reconcilationCommentOther,
                    operator: item?.operator,
                    reconcilationObservationsId: item?.reconcilationObservationsId,
                    quantity: item?.quantity
                })
            }));
            setAddSelectedData(addCommentDefaultValue?.map((item, index) => {
                return ({
                    key: index,
                    reconcilationCommentId: item?.reconcilationCommentId,
                    reconcilationCommentOther: item?.reconcilationCommentOther,
                    reconcilationObservationsId: item?.reconcilationObservationsId,
                })
            }));
            setAddValueData(addCommentDefaultValue?.map((item, index) => {
                return ({ quantity: item?.quantity })
            }));
        }else{
            setAddComment([{ 
                reconcilationCommentId: "",
                operator: "add",
                reconcilationObservationsId: "",
                reconcilationCommentOther: "",
                quantity: 0
            }]);
            setAddSelectedData([])
            setAddValueData([])
        }
    }, [reconcilationComment])

    useEffect(() => {
        const subCommentDefaultValue = reconcilationComment?.filter((item, id) => item?.operator === "substract" && item?.reconcilationCommentId != null)
        if (subCommentDefaultValue?.length > 0) {
            setSubComment(subCommentDefaultValue?.map((item, index) => {
                return ({
                    actualStockId: item?.actualStockId,
                    bookStockId: item?.bookStockId,
                    reconcilationCommentId: item?.reconcilationCommentId,
                    reconcilationCommentOther: item?.reconcilationCommentOther,
                    reconcilationObservationsId: item?.reconcilationObservationsId,
                    operator: item.operator,
                    quantity: item?.quantity
                })
            }));
            setSubSelectedData(subCommentDefaultValue?.map((item, index) => {
                return ({
                    key: index,
                    reconcilationCommentId: item?.reconcilationCommentId,
                    reconcilationCommentOther: item?.reconcilationCommentOther,
                    reconcilationObservationsId: item?.reconcilationObservationsId,
                })
            }));
            setSubValueData(subCommentDefaultValue?.map((item, index) => {
                return ({
                    key: index,
                    quantity: item?.quantity
                })
            }));
        }else{
            setSubComment([{ reconcilationCommentId: "", operator: "substract", reconcilationObservationsId: "", reconcilationCommentOther: "", quantity: 0 }]);
            setSubSelectedData([])
            setSubValueData([])
        }
    }, [reconcilationComment])

    useEffect(() => {
        if(isEmptyArray(reconcilationComment)){
            setFieldsDisabled(false);
        }else{
            setFieldsDisabled(true);
        }
    },[reconcilationComment])

    useEffect(() => {
        const addOtherCommentDefaultValue = reconcilationComment?.filter((item, id) => item?.operator === 'add' && item?.reconcilationCommentId == null)
        
        if (addOtherCommentDefaultValue?.length > 0) {
            setAddOtherComment(addOtherCommentDefaultValue?.map((item, index) => {
                return ({
                    // key: index,
                    actualStockId: item?.actualStockId,
                    bookStockId: item?.bookStockId,
                    reconcilationCommentOther: item?.reconcilationCommentOther,
                    reconcilationObservationsId: item?.reconcilationObservationsId,
                    quantity: item?.quantity,
                    operator: item?.operator
                })
            }))
        }else{
            setAddOtherComment([{
                actualStockId: reconcilationitem?.actualStockId,
                bookStockId: reconcilationitem?.bookStockId,
                reconcilationCommentOther: "",
                reconcilationObservationsId: reconcilationitem.reconcilationObservationsId,
                operator: "add",
                quantity: 0
            }])
        }
        
        const subOtherCommentDefaultValue = reconcilationComment?.filter((item, id) => item?.operator === 'substract' && item?.reconcilationCommentId == null)
        if (subOtherCommentDefaultValue?.length > 0) {
            setSubOtherComment(subOtherCommentDefaultValue?.map((item, index) => {
                return ({
                    // key: index,
                    actualStockId: item?.actualStockId,
                    bookStockId: item?.bookStockId,
                    reconcilationCommentOther: item?.reconcilationCommentOther,
                    reconcilationObservationsId: item.reconcilationObservationsId,
                    quantity: item?.quantity,
                    operator: item?.operator
                })
            }))
        }else{
            setSubOtherComment([{
                actualStockId: reconcilationitem?.actualStockId,
                bookStockId: reconcilationitem?.bookStockId,
                reconcilationCommentOther: "",
                reconcilationObservationsId: reconcilationitem.reconcilationObservationsId,
                operator: "substract",
                quantity: 0
            }]);
        }
    }, [reconcilationComment])

    useEffect(() => {
        let finalValue = [];
        finalValue = [...addComment, ...subComment, ...addOtherComment, ...subOtherComment]
        Object.keys(finalValue).forEach(key => {
            if (finalValue[key].quantity === undefined || finalValue[key].quantity === 0 || finalValue[key].quantity === null || finalValue[key].reconcilationCommentOther === undefined) {
                delete finalValue[key];
            }
        });
        let finalData = finalValue.filter(function (element) {
            return element !== undefined;
        });
        setFinalData(finalData);
        setAddComment(addComment);
        setSubComment(subComment);
        setAddOtherComment(addOtherComment);
        setSubOtherComment(subOtherComment);
    }, [addComment, subComment, addOtherComment, subOtherComment, validation, isValidate])

    // Calculate the add and less total based on operator
    useEffect(() => {
        setPlusValue(sumfunction([...addComment, ...addOtherComment]))
        setMinusValue(sumfunction([...subComment, ...subOtherComment]))
        // setPlusValue(sumfunction(reconcilationComment.filter(item => item.operator == 'add')))
        // setMinusValue(sumfunction(reconcilationComment.filter(item => item.operator == 'substract')))
    }, [reconcilationComment,addComment,subComment,addOtherComment, subOtherComment])

    useEffect(() => {
        setAddComment(previous =>
            previous?.map((elem, index) => {
                return ({
                    ...elem,
                    actualStockId: reconcilationitem?.actualStockId,
                    operator: "add",
                    bookStockId: reconcilationitem?.bookStockId,
                    reconcilationCommentId: addSelectedData[index]?.reconcilationCommentId,
                    reconcilationCommentOther: addSelectedData[index]?.reconcilationCommentOther,
                    reconcilationObservationsId: addSelectedData[index]?.reconcilationObservationsId,
                    quantity: addValueData[index]?.quantity
                })
            })
        );

        setAddSelectOption(filterByReference(addCommetOptions, addSelectedData));
    }, [addSelectedData, addValueData, addCommetOptions])

    useEffect(() => {
        setSubComment(previous =>
            previous?.map((elem, index) => {
                return ({
                    ...elem,
                    actualStockId: reconcilationitem?.actualStockId,
                    bookStockId: reconcilationitem?.bookStockId,
                    reconcilationCommentId: subSelectedData[index]?.reconcilationCommentId,
                    reconcilationCommentOther: subSelectedData[index]?.reconcilationCommentOther,
                    quantity: subValueData[index]?.quantity,
                    reconcilationObservationsId: subSelectedData[index]?.reconcilationObservationsId,
                    operator: "substract"
                })
            })
        )

        setSubSelectOption(filterByReference(subCommentOptions, subSelectedData))
    }, [subValueData, subSelectedData, subCommentOptions])

    const filterByReference = (arr1, arr2) => {
        let res = [];
        res = arr1.filter(el => {
            return !arr2.find(element => {
                return element?.reconcilationCommentId === el?.id;
            });
        });
        return res;
    }
    
    const validationForms = (comment) => {
        let errors = {};
        if (finalData?.length == 0) {
            errors.commentQuntityMessage = "Please enter the reconciliation comment and its quantity";
            setIsValidate(false);
        } else {
            setLoading(false);
            setIsValidate(true);
            comment.forEach((item) => {
                const { reconcilationCommentOther, quantity } = item;
                if (!reconcilationCommentOther && (quantity != 0 && quantity != undefined)) {
                    if (!errors.commentMessage) {
                        errors.commentMessage = "Please enter comment";
                    }
                    setIsValidate(false);
                } else {
                    setIsValidate(true);
                }
                if (!quantity && (reconcilationCommentOther != "" && reconcilationCommentOther != undefined)) {
                    if (!errors.quntityMessage) {
                        errors.quntityMessage = "Please enter quantity";
                    }
                    setIsValidate(false);
                } else {
                    setIsValidate(true);
                }
            });
        }
        setValidation(errors);
        return errors;
    }

    // Fetch the reconciliation comment and uploaded file
    const getOngoingActualReconcilationData = () => {
        fetch(Constants.API_URL.getOngoingActualReconcilationData, {
            method:"POST",  
            mode:"cors",
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${token}`,
            },
            body: JSON.stringify({
                actualStockId: reconcilationitem?.actualStockId,
                assignmentId: parseInt(assignmentId),
                userId: user.userId,
            })
        })
        .then(response => { return response.json(); } )
        .then(data => {
            if(data.status === Constants.status.codeAccessTokenUnauthorized){
                localStorage.clear();
                navigate("/Login");
                dispatch(logout());
            }else if(data.status === Constants.status.success){
                setReconcilationComment(data?.data?.records);
                setUplodedData(data?.data?.uploadedFiles)
            }else{
              toast.error(`${data?.message}`);
            }
        });
    }

    // Generate file URL for download
    const generateFileUrl = (fileName) => {
        fetch(Constants.API_URL.generateFileUrl, {
            method:"POST",
            mode:"cors",
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${token}`,
            },
            body: JSON.stringify({
                fileName:fileName,
                type:Constants.fileType.reconcilations
            })
        })
        .then(response => { return response.json(); } )
        .then(data => {
            if(data.status === Constants.status.codeAccessTokenUnauthorized){
                localStorage.clear();
                navigate("/Login");
                dispatch(logout());
            }else if(data.status === Constants.status.success){
                const link = document.createElement('a');
                link.href = data.data;
                document.body.appendChild(link);
                link.click();
                document.body.removeChild(link);
            }else{
                toast.error(`${data?.message}`);
            }
        });
    }

    // Function to calculate the sum of 'quantity' properties in an array
    const sumfunction = (value) => {
        var sum = 0;
        value?.forEach(subData => { 
            if(subData?.quantity != undefined){
                sum += +subData?.quantity
            }
        });
        return sum;
    }
    
    // Function to remove everything after '#' in a filename
    function removeValueAfterHash(filename) {
        // Use regular expression to replace any characters between '#' and the end of the string with '.'
        const cleanedFilename = filename.replace(/-_-.+?\./, '.');
        return cleanedFilename;
    }

     // submit function
     const submitHandler = () => {
         let finalValue = [];
         finalValue = [...addComment, ...subComment, ...addOtherComment, ...subOtherComment]
        Object.keys(finalValue).forEach(key => {
            
            if (finalValue[key].quantity === undefined || finalValue[key].quantity === 0 || finalValue[key].quantity === null || finalValue[key].reconcilationCommentOther === undefined) {
                delete finalValue[key];
            }
        });
        let finaldata = finalValue.filter(function (element) {
            return element !== undefined;
        });
        const filteredDescriptions = editDescriptionArray.filter(item => item.description !== "" || item.uploadedFileId !== "");
        const mergedArray = [...addDescriptionArray, ...filteredDescriptions];
        var reconcilationFormData = new FormData();
        reconcilationFormData.append('assignmentId', assignmentId);
        reconcilationFormData.append(`operations`, JSON.stringify(finaldata));
        selectedFiles.forEach((file) => {
            reconcilationFormData.append('reconcilationDocumentType', file);
        });
        reconcilationFormData.append("descriptions", JSON.stringify(mergedArray));
        const errors = validationForms([...addComment,...subComment,...addOtherComment,...subOtherComment]);
        if (Object.keys(errors).length === 0) {
            if(deleteRecociliationId.length > 0){
                deleteRecociliationId.map(item => {
                    deleteComment(item);
                })
            }
            setValidation([{
                commentQuntityMessage: "",
                commentMessage: "",
                quntityMessage: "",
            }]);
            setLoading(true);
            new APIRequest.Builder()
                .post()
                .setReqId(RECONCILATION_CREATE)
                .reqURL("bookstock/addReconcilation")
                .jsonParams(reconcilationFormData)
                .response(onResponse)
                .error(onError)
                .build()
                .doRequest()
        }else{
            setValidation(errors);
            setTimeout(() => {
                setValidation({
                    commentQuntityMessage: "",
                    commentMessage: "",
                    quntityMessage: ""
                })
            },3000)
            setLoading(false);
        }
    }

    const deleteComment = (id) => {
        new APIRequest.Builder()
            .delete()
            .setReqId(DELETE_COMMENT)
            .reqURL(`bookstock/deleteReconcilationObservation/${id}`)
            .response(onResponse)
            .error(onError)
            .build()
            .doRequest();
    }

    const deleteUplodedFiles = () => {
        setDeleteFileLoading(true);
        new APIRequest.Builder()
            .delete()
            .setReqId(ACTUALSTOCK_FILE_DELETE)
            .reqURL(`bookstock/uploadedFiles/${fileId}`)
            .response(onResponse)
            .error(onError)
            .build()
            .doRequest();
    }

    const deleteReconcilation = () => {
        setDeleteRecoLoading(true);
        new APIRequest.Builder()
            .post()
            .setReqId(DELETE_RECONCILATION)
            .jsonParams({
                userId: user.userId,
                assignmentId: parseInt(assignmentId),
                actualStockId: reconcilationitem?.actualStockId,
                bookStockId: reconcilationitem?.bookStockId,
            })
            .reqURL("bookstock/deleteReconcilation")
            .response(onResponse)
            .error(onError)
            .build()
            .doRequest()
    }

    const addOptionFunction = () => {
        if (addComment[addComment.length - 1].quantity !== "" && addComment[addComment.length - 1].quantity !== undefined && addComment[addComment.length - 1].quantity !== null) {
            setAddComment(prev => [...prev, {
                reconcilationCommentId: null,
                reconcilationCommentOther: null,
                quantity: 0
            }])
        }
    }

    const handleFileChange = (event) => {
        for (let i = 0; i < event.target.files.length; i++) addDescriptionArray.push({ description: "", uploadedFileId: "" });
        setSelectedFiles([...selectedFiles, ...event.target.files]);
    }

    const handleChangeInput = (e, index) => {
        const updatedArray = [...addDescriptionArray];
        updatedArray[index].description = e.target.value;
        descriptionArray[index].uploadedFileId = null;
        setAddDescriptionArray([...updatedArray]);
    }

    const handleChangevalue = (idd, e) => {
        let nextaddcomment = [
            ...addValueData.slice(0, idd),
            { quantity: e.target.value },
            ...addValueData.slice(idd + 1)
        ];
        setAddValueData(nextaddcomment);
    };

    const handleChangeInput1 = (e, index, item) => {
        const updatedArray = [...editDescriptionArray];
        updatedArray[index].description = e.target.value;
        updatedArray[index].uploadedFileId = item?.uploadedFileId;
        setEditDescriptionArray([...updatedArray]);
    }
    
    function handleChangeoption(idd, e, item) {
        // let Newaerry = [...subOtherComment];
        // Newaerry[id].reconcilationCommentOther = e.target.value;
        // Newaerry[id].actualStockId = reconcilationitem?.actualStockId;
        // Newaerry[id].bookStockId = reconcilationitem?.bookStockId;
        const nextaddcomment = [
            ...addSelectedData.slice(0, idd),
            {
                reconcilationCommentId: e.value,
                reconcilationCommentOther: e.label,
                reconcilationObservationsId: item?.reconcilationObservationsId
            },
            ...addSelectedData.slice(idd + 1)
        ];
        setAddSelectedData(nextaddcomment);
    }

    const deleteFilesTamp = (i) => {
        const row = [...selectedFiles];
        row.splice(i, 1);
        const darray = [...addDescriptionArray];
        darray.splice(i, 1);
        setAddDescriptionArray(darray);
        setSelectedFiles(row);
    }

    const othercommentdelete = (id, reId) => {
        // deleteComment(reId);
        setDeleteReconcilationId([...deleteRecociliationId, reId]);
        if (addOtherComment.length > 1) {

            if (id !== 0) {
                let deletedata = [
                    ...addOtherComment.slice(0, id),
                    ...addOtherComment.slice(id + 1)
                ]
                setAddOtherComment(deletedata)
            }
        }
        else {
            setAddOtherComment([{
                actualStockId: reconcilationitem?.actualStockId,
                bookStockId: reconcilationitem?.bookStockId,
                reconcilationObservationsId: "",
                reconcilationCommentOther: "",
                operator: "add",
                quantity: 0
            }])

        }
    }

    const minusOptionFunction = () => {
        if (subComment[subComment.length - 1].quantity !== "" && subComment[subComment.length - 1].quantity !== undefined && subComment[subComment.length - 1].quantity !== null) {
            setSubComment(prev => [...prev, {
                reconcilationCommentId: null,
                reconcilationCommentOther: null,
                reconcilationObservationsId: null,
                quantity: 0
            }])
        }
    }
    const deleteFiles = (uplodedfileId) => {
        setFileId(uplodedfileId);
        setConfrimDialog(true);
    }
    function del_handleChangeoption(idd, e, item) {
        const nextaddcomment = [
            ...subSelectedData.slice(0, idd),
            { reconcilationCommentId: e.value, reconcilationCommentOther: e.label, reconcilationObservationsId: item?.reconcilationObservationsId },
            ...subSelectedData.slice(idd + 1)
        ];
        setSubSelectedData(nextaddcomment);
    }

    const del_handleChangevalue = (idd, e) => {
        let nextaddcomment = [
            ...subValueData.slice(0, idd),
            { quantity: e.target.value },
            ...subValueData.slice(idd + 1)

        ];
        setSubValueData(nextaddcomment);
    };

    const del_othercommentdelete = (id, reId) => {
        // deleteComment(reId);
        setDeleteReconcilationId([...deleteRecociliationId, reId]);
        if (subOtherComment.length > 1) {
            if (id !== 0) {
                let deletedata = [
                    ...subOtherComment.slice(0, id),
                    ...subOtherComment.slice(id + 1)
                ]
                setSubOtherComment(deletedata)
            }
        } else {
            setSubOtherComment([{
                actualStockId: reconcilationitem?.actualStockId,
                bookStockId: reconcilationitem?.bookStockId,
                reconcilationObservationsId: "",
                reconcilationCommentOther: "",
                operator: "substract",
                quantity: 0
            }]);
        }
    }

    const Deleteminusconcilation = (idd, reId) => {
        // deleteComment(reId);
        setDeleteReconcilationId([...deleteRecociliationId, reId]);
        if (subComment.length > 1) {
            let dnextselecteddata = [
                ...subSelectedData.slice(0, idd),
                ...subSelectedData.slice(idd + 1)
            ]
            setSubSelectedData(dnextselecteddata);

            let dnextvaluedata = [
                ...subValueData.slice(0, idd),
                ...subValueData.slice(idd + 1)
            ]
            setSubValueData(dnextvaluedata);

            let dnextaddcomment = [
                ...subComment.slice(0, idd),
                ...subComment.slice(idd + 1)
            ];
            setSubComment(dnextaddcomment);
        }
        else {
            setSubComment([{
                actualStockId: reconcilationitem?.actualStockId,
                bookStockId: reconcilationitem?.bookStockId,
                reconcilationCommentId: "",
                reconcilationObservationsId: "",
                reconcilationCommentOther: "",
                operator: "substract",
                quantity: 0
            }])
            setSubSelectedData([]);
            setSubValueData([]);
        }
    }

    const DeleteReconcilation = (idd, reId) => {
        // deleteComment(reId);
        setDeleteReconcilationId([...deleteRecociliationId, reId]);
        if (addComment.length > 1) {
            let nextselecteddata = [
                ...addSelectedData.slice(0, idd),
                ...addSelectedData.slice(idd + 1)
            ]
            setAddSelectedData(nextselecteddata);

            let nextvaluedata = [
                ...addValueData.slice(0, idd),
                ...addValueData.slice(idd + 1)
            ]
            setAddValueData(nextvaluedata);

            let nextaddcomment = [
                ...addComment.slice(0, idd),
                ...addComment.slice(idd + 1)
            ];
            setAddComment(nextaddcomment);
        } else {
            setAddComment([{
                actualStockId: reconcilationitem?.actualStockId,
                bookStockId: reconcilationitem?.bookStockId,
                reconcilationCommentId: "",
                reconcilationObservationsId: "",
                reconcilationCommentOther: "",
                operator: "add",
                quantity: 0,
            }]);
            setAddSelectedData([]);
            setAddValueData([]);
        }
    }

    const onResponse = (response, reqId) => {
        switch (reqId) {

            case RECONCILATION_CREATE:
                setLoading(false);
                toast.success(`${response?.data.message}`);
                handleOnClose(true);
                // setSubSelectOption([]);
                // setSubValueData([]);
                // setSubSelectedData([]);
                // setAddSelectOption([]);
                // setAddValueData([]);
                // setAddSelectedData([]);
                // setEditDescriptionArray([]);
                // setAddComment([{ reconcilationCommentId: "", reconcilationCommentOther: "", reconcilationObservationsId: "", operator: "add", quantity: 0 }]);
                // setSubComment([{ reconcilationCommentId: "", reconcilationObservationsId: "", reconcilationCommentOther: "", operator: "substract", quantity: 0 }]);
                // setAddOtherComment([{
                //     actualStockId: reconcilationitem?.actualStockId,
                //     bookStockId: reconcilationitem?.bookStockId,
                //     reconcilationCommentOther: reconcilationitem.reconcilationCommentOther,
                //     reconcilationObservationsId: reconcilationitem.reconcilationObservationsId,
                //     operator: "add",
                //     quantity: 0
                // }]);
                // setSubOtherComment([{
                //     actualStockId: reconcilationitem?.actualStockId,
                //     bookStockId: reconcilationitem?.bookStockId,
                //     reconcilationCommentOther: reconcilationitem.reconcilationCommentOther,
                //     reconcilationObservationsId: reconcilationitem.reconcilationObservationsId,
                //     operator: "substract",
                //     quantity: 0
                // }]);
                break;
            case RECONCILATION_LIST:
                setAddCommetOptions(response?.data.data?.records?.filter((value) => value.operation === "add"));
                setSubCommentOptions(response?.data.data?.records?.filter((value) => value.operation === "substract"))
                break;
            case DELETE_COMMENT:
                // toast.success(response?.data.message);
                setDeleteComment(true);
                setDeleteReconcilationId([]);
                break;
            case ACTUALSTOCK_FILE_DELETE:
                toast.success(response?.data?.message);
                setConfrimDialog(false);
                setDeleteFileLoading(false);
                break;
            case DELETE_RECONCILATION:
                toast.success(`${response.data?.message}`);
                setDeleteRecoConfirmDialog(false);
                handleOnClose(true);
                setDeleteRecoLoading(false);
            default:
                break;
        }
    };

    const onError = (response, reqId) => {
        switch (reqId) {
            case ACTUALSTOCK_FILE_DELETE:
                if (response.data?.status === Constants.status.codeAccessTokenUnauthorized) {
                    localStorage.clear();
                    navigate("/Login");
                    dispatch(logout());
                } else {
                    toast.error(`${response.data?.message}`);
                    setConfrimDialog(false);
                    setDeleteFileLoading(false);
                }
                break;
            case DELETE_COMMENT:
                if (response.data?.status === Constants.status.codeAccessTokenUnauthorized) {
                    localStorage.clear();
                    navigate("/Login");
                    dispatch(logout());
                } else {
                    toast.error(`${response.data?.message}`);
                }
                break;

            case RECONCILATION_LIST:
                if (response.data?.status === Constants.status.codeAccessTokenUnauthorized) {
                    localStorage.clear();
                    navigate("/Login");
                    dispatch(logout());
                } else {

                }
                break;
            case RECONCILATION_CREATE:
                if (response.data?.status === Constants.status.codeAccessTokenUnauthorized) {
                    localStorage.clear();
                    navigate("/Login");
                    dispatch(logout());
                } else {
                    setLoading(false);
                    // toast.error(`${response?.data?.message}`)

                }
                break;
            case DELETE_RECONCILATION:
                if (response.data?.status === Constants.status.codeAccessTokenUnauthorized) {
                    localStorage.clear();
                    navigate("/Login");
                    dispatch(logout());
                } else {
                    setDeleteRecoLoading(false);
                }
                break;
            // case UPLOAD_FILES:
            //     if (response.data?.status === Constants.status.codeAccessTokenUnauthorized) {
            //         localStorage.clear();
            //         navigate("/Login");
            //         dispatch(logout());
            //     } else {
            //         toast.success(response?.data?.message);
            //     }
            //     break;
            default:
                break;
        }
    };

    const selectcomment = addSelectOption.filter(e => !addSelectedData.find(item => (item.reconcilationCommentId === e.reconcilationCommentsId)))
        .map((item) => ({ "label": item.comment, "value": item.reconcilationCommentsId }));
    const del_selectcomment = subSelectOption.filter(e => !subSelectedData.find(item => (item.reconcilationCommentId === e.reconcilationCommentsId)))
        .map((item) => ({ "label": item.comment, "value": item.reconcilationCommentsId }));
   
    // Calculate the balance based on difference, plusValue, and minusValue
    const difference = reconcilationitem?.actualStockQuantity - reconcilationitem?.quantity;
    const balance = difference + (isNaN(plusValue) ? 0 : plusValue) - (isNaN(minusValue) ? 0 : minusValue);

    return (
        <>
            <Modal show={reconcilation} size="md" >
                <Modal.Header>
                    <Modal.Title>Reconciliation Detail</Modal.Title>
                    <div
                        onClick={() => {
                            if(isDeleteComment){
                                handleOnClose(true);
                            }else{
                                handleOnClose();
                            }
                            setSubSelectOption([]);
                            setSubValueData([]);
                            setSubSelectedData([]);
                            setAddSelectOption([]);
                            setAddValueData([]);
                            setAddSelectedData([]);
                            setAddComment([{
                                reconcilationCommentId: "",
                                reconcilationObservationsId: "",
                                reconcilationCommentOther: "",
                                operator: "add",
                                quantity: 0
                            }]);
                            setSubComment([{ 
                                reconcilationCommentId: "", 
                                reconcilationObservationsId: "", 
                                reconcilationCommentOther: "", 
                                operator: "substract", 
                                quantity: 0
                            }]);
                            setAddOtherComment([{
                                actualStockId: reconcilationitem?.actualStockId,
                                bookStockId: reconcilationitem?.bookStockId,
                                reconcilationCommentOther: "",
                                reconcilationObservationsId: reconcilationitem.reconcilationObservationsId,
                                operator: "add",
                                quantity: 0
                            }]);
                            setSubOtherComment([{
                                actualStockId: reconcilationitem?.actualStockId,
                                bookStockId: reconcilationitem?.bookStockId,
                                reconcilationCommentOther: "",
                                reconcilationObservationsId: reconcilationitem.reconcilationObservationsId,
                                operator: "substract",
                                quantity: 0
                            }]);
                            setSelectedFiles([]);
                            setDescriptionArray([]);
                            setAddDescriptionArray([]);
                            setEditDescriptionArray([]);
                            setDeleteReconcilationId([]);
                        }}
                        data-dismiss="modal">
                        <i className="fa fa-close" aria-hidden="true"></i>
                    </div>
                </Modal.Header>
                <Modal.Body className='pb-0 position-relative'>
                    <div className='row'>
                        <div className='col md-12 text-center'>
                            <h6>Part Number : {!isEmptyVariable(reconcilationitem?.partNo1) ? reconcilationitem?.partNo1 : '-'}
                                <i
                                    className="fa fa-info-circle"
                                    aria-hidden="true"
                                    style={{ 
                                        fontSize: "20px",
                                        color: "#552B84"
                                    }}
                                    onClick={() => {
                                        setStockDetailsShow(true)
                                    }}
                                />
                            </h6>
                            <h6>Material Name : {!isEmptyVariable(reconcilationitem?.materialName) ? reconcilationitem?.materialName : '-'}</h6>
                        </div>
                        <div className='modal-labels reconlable'>
                            <p>Actual stock</p>
                            <p>{reconcilationitem?.actualquantity}</p>
                        </div>
                        <div className='modal-labels reconlable'>
                            <p>Book Stock</p>
                            <p>{reconcilationitem?.quantity}</p>
                        </div>
                        <div className='modal-labels reconlable'>
                            <p>Difference</p>
                            <p>{isNaN(difference) ? 0 : difference}</p>
                        </div>
                        {fieldsDisabled ?
                        <div className='col-md-12'>
                            <div className='row'>
                                <div className='col-md-12'>
                                    <span className='mb-2 fw-bold'>Add</span>
                                </div>
                                <div className='col-md-12'>
                                    <Dropdown.Divider />
                                </div>
                                {reconcilationComment.map(item => {
                                    return (
                                        item.operator == 'add' &&
                                        <>
                                            <div className='col-md-9 mb-2 reconlable'>
                                                <p>{item?.reconcilationCommentOther}</p>
                                            </div>
                                            <div className='col-md-3 mb-2 reconlable'>
                                                <p>{item?.quantity ?? ""}</p>
                                            </div>
                                        </>
                                    )
                                })}
                            </div>
                            <div className='modal-labels reconlable'>
                                <p>Add total</p>
                                <p style={{ fontSize: "12px" }}>
                                    {!isNaN(plusValue) ? plusValue : 0}
                                </p>
                            </div>
                            <div className='row'>
                                <div className='col-md-12'>
                                    <span className='mb-2 fw-bold'>Less</span>
                                </div>
                                <div className='col-md-12'>
                                    <Dropdown.Divider />
                                </div>
                                {reconcilationComment.map(item => {
                                    return (
                                        item.operator == 'substract' &&
                                        <>
                                            <div className='col-md-9 mb-2 reconlable'>
                                                <p>{item?.reconcilationCommentOther}</p>
                                            </div>
                                            <div className='col-md-3 mb-2 reconlable'>
                                                <p>{item?.quantity ?? ""}</p>
                                            </div>
                                        </>
                                    )
                                })}
                            </div>
                            <div className='modal-labels reconlable'>
                                <p>Less total</p>
                                <p style={{ fontSize: "12px" }}>
                                    {!isNaN(minusValue) ? minusValue : 0}
                                </p>
                            </div>
                            <div className='col-md-12'>
                                <Dropdown.Divider />
                            </div>
                            <div className='modal-labels reconlable'>
                                <p>Balance</p>
                                <p>{isNaN(balance) ? "0" : balance}</p>
                            </div>
                            {uplodedData?.length > 0 && 
                                <>
                                    <div>
                                        <table className='table'>
                                            <thead>
                                                <tr>
                                                    <th>File Name</th>
                                                    <th>Description</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {uplodedData.map((e, index) =>
                                                    <>
                                                        <tr key={index}>
                                                            <td>
                                                                <a href="javascript:void(0);" 
                                                                    onClick={() => generateFileUrl(e.uploadedFilePath)}
                                                                    style={{ 
                                                                        fontSize: "12px",
                                                                        color: "purple",
                                                                        textDecoration: "none",
                                                                        wordWrap: "break-word",
                                                                        textAlign: "left"
                                                                    }}
                                                                >
                                                                    {removeValueAfterHash(e.uploadedFilePath)}
                                                                </a>
                                                            </td>
                                                            <td>
                                                                {e?.description}
                                                            </td>
                                                        </tr>
                                                    </>
                                                )}
                                            </tbody>
                                        </table>
                                    </div>
                                </>
                            }
                        </div>
                        : 
                        <div className='col-md-12'>
                            <div className='row'>
                                <div className='col-md-12'>
                                    <button 
                                        type="button" 
                                        disabled={fieldsDisabled} 
                                        className="btn btn-primary mb-2" 
                                        variant="primary" 
                                        onClick={() => addOptionFunction()}>
                                            {fieldsDisabled ? "" : 
                                            <i className="fa fa-plus-circle" aria-hidden="true" ></i>}{" "}
                                            Add
                                    </button>
                                </div>
                                <div className='col-md-12'>
                                    <Dropdown.Divider />
                                </div>
                                {addComment?.map((item, idd) => {
                                    return (
                                        <>{((item?.reconcilationCommentOther !== undefined && item?.reconcilationCommentOther !== '') || (item.quantity !== 0 && item.quantity !== undefined)) || fieldsDisabled == false ? <>
                                            <div className='col-md-8 mb-2 reconcilation-select'>
                                                <Select
                                                    className='reduceFonts'
                                                    id="comment"
                                                    name="comment"
                                                    options={selectcomment}
                                                    isDisabled={fieldsDisabled}
                                                    onChange={value => { 
                                                        handleChangeoption(idd, value, item);
                                                    }}
                                                    value={{ "label": item?.reconcilationCommentOther ?? "Select Comment" }}/>
                                            </div>
                                            <div className='col-md-1'>
                                                {fieldsDisabled ? "" : <i className="fa fa-trash" aria-hidden="true" type='button' onClick={() => DeleteReconcilation(idd, item?.reconcilationObservationsId)}></i>}
                                            </div>
                                            <div className='col-md-2'>
                                                <input className="form-control" id="name" name="name" type="number" value={item?.quantity}
                                                    disabled={fieldsDisabled} onWheel={e => e.currentTarget.blur()}
                                                    onChange={e => { 
                                                        handleChangevalue(idd, e);
                                                    }}
                                                />
                                            </div></> : ""}
                                        </>
                                    )
                                }
                                )}
                                <label>Other</label>
                                {addOtherComment?.map((item, id) => {
                                    return (
                                        <>
                                            {((item?.reconcilationCommentOther !== undefined && item?.reconcilationCommentOther !== '') || (item.quantity !== 0 && item.quantity !== undefined)) || fieldsDisabled == false ?
                                                <>
                                                    <div className='col-md-8 mb-2'>
                                                        <input className='form-control' name='other' id='other' type="text" value={item?.reconcilationCommentOther}
                                                            onChange={e => {
                                                                let Newaerry = [...addOtherComment]; Newaerry[id].reconcilationCommentOther = e.target.value;
                                                                Newaerry[id].actualStockId = reconcilationitem?.actualStockId;
                                                                Newaerry[id].bookStockId = reconcilationitem?.bookStockId;
                                                                setAddOtherComment(Newaerry);
                                                            }}
                                                            disabled={fieldsDisabled}
                                                        />
                                                    </div>
                                                    <div className='col-md-1'>
                                                        <div className='d-flex modal-delet-icon align-items-center'>
                                                            {fieldsDisabled ? "" : <i className="fa fa-plus" aria-hidden="true" type='button' onClick={() => {
                                                                if (addOtherComment[addOtherComment.length - 1].quantity !== 0 && addOtherComment[addOtherComment.length - 1].quantity !== undefined && addOtherComment[addOtherComment.length - 1].quantity !== null) {
                                                                    setAddOtherComment(prev => [...prev, {
                                                                        actualStockId: reconcilationitem?.actualStockId,
                                                                        bookStockId: reconcilationitem?.bookStockId,
                                                                        reconcilationCommentOther: "",
                                                                        reconcilationObservationsId: reconcilationitem.reconcilationObservationsId,
                                                                        operator: "add",
                                                                        quantity: 0
                                                                    }])
                                                                }
                                                            }}></i>
                                                            } &nbsp;
                                                            {fieldsDisabled ? "" : <i className="fa fa-trash" aria-hidden="true" type='button' onClick={() => othercommentdelete(id, item?.reconcilationObservationsId)}></i>}
                                                        </div>
                                                    </div>
                                                    <div className='col-md-2'>
                                                        <input className="form-control" id="name" name="name" type="number" value={item?.quantity} onWheel={e => e.currentTarget.blur()}
                                                            onChange={e => { 
                                                                let Newaerry = [...addOtherComment];
                                                                Newaerry[id].quantity = e.target.value;
                                                                setAddOtherComment(Newaerry); 
                                                            }}
                                                            disabled={fieldsDisabled}
                                                        />
                                                    </div>
                                                </> : ""}</>
                                    )
                                })}
                            </div>
                            <div className='modal-labels reconlable'>
                                <p>Add total</p>
                                <p style={{ fontSize: "12px" }}>{!isNaN(plusValue) ? plusValue : 0}</p>
                            </div>
                            <div className='col-md-12'>
                                <Dropdown.Divider />
                            </div>
                            <div className='row'>
                                <div className='col-md-12'>
                                    <button
                                        disabled={fieldsDisabled}
                                        type="button"
                                        className="btn btn-primary mb-2"
                                        variant="primary" onClick={() => minusOptionFunction()} >
                                        {fieldsDisabled ? "" : <i className="fa fa-minus-circle" aria-hidden="true" ></i>}{" "}
                                        Less
                                    </button>
                                </div>
                                <div className='col-md-12'>
                                    <Dropdown.Divider />
                                </div>
                                {subComment?.map((item, id) => {
                                    return (
                                        <>{((item?.reconcilationCommentOther !== undefined && item?.reconcilationCommentOther !== '') || (item.quantity !== 0 && item.quantity !== undefined)) || fieldsDisabled == false ? <>
                                            <div className='col-md-8 mb-2 reconcilation-select'>
                                                <Select
                                                    disabled={fieldsDisabled}
                                                    className='reduceFonts'
                                                    id="comment"
                                                    name="comment"
                                                    isDisabled={fieldsDisabled}
                                                    options={del_selectcomment}
                                                    onChange={value => { 
                                                        del_handleChangeoption(id, value, item);
                                                    }}
                                                    value={{ "label": item?.reconcilationCommentOther ?? "Select Comment" }}/>
                                            </div>
                                            <div className='col-md-1'>
                                                {fieldsDisabled ? "" : <i className="fa fa-trash" aria-hidden="true" type='button' onClick={() => Deleteminusconcilation(id, item?.reconcilationObservationsId)}></i>}
                                            </div>
                                            <div className='col-md-2'>
                                                <input className="form-control" id="name" name="name" type="number" value={item?.quantity}
                                                    disabled={fieldsDisabled} onWheel={e => e.currentTarget.blur()}
                                                    onChange={e => {
                                                        del_handleChangevalue(id, e);
                                                    }}
                                                />
                                            </div>
                                        </> : ""}</>
                                    )
                                }
                                )}
                                <label>Other</label>
                                {subOtherComment.map((item, id) => {
                                    return (
                                        <>{((item?.reconcilationCommentOther !== undefined && item?.reconcilationCommentOther !== '') || (item.quantity !== 0 && item.quantity !== undefined)) || fieldsDisabled == false ? <>
                                            <div className='col-md-8 mb-2'>
                                                <input className='form-control' name='other' id='other ' value={item?.reconcilationCommentOther}
                                                    disabled={fieldsDisabled}
                                                    onChange={e => {
                                                        let Newaerry = [...subOtherComment];
                                                        Newaerry[id].reconcilationCommentOther = e.target.value;
                                                        Newaerry[id].actualStockId = reconcilationitem?.actualStockId;
                                                        Newaerry[id].bookStockId = reconcilationitem?.bookStockId;
                                                        setSubOtherComment(Newaerry);
                                                    }} ></input>
                                            </div>
                                            <div className='col-md-1'>
                                                <div className='d-flex modal-delet-icon align-items-center'>
                                                    {fieldsDisabled ? "" : <i className="fa fa-plus" aria-hidden="true" type='button' onClick={() => {
                                                        if (subOtherComment[subOtherComment.length - 1].quantity !== 0 && subOtherComment[subOtherComment.length - 1].quantity !== undefined && subOtherComment[subOtherComment.length - 1].quantity !== null)
                                                        setSubOtherComment(prev => [...prev, {
                                                                actualStockId: reconcilationitem?.actualStockId,
                                                                bookStockId: reconcilationitem?.bookStockId,
                                                                reconcilationObservationsId: reconcilationitem.reconcilationObservationsId,
                                                                reconcilationCommentOther: "",
                                                                operator: "substract",
                                                                quantity: 0
                                                            }])
                                                    }}></i>}&nbsp;
                                                    {fieldsDisabled ? "" : <i className="fa fa-trash" aria-hidden="true" type='button' onClick={() => del_othercommentdelete(id, item.reconcilationObservationsId)}></i>}
                                                </div>
                                            </div>
                                            <div className='col-md-2'>
                                                <input className="form-control" id="name" name="name" type="number" value={item?.quantity}
                                                    disabled={fieldsDisabled} onWheel={e => e.currentTarget.blur()}
                                                    onChange={e => {
                                                        let Newaerry = [...subOtherComment];
                                                        Newaerry[id].quantity = e.target.value;
                                                        setSubOtherComment(Newaerry);
                                                    }}
                                                />
                                            </div>
                                        </> : ""}</>
                                    )
                                }
                                )}
                            </div>
                            <div className='modal-labels reconlable'>
                                <p>Less total</p>
                                <p style={{ fontSize: "12px" }}>{!isNaN(minusValue) ? minusValue : 0}</p>
                            </div>
                            <div className='col-md-12'>
                                <Dropdown.Divider />
                            </div>
                            <div className='modal-labels reconlable'>
                                <p>Balance</p>
                                <p>{isNaN(balance) ? 0 : balance}</p>
                            </div>
                            <div className='col-md-12'>
                                <Dropdown.Divider />
                            </div>
                            {fieldsDisabled ? "" : <div className='actual-upload-btn text-center'>
                                <label htmlFor="inputGroupFile01" className='text-dark'>Upload Photo/Document</label>
                                <input
                                    type="file"
                                    className="form-control w-50 mb-2 m-auto"
                                    id="logofile"
                                    multiple
                                    onChange={handleFileChange} />
                            </div>}
                            <div>
                                {selectedFiles?.map((file, index) => (
                                    <>
                                        <div className='row d-flex'>
                                            <div className='col-md-4'>
                                                <p style={{ fontSize: "12px", color: "black" }}>{file.name}</p>
                                            </div>
                                            <div className='col-md-6'>
                                                <input
                                                    type="text"
                                                    name={`descriptions.${index}.description`}
                                                    onChange={(e) => handleChangeInput(e, index)}
                                                    className="form-control mb-2  m-auto"
                                                />
                                            </div>
                                            <div className='col-md-2'>
                                                <span id="list" onClick={() => deleteFilesTamp(index)}>
                                                    <i className="fa fa-trash ms-1"></i>
                                                </span>
                                            </div>
                                        </div>
                                    </>
                                ))}
                            </div>
                            {uplodedData?.length > 0 && <>
                                <div>
                                    <table className='table'>
                                        <thead>
                                            <tr>
                                                <th>File Name</th>
                                                <th>Description</th>
                                                {fieldsDisabled ? "" : <th className='text-center'>Action</th>}
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {uplodedData.map((e, index) =>
                                            (<>
                                                <tr key={index}>
                                                    <td>
                                                        <a href="javascript:void(0);" onClick={() => generateFileUrl(e.uploadedFilePath)} style={{ fontSize: "12px", color: "purple", textDecoration: "none", wordWrap: "break-word", textAlign: "left"}}>{removeValueAfterHash(e.uploadedFilePath)}</a>
                                                    </td>
                                                    <td className='w-50'>
                                                        <input
                                                            type="text"
                                                            name={e?.description}
                                                            defaultValue={e?.description}
                                                            onChange={(event) => handleChangeInput1(event, index, e)}
                                                            className="form-control"
                                                            disabled={fieldsDisabled}
                                                        />
                                                    </td>
                                                    <td>
                                                        {fieldsDisabled ? "" : <span onClick={() => deleteFiles(e.uploadedFileId)}>
                                                            <i className="fa fa-trash text-center ms-5"></i>
                                                        </span>}
                                                    </td>
                                                </tr>
                                            </>)
                                            )}
                                        </tbody>
                                    </table>
                                </div></>}
                            </div>
                        }
                    </div>
                </Modal.Body>
                <Modal.Footer>
                    <div className="row d-flex">
                        <div className='col-12 text-center'>
                            {validation?.commentQuntityMessage && finalData?.length == 0 ?
                                <>
                                    <p className='error'>{validation?.commentQuntityMessage}</p>
                                </>
                                : ""}
                            {validation?.commentMessage ?
                                <>
                                    <p className='error'>{validation?.commentMessage}</p>
                                </>
                                : ""}
                            {validation?.quntityMessage ?
                                <>
                                    <p className='error'>{validation?.quntityMessage}</p>
                                </>
                                : ""}
                        </div>
                        <div className='col-12 mt-2 text-center'>
                            {fieldsDisabled && 
                                <>
                                    <CommonButton
                                        label='Edit'
                                        onClick={() => {
                                            setFieldsDisabled(false);
                                        }}
                                    />
                                    <CommonButton
                                        style={{marginLeft: 10}}
                                        loading={loading}
                                        label={'Delete'}
                                        onClick={() => {
                                            setDeleteRecoConfirmDialog(true);
                                        }}
                                    />
                                </>
                            }
                            {!fieldsDisabled &&
                                <CommonButton
                                label='Cancel'
                                onClick={() => {
                                    if(isDeleteComment){
                                        handleOnClose(true);
                                    }else{
                                        handleOnClose();
                                    }
                                    setSelectedFiles([]);
                                    setDescriptionArray([]);
                                    setAddDescriptionArray([]);
                                    setEditDescriptionArray([]);
                                    setAddComment([{
                                        reconcilationCommentId: "",
                                        reconcilationObservationsId: "",
                                        reconcilationCommentOther: "",
                                        operator: "add",
                                        quantity: 0
                                    }]);
                                    setSubComment([{ 
                                        reconcilationCommentId: "", 
                                        reconcilationObservationsId: "", 
                                        reconcilationCommentOther: "", 
                                        operator: "substract", 
                                        quantity: 0
                                    }]);
                                    setAddOtherComment([{
                                        actualStockId: reconcilationitem?.actualStockId,
                                        bookStockId: reconcilationitem?.bookStockId,
                                        reconcilationObservationsId: reconcilationitem.reconcilationObservationsId,
                                        reconcilationCommentOther: "",
                                        operator: "add",
                                        quantity: 0
                                    }]); 
                                    setSubOtherComment([{
                                        actualStockId: reconcilationitem?.actualStockId,
                                        bookStockId: reconcilationitem?.bookStockId,
                                        reconcilationObservationsId: reconcilationitem.reconcilationObservationsId,
                                        reconcilationCommentOther: "",
                                        operator: "substract",
                                        quantity: 0
                                    }]);
                                    setSubSelectOption([]);
                                    setSubValueData([]);
                                    setSubSelectedData([]);
                                    setAddSelectOption([]);
                                    setAddValueData([]);
                                    setAddSelectedData([]);
                                    setDeleteReconcilationId([]);
                                }}
                            />
                            }
                            {fieldsDisabled ? "" : 
                                <CommonButton
                                    loading={loading}
                                    label="Submit"
                                    style={{marginLeft: 10}}
                                    onClick={() => submitHandler()}
                                />
                            }
                        </div>
                    </div>
                </Modal.Footer>
            </Modal>
            {stockDetailsShow &&
                <Bookstockitem
                    bookStockDetailsShow={stockDetailsShow}
                    setBookStockDetailsShow={setStockDetailsShow}
                    details={reconcilationitem}
                    actualquantity={reconcilationitem.actualquantity}
                />
            }
            {confrimDialog &&
                <ReusableModal
                    show={confrimDialog}
                    onClose={() => setConfrimDialog(false)}
                    loading={deleteFileLoading}
                    onConfirm={deleteUplodedFiles}
                    message={"Are you sure, you want to delete this file?"}
                />
            }
            {deleteRecoConfirm &&
                <ReusableModal
                    show={deleteRecoConfirm}
                    onClose={() => setDeleteRecoConfirmDialog(false)}
                    loading={deleteRecoLoading}
                    onConfirm={deleteReconcilation}
                    message={"Are you sure, you want to delete reconciliation? Data deleted including uploaded files cannot be retrieved afterwards."}
                />
            }
        </>
    )
}

export default ReconciliationDetail;