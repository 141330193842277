import React from 'react';
import '../UploadBookStock/bookstyle.css'
import { useState } from 'react';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { ArrowLeftOutlined } from '@ant-design/icons';
import CommonButton from '../../commonComponent/button/commonButton';
import CommonTabs from '../../commonComponent/tabs/commonTabs';
import UploadBookStock from './uploadBookstock';
import UploadedFiles from './uploadedFiles';

function TabMenu() {
    const navigate = useNavigate();
    const { assignmentId } = useParams();
    const location = useLocation();
    const assignment = location?.state?.assignmentListDetails;
    const [selectedTab, setSelectedTab] = useState(1);

    // An array of objects representing the tabs
    const tabItems = [
        {
            key: '1',
            label: 'Upload Bookstock',
        },
        {
            key: '2',
            label: 'Uploaded Files',
        },
    ];

    // Handles the change of the selected tab.
    const handleTabChange = (selectedTabKey) => {
        setSelectedTab(selectedTabKey);
    }

    return (
        <>
            <div className='main-container'>
                <div className="d-flex">
                    <CommonButton 
                        style={{
                            marginRight: 10
                        }}
                        onClick={() => navigate(`/Client/ongoingassignment/${assignmentId}`, {
                            state: {
                                assignmentListDetails: assignment
                            }
                        })}
                        icon={<ArrowLeftOutlined 
                            style={{
                                verticalAlign: 'middle',
                                paddingLeft: 10,
                                paddingRight: 10,
                                marginBottom: '2px'
                            }}
                        />}
                    />
                    <CommonTabs
                        items={tabItems}
                        style={{marginLeft: 10}}
                        onChange={handleTabChange}
                    />
                </div>
               {selectedTab == 1 ? <UploadBookStock assignment={assignment}/> : <UploadedFiles />}
            </div>
        </>
    );
}

export default TabMenu;