import React, { useEffect, useState } from 'react'
import Modal from "react-bootstrap/Modal";
import moment from "moment";
import Dropdown from 'react-bootstrap/Dropdown';
import { useDispatch, useSelector } from "react-redux";
import ObservationsTableEdit from '../../../Component/ObservationsTable/observationTableEdit';
import { toast } from 'react-toastify';
import { useNavigate } from 'react-router-dom';
import * as Constants from '../../../Component/common/Global/constants';
import { logout } from '../../../redux/action';
import { EditOutlined }  from '@ant-design/icons';
import CommonButton from '../../../commonComponent/button/commonButton';

const Observationdetails = ({ observationsDetails, setObservationsDetailsShow, observationsDetailsShow, status, handleEditObservationClose }) => {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const user = useSelector((state) => state.user);
    const token = useSelector((state) => state.token);
    const id = observationsDetails?.assignmentObservationsId;
    const [observationData, setObservationData] = useState({});
    const [observationsEditModalShow, setObservationsEditModalShow] = useState(false);

    useEffect(() => {
        getObservationData();
    },[]);

    // Check if 'observationsDetails' and its 'instances' property are not undefined.
    if (observationsDetails?.instances != undefined) {
        // Parse the 'data' variable from a string to a JavaScript object.
        const data = observationsDetails?.instances;
        var tableData = JSON.parse(data);
    }

    // Fetch observation data from the API.
    const getObservationData = () => {
        if (id) {
            fetch(Constants.API_URL.getOneObservation + id, {
                method:"GET",
                mode:"cors",
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${token}`,
                }
            })
            .then(response => { return response.json(); } )
            .then(data => {
                if(data.status === Constants.status.codeAccessTokenUnauthorized){
                    localStorage.clear();
                    navigate("/Login");
                    dispatch(logout());
                }else if(data.status === Constants.status.success){
                    setObservationData(data?.result);
                }else{
                    toast.error(data?.message);
                }
            });
        }
    }

    // Close the observations edit modal
    const handleObservationEditClose  = (reload) => {
        if(reload){
            handleEditObservationClose(true)
        }
        setObservationsEditModalShow(false)
    }

    // Open the observations edit modal
    const handleEditObservations = () => {
        setObservationsEditModalShow(true)
    }

    // This function removes any text between a '-_-' and the next '.' in a filename
    function removeValueAfterHash(filename) {
        const cleanedFilename = filename?.replace(/-_-.+?\./, '.');
        return cleanedFilename;
    }

    // This function removes HTML tags from a given HTML string
    const stripHtmlTags = (html) => {
        const div = document.createElement('div');
        div.innerHTML = html;
        return div.textContent || div.innerText || '';
    };

    // Generate file URL for download
    const generateFileUrl = (fileName, type) => {
        fetch(Constants.API_URL.generateFileUrl, {
            method:"POST",
            mode:"cors",
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${token}`,
            },
            body: JSON.stringify({
                fileName:fileName,
                type:type
            })
        })
        .then(response => { return response.json(); } )
        .then(data => {
            if(data.status === Constants.status.codeAccessTokenUnauthorized){
                localStorage.clear();
                navigate("/Login");
                dispatch(logout());
            }else if(data.status === Constants.status.success){
                const link = document.createElement('a');
                link.href = data.data;
                document.body.appendChild(link);
                link.click();
                document.body.removeChild(link);
            }else{
                toast.error(`${data?.message}`);
            }
        });
    }

    return (
        <>
            <Modal size='lg' show={observationsDetailsShow}>
                <Modal.Header>
                    <Modal.Title>Observations Details</Modal.Title>
                    <div
                        onClick={() => {
                            setObservationsDetailsShow(false);
                        }}
                        data-dismiss="modal">
                        <i className="fa fa-close" aria-hidden="true"></i>
                    </div>
                </Modal.Header>
                <Modal.Body>
                    {observationsDetails?.ObservationsReviewerCycle?.currentLevel == user?.userId && status != "completed" &&
                        <div className="d-flex justify-content-end">
                            <CommonButton
                                label={'Edit'}
                                onClick={() => handleEditObservations()}
                                icon={<EditOutlined
                                    style={{
                                        verticalAlign: 'middle',
                                        marginBottom: '2px'
                                    }}
                                />}
                            />
                        </div>
                    }
                    <div className='observations_tittle'>
                        <label>Observation heading</label>
                    </div>
                    <Dropdown.Divider className='pb-0 mb-0' />
                    <div className='detailslable d-flex '>{stripHtmlTags(observationsDetails?.observationHeading)}</div>

                    <div className='observations_tittle '>
                        <label>Observation Details*</label>
                        <Dropdown.Divider className='pb-0 mb-0' />
                        <div className='detailslable d-flex mb-0' >{stripHtmlTags(observationsDetails?.observationDetails)}</div>
                    </div>

                    <div className='observations_tittle'>
                        <label>Executive Summary</label>
                        <Dropdown.Divider className='pb-0 mb-0' />
                        <div className='detailslable d-flex '>{stripHtmlTags(observationsDetails?.executiveSummary)}</div>
                    </div>

                    <div className='observations_tittle'>
                        <label>Risk/Impact</label>
                        <Dropdown.Divider className='pb-0 mb-0' />
                        <div className='detailslable d-flex '>{stripHtmlTags(observationsDetails?.riskImpact)}</div>
                    </div>
                    <div className='observations_tittle mt-3'>
                        <label>Instances</label>
                        <Dropdown.Divider className='pb-0 mb-0' />
                        {tableData && <table className="table">
                            <tbody>
                                {tableData.splice(1).map((row, index) => (
                                    row.filter(e => e != '').length == 0 ? null : 
                                    <tr key={index}>
                                        {row.map((cell, index) => (
                                            <td key={index}>{cell}</td>
                                        ))}
                                    </tr>
                                ))}
                            </tbody>
                        </table>}
                    </div>

                    <div className='observations_tittle mt-4'>
                        <label>Suggestion</label>
                        <div className="form-check">
                        </div>
                        <Dropdown.Divider className='pb-0 mb-0' />
                        <div className='detailslable d-flex '>{stripHtmlTags(observationsDetails?.suggestion)}</div></div>
                    <div className='row mb-4'>
                        <div className='col-lg-6'>
                            <label>Annexure</label>
                            <Dropdown.Divider className='pb-0 mb-0' />
                            <div className='detailslable d-flex my-1'><table style={{ wordWrap: "break-word", width: '100%' }}>
                                <thead>
                                    <tr>
                                        <th className='file_upload'><b>Uploaded File</b></th> &nbsp; &nbsp;
                                        <th className='description'><b>Description</b></th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {Object.values(observationData).length > 0 ? observationData?.uploadedFiles.map(e => {
                                        if (e?.uploadedFileType == "annexure") {
                                            return (<tr key={e?.uploadedFilePath}>
                                                <td className='file_upload'>
                                                    <a href="javascript:void(0);" className="tool" data-title={removeValueAfterHash(e?.uploadedFilePath)} onClick={() => generateFileUrl(e.uploadedFilePath,Constants.fileType.annexure)} style={{ fontSize: "12px", color: "purple", textDecoration: "none" }}>
                                                        {removeValueAfterHash(e?.uploadedFilePath).length > 20 ? `${removeValueAfterHash(e?.uploadedFilePath).substring(0, 20)}...` : removeValueAfterHash(e?.uploadedFilePath)}
                                                    </a>
                                                </td> &nbsp; &nbsp;
                                                <td className='description'>{e.description}</td>
                                            </tr>)
                                        }
                                    }): observationsDetails?.uploadedFiles.map(e => {
                                        if (e?.uploadedFileType == "annexure") {
                                            return (<tr key={e?.uploadedFilePath}>
                                                <td className='file_upload'>
                                                    <a href="javascript:void(0);" className="tool" data-title={removeValueAfterHash(e?.uploadedFilePath)} onClick={() => generateFileUrl(e.uploadedFilePath,Constants.fileType.annexure)} style={{ fontSize: "12px", color: "purple", textDecoration: "none" }}>
                                                        {removeValueAfterHash(e?.uploadedFilePath).length > 20 ? `${removeValueAfterHash(e?.uploadedFilePath).substring(0, 20)}...` : removeValueAfterHash(e?.uploadedFilePath)}
                                                    </a>
                                                </td> &nbsp; &nbsp;
                                                <td className='description'>{e.description}</td>
                                            </tr>)
                                        }
                                    })}
                                </tbody>
                            </table>
                            </div>
                        </div>

                        <div className='col-lg-6'>
                            <label>Supporting</label>
                            <Dropdown.Divider className='pb-0 mb-0' />
                            <div className='detailslable d-flex my-1'><table style={{ wordWrap: "break-word", width: '100%' }}>
                                <thead>
                                    <tr>
                                        <th className='file_upload'><b>Uploaded File</b></th> &nbsp; &nbsp;
                                        <th className='description'><b>Description</b></th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {Object.values(observationData).length > 0 ? observationData?.uploadedFiles.map(e => {
                                        if (e.uploadedFileType == "supporting") {

                                            return (<tr key={e.uploadedFilePath}>
                                                <td className='file_upload'>
                                                    <a href="javascript:void(0);" className="tool" data-title={removeValueAfterHash(e?.uploadedFilePath)} onClick={() => generateFileUrl(e.uploadedFilePath,Constants.fileType.supportings)} style={{ fontSize: "12px", color: "purple", textDecoration: "none" }}>
                                                        {removeValueAfterHash(e?.uploadedFilePath).length > 20 ? `${removeValueAfterHash(e?.uploadedFilePath).substring(0, 20)}...` : removeValueAfterHash(e?.uploadedFilePath)}
                                                    </a>
                                                </td> &nbsp; &nbsp;
                                                <td className='description'>{e.description}</td>
                                            </tr>)
                                        }
                                    }) : observationsDetails?.uploadedFiles.map(e => {
                                        if (e.uploadedFileType == "supporting") {

                                            return (<tr key={e.uploadedFilePath}>
                                                <td className='file_upload'>
                                                    <a href="javascript:void(0);" className="tool" data-title={removeValueAfterHash(e?.uploadedFilePath)} onClick={() => generateFileUrl(e.uploadedFilePath,Constants.fileType.supportings)} style={{ fontSize: "12px", color: "purple", textDecoration: "none" }}>
                                                        {removeValueAfterHash(e?.uploadedFilePath).length > 20 ? `${removeValueAfterHash(e?.uploadedFilePath).substring(0, 20)}...` : removeValueAfterHash(e?.uploadedFilePath)}
                                                    </a>
                                                </td> &nbsp; &nbsp;
                                                <td className='description'>{e.description}</td>
                                            </tr>)
                                        }
                                    })}
                                </tbody>
                            </table>
                            </div>
                        </div>
                    </div>
                </Modal.Body>
                <Modal.Footer>
                    <CommonButton
                        label={'Cancel'}
                        onClick={() => {
                            setObservationsDetailsShow(false);
                        }}
                    />
                </Modal.Footer>
            </Modal >
            {observationsEditModalShow &&
                <ObservationsTableEdit
                    setObservationsDetailsShow={setObservationsDetailsShow}
                    observationsEditModalShow={observationsEditModalShow}
                    editObservationsData={observationsDetails}
                    handleEditObservationClose={handleObservationEditClose}
                />
            }
        </>
    )
}


export default Observationdetails