import React, { useState, useEffect } from "react";
import { Button, Modal } from "react-bootstrap";
import { useFormik } from "formik";
import { APIRequest, EDIT_CLIENT_UNIT, COMMON_CREATE, COMMON_EDIT, ADD_STORE } from "../../api";
import { toast } from "react-toastify";
import * as Yup from "yup";
import Select from 'react-select';
import { useDispatch, useSelector } from 'react-redux';
import * as Constants from '../../Component/common/Global/constants';
import { logout } from '../../redux/action';
import { useNavigate } from 'react-router-dom';
import Spinner from "react-bootstrap/Spinner";
const AddStoreDialog = ({
    showStore,
    setShowStore,
    fetchClientUnit,
    store,
    clientId,
    fetchStoreList,
    storeData
}) => {
  const user = useSelector((state) => state.user);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [storeloading, setStoreLoading] = useState(false);
  const [show, setShow] = useState(false);

  const addStoreform = useFormik({
    initialValues: {
      store:storeData?.ClientUnitStoreMap?.map((item, id) => { return { 'value': item.StoreType?.storeTypeId, 'label': item.StoreType?.storeTypeName } }) ?? "",
    },
    validationSchema: Yup.object().shape({
      store: Yup.array().required("Select Store").min(1 ,"Select Store"),
    }),
    onSubmit: (values) => {
      setLoading(true);
      let finalValue = {
        clientId:storeData.clientId,
        clientUnitid:storeData.clientUnitId,
        storeId:values.store == ""?"": values?.store?.map((item, id) => { return item.value }),
        createBy:user.userId,
        updateBy:user.userId
      }

      new APIRequest.Builder()
        .post()
        .setReqId(ADD_STORE)
        .reqURL(`/clientUnit/addStoreMainLocation`)
        .jsonParams(finalValue)
        .response(onResponse)
        .error(onError)
        .build()
        .doRequest();
      },
    });
    
    const generalForm = useFormik({
      enableReinitialize: true,
      initialValues: {
        storeTypeShortName: '',
        storeTypeName: '',
        clientId: clientId,
        organisationId: user?.organisationId,
        createdBy: user.userId,
        updatedBy: user.userId
      },
      validationSchema: Yup.object().shape({
        storeTypeShortName: Yup.string().required("Enter storeTypeShortName"),
        storeTypeName: Yup.string().required("Enter Name"),
      }),
      onSubmit: values => {
        setStoreLoading(true);
        let pageInfo = {};
        pageInfo.title = 'Store';
        pageInfo.createApi = 'storeType/addStoreType';
        pageInfo.editApi = 'store/:id/update';
        pageInfo.listApi = 'store/list';
        pageInfo.deleteApi = 'store/:id/changeStatus';
        const editUrl = false ? pageInfo.editApi.replace(":id", values.id) : '';
        new APIRequest.Builder()
          .post()
          .setReqId(COMMON_CREATE)
          .jsonParams(values)
          .reqURL(true ? pageInfo.createApi : editUrl)
          .response(onResponse)
          .error(onError)
          .build()
          .doRequest();
      },
    });

    const onResponse = (response, reqId) => {
      switch (reqId) {
        case ADD_STORE:
          setLoading(false);
          setShowStore(false);
          toast.success(response?.data?.message);
          fetchClientUnit()
          addStoreform.resetForm();
          break;
        case COMMON_CREATE:
          setStoreLoading(false);
          toast.success(response?.data?.message);
          setShow(false);
          fetchStoreList();
          generalForm.resetForm();
          break;
        default:
        break;
      }
    };

  const onError = (response, reqId) => {
    switch (reqId) {
      case ADD_STORE:
        if (response.data?.status === Constants.status.codeAccessTokenUnauthorized) {
          localStorage.clear();
          navigate("/Login");
          dispatch(logout());
        } else {
          toast.error(`${response?.data?.message}`)
          setLoading(false);
        }
        break;
      case COMMON_CREATE:
        if (response.data?.status === Constants.status.codeAccessTokenUnauthorized) {
          localStorage.clear();
          navigate("/Login");
          dispatch(logout());
        } else {
          response?.data?.message && toast.error(`${response?.data?.message}`);
          setStoreLoading(false);
          // setShow(false);
        }
        break;
      default:
      break;
    }
  };

  const handleClose = () => {
    setShow(false);
    addStoreform.resetForm();
  };

  const storeoption = store?.map((e) => { return { 'value': e.storeTypeId, 'label': e.storeTypeName } });

  return (
    <Modal show={showStore}>
      <form onSubmit={addStoreform.handleSubmit}>
        <Modal.Header>
          <Modal.Title>{storeData?.ClientUnitStoreMap?.length != 0 ? "Edit Store" : "Add Store"}</Modal.Title>
          <div
            onClick={() => {
              setShowStore(false)
              addStoreform.resetForm();
            }}
            data-dismiss="modal">
            <i className="fa fa-close" aria-hidden="true"></i>
          </div>
        </Modal.Header>
        <Modal.Body className="pb-0">
          <div className="addclients_details">
            <div className="row">
              <div className="col-md-6">
                <div className="form-group ">
                  <label htmlFor="store" className="form-label">
                    Store Type{" "}
                  </label>
                  <Select
                    placeholder="Select"
                    className='reduceFonts'
                    options={storeoption}
                    defaultValue={storeData?.ClientUnitStoreMap?.map((item, id) => { return { 'value': item.StoreType?.storeTypeId, 'label': item.StoreType?.storeTypeName } })}
                    isMulti
                    onChange={e => { addStoreform.setFieldValue("store", e) }} />
                    {addStoreform.touched.store && addStoreform.errors.store ? (
                      <span className="error">{addStoreform.errors.store}</span>
                    ) : null}
                </div>
                <button className='btn btn-primary mt-2 mb-1' type="button" onClick={() => {
                  setShow(true);
                }}>Add</button>
              </div>
            </div>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={ () => setShowStore(false)}>
            Cancel
          </Button>
          <Button variant="primary" type="submit">
            {loading ? <Spinner animation="border" variant="light" className='spinner-border-sm' /> : "Submit"}
          </Button>
        </Modal.Footer>
      </form>
      <Modal show={show}>
        <form onSubmit={generalForm.handleSubmit}>
          <Modal.Header>
            <Modal.Title>Store Type</Modal.Title>
            <div
              onClick={() => {
                setShow(false);
                generalForm.resetForm();
              }}
              data-dismiss="modal">
              <i className="fa fa-close" aria-hidden="true"></i>
            </div>
          </Modal.Header>
          <Modal.Body className='pb-1'>
            <div className="">
              <label htmlFor="storeTypeShortName" className="form-label">Short Name*</label>
              <input
                className="form-control"
                id="storeTypeShortName"
                name="storeTypeShortName"
                type="text"
                onChange={generalForm.handleChange}
                value={generalForm.values.storeTypeShortName}
              />
              {generalForm.touched.storeTypeShortName && generalForm.errors.storeTypeShortName ? (
                <span className="error">{generalForm.errors.storeTypeShortName}</span>
              ) : null}
            </div>
            <div className="">
              <label htmlFor="storeTypeName" className="form-label">Name*</label>
              <input
                className="form-control"
                id="storeTypeName"
                name="storeTypeName"
                type="text"
                onChange={generalForm.handleChange}
                value={generalForm.values.storeTypeName}
              />
              {generalForm.touched.storeTypeName && generalForm.errors.storeTypeName ? (
                <span className="error">{generalForm.errors.storeTypeName}</span>
              ) : null}
            </div>
            <div className="Mandotary">
              {/* <p>* Fields Are Mandatory Reqired</p> */}
            </div>
          </Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={handleClose}>
              Cancel
            </Button>
            <Button variant="primary" type="submit">
            {storeloading ? <Spinner animation="border"  variant="light" className='spinner-border-sm' /> :  "Save"}
            </Button>
          </Modal.Footer>
        </form>
      </Modal>
    </Modal>
  );
};

export default AddStoreDialog;
