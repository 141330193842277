import React from 'react';
import { useEffect } from 'react';
import { useState } from 'react';
import { ASSIGNMENT_LIST, APIRequest, ASSIGNMENT_STATUS } from '../../../../api';
import { Table } from '../../../Table/Table';
import Updateassignment from './updateassignment';
import moment from "moment";
import Schedulednewassignment from './schedulednewassignment';
import { useDispatch, useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import Assignmentdetails from './assignmentdetails';
import { logout } from '../../../../redux/action';
import { useNavigate } from 'react-router-dom';
import ReusableModal from '../../../Modal/reusablemodel';
import RegularDropDown from '../../../../Component/common/DropdownMenus/RegularDropdown';
import * as Constants from "../../../../Component/common/Global/constants";
import { isEmptyArray, isEmptyVariable } from '../../../../Component/common/Global/commonFunctions';
import CommonLoader from '../../../../commonComponent/loader/commonLoader';

const ScheduleAssignment = () => {
    const user = useSelector((state) => state.user);
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const [detailsshow, Setdetailsshow] = useState(false);
    const [assign_details, Setassign_details] = useState();
    const [newscheduled, setnewscheduledShow] = useState(false);
    const [assignment, Setassignment] = useState({});
    const [editassignment, Seteditassignment] = useState(false);
    const [editAssignmentData, SeteditAssignmentData] = useState();
    const [assignmentActive, SetassignmentActive] = useState(true);
    const [confrimDialog, setConfrimDialog] = useState(false);
    const [Id, setId] = useState();
    const [selectedOption, setSelectedOption] = useState(Constants.placeholder.activeInactiveDefaultPlaceholder);
    const [isShowDropDownItem, showDropdownItem] = useState(false);
    const [componentDidMountScheduledFlag, isComponentDidMountScheduledFlag] = useState(false);
    const [scheduledAssignmentLoading, setScheduledAssignmentLoading] = useState(false);
    const [search, setSearch] = useState('');
    const [sort, setSort] = useState('');
    const [sortOrder, setSortOrder] = useState('');
    const [deleteAssignmentLoading, setDeleteAssignmentLoading] = useState(false);

    useEffect(() => {
        getAssignmentList();
    },[]);

    const getAssignmentList = () => {
        setScheduledAssignmentLoading(true);
        new APIRequest.Builder()
        .get()
        .setReqId(ASSIGNMENT_LIST)
        .reqURL(`assignments/getAssignmentsListByOrgId/${user.organisationId}?status=Scheduled&page=1&limit=15`)
        .response(onResponse)
        .error(onError)
        .build()
        .doRequest()
    }

    const getAssignmentActiveInactiveList = (assignmentActive = true) => {
        let url = assignmentActive ? `assignments/getAssignmentsListByOrgId/${user.organisationId}?status=Scheduled&page=1&limit=15` : `assignments/getAssignmentsListByOrgId/${user.organisationId}?isActive=false&status=Scheduled&page=1&limit=15`;
        if (search) {
            url = url + `&search=${encodeURIComponent(search)}`;
        }
        if(!isEmptyVariable(sort)){
            url = url + `&sortColumn=${sort}`
        }
        if(!isEmptyVariable(sortOrder)) {
            url = url + `&sortType=${sortOrder}`
        }
        new APIRequest.Builder()
        .get()
        .setReqId(ASSIGNMENT_LIST)
        .reqURL(url)
        .response(onResponse)
        .error(onError)
        .build()
        .doRequest()
    }

    const Editassignment = (e) => {
        Seteditassignment(true)
        SeteditAssignmentData(e);
    }

    const Startassignment = (e) => {
        new APIRequest.Builder()
        .post()
        .setReqId(ASSIGNMENT_STATUS)
        .jsonParams({
            "isActive": "true",
            "status": "Started",
        })
        .reqURL(`assignments/changeAssignmentIsActiveStatus/${e.assignmentId}`)
        .response(onResponse)
        .error(onError)
        .build()
        .doRequest();
    }

    const handleDeactivateAssignment = () => {
        setDeleteAssignmentLoading(true);
        new APIRequest.Builder()
        .post()
        .setReqId(ASSIGNMENT_STATUS)
        .jsonParams({
            "isActive": "false",
            "organisationId": user.organisationId
        })
        .reqURL(`assignments/changeAssignmentIsActiveStatus/${Id}`)
        .response(onResponse)
        .error(onError)
        .build()
        .doRequest();
    }

    const handleReactiveteAssignment = () => {
        setDeleteAssignmentLoading(true);
        new APIRequest.Builder()
        .post()
        .setReqId(ASSIGNMENT_STATUS)
        .jsonParams({
            "isActive": "true",
            "organisationId": user.organisationId
        })
        .reqURL(`assignments/changeAssignmentIsActiveStatus/${Id}`)
        .response(onResponse)
        .error(onError)
        .build()
        .doRequest();
    }

    const onResponse = (response, reqId) => {
        switch (reqId) {
            case ASSIGNMENT_LIST:
                const data = response?.data?.data ?? {};
                Setassignment({ ...data });
                isComponentDidMountScheduledFlag(true);
                setScheduledAssignmentLoading(false);
                break;
            case ASSIGNMENT_STATUS:
                setConfrimDialog(false);
                getAssignmentList();
                toast.success(`${response?.data?.message}`)
                setDeleteAssignmentLoading(false);
                break;
            default:
                break;
        }
    }

    const onError = (response, reqId) => {
        switch (reqId) {
            case ASSIGNMENT_LIST:
                if (response.data?.status === Constants.status.codeAccessTokenUnauthorized) {
                    localStorage.clear();
                    navigate("/Login");
                    dispatch(logout());
                } else {
                    setScheduledAssignmentLoading(false);
                    toast.error(`${response?.data?.message}`);
                }
                break;
            case ASSIGNMENT_STATUS:
                if (response.data?.status === Constants.status.codeAccessTokenUnauthorized) {
                    localStorage.clear();
                    navigate("/Login");
                    dispatch(logout());
                } else {
                    toast.error(`${response?.data?.message}`)
                    setDeleteAssignmentLoading(false);
                }
                break;
            default:
                break;
        }
    }

    const handleTableChange = (type, newState) => {
        let url = assignmentActive == true ? `assignments/getAssignmentsListByOrgId/${user.organisationId}?status=Scheduled&page=${type === "search" || type === "sort" ? "1" : newState.page
        }` : `assignments/getAssignmentsListByOrgId/${user.organisationId}?isActive=false&status=Scheduled&page=${type === "search" || type === "sort" ? "1" : newState.page
        }`;
        if (newState?.sizePerPage) {
            url = url + `&limit=${newState.sizePerPage}`;
        }
        if (!isEmptyVariable(newState?.searchText)) {
            setSearch(newState.searchText);
            url = url + `&search=${encodeURIComponent(newState.searchText)}`;
        }else{
            setSearch('');
        }
        if (!isEmptyVariable(newState?.sortField)) {
            setSort(newState?.sortField);
            if (newState.sortField === "name") {
                url = url + `&sortColumn=name`
            }
            if (newState.sortField === "unit") {
                url = url + `&sortColumn=unit`
            }
            if (newState.sortOrder) {
                setSortOrder(newState?.sortOrder);
                url = url + `&sortType=${newState.sortOrder}`
            }
        }else{
            setSort('');
            setSortOrder('');
        }

        new APIRequest.Builder()
        .get()
        .setReqId(ASSIGNMENT_LIST)
        .reqURL(url)
        .response(onResponse)
        .error(onError)
        .build()
        .doRequest()
    };
  
    const handleActiveInactiveChange = (selected) => {
        selected.status === "Inactive" ? SetassignmentActive(false) : SetassignmentActive(true);
        getAssignmentActiveInactiveList(selected.status === "Inactive" ? false : true);
        setSelectedOption(selected.status)
        showDropdownItem(false)
    }

    const handleEditAssignmentModal = (reload) => {
        Seteditassignment(false);
        if(reload){
            getAssignmentList();
        }
    }

    const handleNewScheduleModalOnClose = (reload) => {
        setnewscheduledShow(false);
        if(reload){
            getAssignmentList();
        }
    }

    const clearFilter = (isCompleted) => {
        SetassignmentActive(true);
        setSelectedOption(Constants.placeholder.activeInactiveDefaultPlaceholder);
        setSearch('');
        setSort('');
        setSortOrder('');
        isComponentDidMountScheduledFlag(false);
        getAssignmentList();
    }

    return (
        <div className='master_boxinfo'>
            {scheduledAssignmentLoading &&
            <CommonLoader loading={scheduledAssignmentLoading} />
            }
            <div className='table-responsive pt-2 pb-5 mt-2'>
                {componentDidMountScheduledFlag &&
                    <>
                        <div className={'deactivelist'}>
                            <RegularDropDown
                            placeholder={selectedOption}
                            dropdownArr={Constants.activeInactiveStatus}
                            labelParam="status"
                            onDropDownItemClick={handleActiveInactiveChange}
                            isFocusRequired={true}
                            show={isShowDropDownItem}
                            onToggle={(isOpen) => showDropdownItem(isOpen)}
                            defaultPlaceholderDropDown={Constants.placeholder.activeInactiveDefaultPlaceholder}
                            />
                        </div>
                        <div className='add_btns'>
                            {!isEmptyArray(assignment?.records) && (search != "" || sort != "" || selectedOption == "Inactive") && 
                                <button
                                    type="button"
                                    className="btn btn-primary"
                                    variant="primary"
                                    onClick={() => clearFilter()}
                                >
                                    <i
                                    className="fa fa-filter"
                                    aria-hidden="true"
                                    ></i>{" "}
                                    Clear
                                </button>
                            }
                            <button type='button' className='btn btn-primary w-auto' variant="primary" onClick={setnewscheduledShow}><i className="fa fa-plus-circle" aria-hidden="true"></i> Schedule New </button>
                        </div>
                        <i className="fa fa-search search_icon" aria-hidden="true"></i>
                    </>
                }
                {componentDidMountScheduledFlag && assignment?.records && (
                    <Table
                        data={assignment?.records.map((item, index) => {
                            return {
                                ...item,
                                key: index,
                                index: (assignment?.page - 1) * assignment?.limit + index + 1,
                                id: item.id,
                                name: item.Client?.clientName ?? '',
                                referenceNumber: item?.referenceNumber ?? '',
                                unit: item.ClientUnit?.clientUnitName ?? '',
                                assignmentStartDate: item.assignmentStartDate !== null ? moment(item.assignmentStartDate).format('DD-MM-YYYY') : "",
                                reviewer: item?.reviewers?.length > 0 ? item?.reviewers.map(function (item, index) { return <span key={`demo_snap_${index}`}>{(index ? ', ' : '') + item.User?.title + "" + item.User?.firstName + " " + item.User?.lastName}</span> }) : "",
                                teammember: item?.teamMembers?.length > 0 ? item?.teamMembers.map(function (item, index) { return <span key={`demo_snap_${index}`}>{(index ? ', ' : '') + item.User?.title + "" + item.User?.firstName + " " + item.User?.lastName}</span> }) : ""
                            };
                        }) ?? []}
                        columns={[
                            {
                                dataField: "index",
                                text: "No",
                                headerClasses: "sr_no",
                                style: { cursor: "pointer" },
                                events: {
                                    onClick: (e, column, columnIndex, row, rowIndex) => {
                                        Setassign_details(row); Setdetailsshow(true)
                                    },
                                }
                            },
                            {
                                dataField: "referenceNumber",
                                text: "Ref. No.",
                                sort: false,
                                style: { cursor: "pointer" },
                                events: {
                                    onClick: (e, column, columnIndex, row, rowIndex) => {
                                        Setassign_details(row); Setdetailsshow(true)
                                    },
                                }
                            },
                            {
                                dataField: "name",
                                text: "Name",
                                sort: true,
                                style: { cursor: "pointer" },
                                events: {
                                    onClick: (e, column, columnIndex, row, rowIndex) => {
                                        Setassign_details(row); Setdetailsshow(true)
                                    },
                                }
                            },
                            {
                                dataField: "unit",
                                text: "Unit",
                                sort: true,
                                style: { cursor: "pointer" },
                                events: {
                                    onClick: (e, column, columnIndex, row, rowIndex) => {
                                        Setassign_details(row); Setdetailsshow(true)
                                    },
                                }
                            },
                            {
                                dataField: "assignmentStartDate",
                                text: "Start Date",
                                sort: false,
                                style: { cursor: "pointer" },
                                events: {
                                    onClick: (e, column, columnIndex, row, rowIndex) => {
                                        Setassign_details(row); Setdetailsshow(true)
                                    },
                                }
                            },
                            {
                                dataField: "teammember",
                                text: "Team Member",
                                sort: false,
                                style: { cursor: "pointer" },
                                events: {
                                    onClick: (e, column, columnIndex, row, rowIndex) => {
                                        Setassign_details(row); Setdetailsshow(true)
                                    },
                                }
                            },
                            {
                                dataField: "reviewer",
                                text: "Reviewer",
                                sort: false,
                                style: { cursor: "pointer" },
                                events: {
                                    onClick: (e, column, columnIndex, row, rowIndex) => {
                                        Setassign_details(row); Setdetailsshow(true)
                                    },
                                }
                            },
                            {
                                dataField: "status",
                                text: "Started",
                                style: { cursor: "pointer", textAlign:"center"},
                                headerClasses: "status",
                                formatter: (cell, e) => {
                                    return(
                                        e.status == "Started" ?
                                        <h4 style={{padding:0, margin:0}}>&#10003;</h4>
                                        : ""
                                    )
                                }
                            },
                            {
                                dataField: "action",
                                text: "Action",
                                headerClasses: "action",
                                style: { cursor: "pointer" },
                                formatter: (cell, e) => {
                                    return (
                                        e?.isActive === "true" ?
                                        <div className="edit_icons">
                                            <div className="dropdown">
                                                <button
                                                    className="btn btn-secondary dropdown-toggle"
                                                    type="button"
                                                    id="dropdownMenuButton1"
                                                    data-bs-toggle="dropdown"
                                                    aria-expanded="false"
                                                >
                                                    <i
                                                    className="fa fa-ellipsis-v"
                                                    aria-hidden="true"
                                                    ></i>
                                                </button>
                                                <ul
                                                    className="dropdown-menu p-1"
                                                    aria-labelledby="dropdownMenuButton1"
                                                >
                                                    <li>
                                                        <a className="dropdown-item"
                                                            data-bs-toggle="#modal"
                                                            data-bs-target="#exampleModal3"
                                                            onClick={() => Editassignment(e)}
                                                        >
                                                            <i className="fa fa-pencil me-1" aria-hidden="true"></i>
                                                            Edit
                                                        </a>
                                                    </li>
                                                    {e.status != "Started" &&
                                                        <li>
                                                            <a className="dropdown-item"
                                                                onClick={() => {
                                                                setId(e?.assignmentId)
                                                                setConfrimDialog(true);
                                                                }}
                                                            >
                                                                <i className="fa fa-trash me-1" aria-hidden="true"></i>Delete
                                                            </a>
                                                        </li>
                                                    }
                                                    {((e.status != "Started" && (e?.reviewers?.length > 0 || e?.teammember?.length > 0) && e?.assignmentEndDate != null && e?.assignmentStartDate != "")) && (
                                                        <li>
                                                            <a
                                                                className="dropdown-item"
                                                                onClick={() => Startassignment(e)}
                                                            >
                                                                <i className="fa fa-step-forward me-1" aria-hidden="true"></i>
                                                                Start Assignment
                                                            </a>
                                                        </li>
                                                    )}
                                                </ul>
                                            </div>
                                        </div> :
                                        <div className='edit_icons'>
                                            <div className="dropdown">
                                                <button className="btn btn-secondary dropdown-toggle" type="button" id="dropdownMenuButton1" data-bs-toggle="dropdown" aria-expanded="false">
                                                    <i className="fa fa-ellipsis-v" aria-hidden="true"></i>
                                                </button>
                                                <ul className="dropdown-menu" aria-labelledby="dropdownMenuButton1">
                                                    <li>
                                                        <a className="dropdown-item" 
                                                            onClick={() => {
                                                                setId(e?.assignmentId)
                                                                setConfrimDialog(true);
                                                            }}
                                                        >
                                                        <i className="fa fa-repeat" aria-hidden="true"></i> &nbsp;Reactivate</a></li>
                                                </ul>
                                            </div>
                                        </div>
                                    );
                                },
                            },
                        ]}
                        handleTableChange={handleTableChange}
                        totalSize={assignment?.total ?? 0}
                        currentPage={assignment?.page ?? 1}
                        sizePerPage={assignment?.limit ?? 15}
                    />
                )}
                {componentDidMountScheduledFlag && isEmptyArray(assignment?.records) && 
                    <div className="no-items-layout">
                        <div className="no-items-card">
                            <h6>
                                {
                                    Constants.message.noRecordsWarning
                                }
                            </h6>
                        </div>
                    </div>
                }
                {editassignment &&
                    <Updateassignment editassignment={editassignment} handleEditAssignmentModal={handleEditAssignmentModal} editAssignmentData={editAssignmentData} user={user} />
                }
            </div>
            <ReusableModal
                show={confrimDialog}
                onClose={() => setConfrimDialog(false)}
                loading={deleteAssignmentLoading}
                onConfirm={assignmentActive ? handleDeactivateAssignment : handleReactiveteAssignment}
                message={assignmentActive ? "Are you sure, you want to delete this assignment?" : "Are you sure, you want to reactivate this assignment?"}
            />
            {newscheduled &&
                <Schedulednewassignment newscheduled={newscheduled} setnewscheduledShow={handleNewScheduleModalOnClose} />
            }
            <Assignmentdetails assign_details={assign_details} Setdetailsshow={Setdetailsshow} detailsshow={detailsshow} />
        </div>
    );
}

export default ScheduleAssignment;
