import React, { useEffect, useMemo, useRef, useState } from "react";
import { Button, Modal, Spinner, Table } from "react-bootstrap";
import RegularDropDown from "../../../Component/common/DropdownMenus/RegularDropdown";
import { APIRequest, BOOKSTOCK_MAP_FIELDS, CHECK_BOOKSTOCK_EXISTENCE, CREATE_NEW_SINGLE_BOOKSTOCK, LIST_STOCK } from "../../../api";
import * as Constants from '../../../Component/common/Global/constants';
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { logout } from "../../../redux/action";
import { useFormik } from "formik";
import { toast } from "react-toastify";
import { isEmptyVariable } from "../../../Component/common/Global/commonFunctions";
import moment from "moment";

const AddBookStock = ({handleOnClose, addBookstockDialog, assignmentId}) => {
    const [loading, setLoading] = useState(false);
    const [selectedStoreType, setSelectedStoreType] = useState("Select Store Type");
    const [isShowStoreTypeDropDownItem, showStoreTypeDropdownItem] = useState(false);
    const [selectedStockType, setSelectedStockType] = useState("Select Stock Type");
    const [isShowStockTypeDropDownItem, showStockTypeDropdownItem] = useState(false);
    const [stockData, setStockData] = useState([]);
    const [storeId, setStoreId] = useState("");
    const [stockId, setStockId] = useState("");
    const [mapField, setMapField] = useState([]);
    const [mapFieldModal, setMapFieldModal] = useState(false);
    const [selectedColumns, setSelectedColumns] = useState({});
    const [isDuplicate, setIsDuplicate] = useState("N");
    const [isLoosely, setIsLoosely] = useState("N");
    const [data, setData] = useState([]);
    const user = useSelector((state) => state?.user);
    const [validation, setValidation] = useState({});
    const [storeStockValidation, setStoreStockValidation] = useState({})
    const [isEdit, setIsEdit] = useState(false);
    const [isEditVisible, setIsEditVisible] = useState(false);
    const [oldData, setOldData] = useState({});
    const selectedColumnsRef = useRef();
    selectedColumnsRef.current = selectedColumns;
    const store = storeId;
    const stock = stockId;
    const navigate = useNavigate();
    const dispatch = useDispatch();

    useEffect(() => {
        if(addBookstockDialog){
            getAssignmentStoreStock()
        }
    },[addBookstockDialog]);

    const getAssignmentStoreStock = () => {
        new APIRequest.Builder()
            .get()
            .setReqId(LIST_STOCK)
            .reqURL(`bookstock/${assignmentId}/getFilterStoreStockList`)
            .response(onResponse)
            .error(onError)
            .build()
            .doRequest();
    }

    const onResponse = (response, reqId) => {
        switch (reqId) {
            case LIST_STOCK:
                setStockData(response.data.storeType);
                break;
            case BOOKSTOCK_MAP_FIELDS:
                setLoading(false);
                setMapField(response.data?.data);
                let columns = {};
                if (response.data?.data) {
                    response.data.data.forEach(h => {
                        columns[h.originalName] = "";
                    });
                }
                setSelectedColumns({ ...columns });
                handleOnClose(true);
                setMapFieldModal(true);
                break;
            case CHECK_BOOKSTOCK_EXISTENCE:
                setStockId("");
                setLoading(false);
                setMapFieldModal(false);
                handleOnClose(true);
                toast.success(response.data.message);
                setSelectedStoreType("Select Store Type");
                setSelectedStockType("Select Stock Type");
                setStoreId("");
                setValidation({});
                setIsEditVisible(false);
                setIsEdit(false);
                setOldData({});
                break;
            case CREATE_NEW_SINGLE_BOOKSTOCK:
                setLoading(false);
                setData([])
                setStoreId("");
                setStockId("");
                setIsDuplicate("N");
                setIsLoosely("N");
                setSelectedStoreType("Select Store Type");
                setSelectedStockType("Select Stock Type");
                setMapFieldModal(false);
                toast.success(response.data.message);
                setIsEdit(false);
                setIsEditVisible(false);
                setOldData({});
                break;
            default:
                break;
        }
    }

    const onError = (response, reqId) => {
        switch (reqId) {
        case LIST_STOCK:
            if (response.data?.status === Constants.status.codeAccessTokenUnauthorized) {
                localStorage.clear();
                navigate("/Login");
                dispatch(logout());
            } else {
            }
            break;
        case BOOKSTOCK_MAP_FIELDS:
            if (response.data?.status === Constants.status.codeAccessTokenUnauthorized) {
                localStorage.clear();
                navigate("/Login");
                dispatch(logout());
            } else {
            }
            break;
        case CHECK_BOOKSTOCK_EXISTENCE:
            if (response.data?.status === Constants.status.codeAccessTokenUnauthorized) {
                localStorage.clear();
                navigate("/Login");
                dispatch(logout());
            } else {
                setLoading(false);
                if(response?.data?.isDuplicate){
                    setIsDuplicate(response.data.isDuplicate);
                    setIsLoosely("N");
                }
                if(response?.data?.isLoosely){
                    setIsLoosely(response.data.isLoosely);
                    setIsDuplicate("N");
                }
                setOldData(selectedColumns);
                setData(response?.data?.data);
                setIsEdit(true);
                toast.error(response.data.message);
                setValidation({});
                setIsEditVisible(true);
            }
            break;
        case CREATE_NEW_SINGLE_BOOKSTOCK:
            setLoading(false);
            setMapFieldModal(false);
            setIsLoosely("N");
            setIsLoosely("N");
            setData([]);
            setIsEdit(false);
            setIsEditVisible(false);
            setOldData({});
            break;
        default:
            break;
        }
    }

    // onchange event for store
    const handleStoreChange = (selected) => {
        setStockId('');
        setSelectedStockType("Select Stock Type")
        if (selected.value !== "") {
            setStoreId(selected.value);
            setSelectedStoreType(selected.label)
        }
        showStoreTypeDropdownItem(false);
    };

    // onchange event for stock
    const handleStockChange = (e) => {
        if (e.value !== "") {
            setStockId(e.value);
            setSelectedStockType(e.label)
        }
        showStockTypeDropdownItem(false);
    };

    const addBookStock = () => {
        const selectedColumnsValue = selectedColumnsRef.current;
        let finalValue = {
            storeTypeId: storeId,
            stockTypeId: stockId,
            assignmentId: assignmentId,
            userId: user.userId,
            bookStockMapData: selectedColumnsValue
        }
        let errormessage = false;
        setLoading(true);
        let errors = {};
        if (isEmptyVariable(selectedColumnsValue.materialName)  && isEmptyVariable(selectedColumnsValue.partNo1)) {
            errormessage = true;
            errors['partNo1'] = "Part number is required";
            errors['materialName'] = "Material name is required";
        }

        if (isEmptyVariable(selectedColumnsValue.uom) && isEmptyVariable(selectedColumnsValue.quantity)) {
            errormessage = true;
            errors['Uom'] = "UOM is required";
            errors['Qty'] = "Quantity is required";
        }
        
        Object.keys(finalValue.bookStockMapData).forEach(key => finalValue.bookStockMapData[key] === undefined && delete finalValue.bookStockMapData[key])
        Object.keys(finalValue.bookStockMapData).forEach(key => finalValue.bookStockMapData[key] === "" && delete finalValue.bookStockMapData[key])
        Object.keys(finalValue.bookStockMapData).forEach(key => finalValue.bookStockMapData[key]?.length === 0  && delete finalValue.bookStockMapData[key])
        
        if (errormessage) {
            setValidation(errors);
            setLoading(false);
            setTimeout(()=>{
                setValidation({})
            },4000);
        } else {
            if(isLoosely == "Y" && oldData == finalValue.bookStockMapData){
                new APIRequest.Builder()
                .post()
                .setReqId(CREATE_NEW_SINGLE_BOOKSTOCK)
                .jsonParams(finalValue)
                .reqURL("bookstock/createNewSingleBookStock")
                .response(onResponse)
                .error(onError)
                .build()
                .doRequest();
            }else{
                new APIRequest.Builder()
                .post()
                .setReqId(CHECK_BOOKSTOCK_EXISTENCE)
                .jsonParams(finalValue)
                .reqURL("bookstock/checkBookStockExistence")
                .response(onResponse)
                .error(onError)
                .build()
                .doRequest();
            } 
        }
    }

    const stockOptions = [];
    const storeOptions = stockData?.map((item, i) => { return { "value": item?.storeTypeId, "label": item?.storeTypeName } }) ?? [];
    const filteredItems = stockData?.filter((e) => e?.storeTypeId == store);
    filteredItems?.forEach((item) => {
        item?.stockType?.forEach((stockItem) => {
            stockOptions.push({ "value": stockItem?.stockId, "label": stockItem?.stockTypeName });
        });
    });

    const handleSubmit = () => {
        let errormessage = false;
        let error = {}
        if(isEmptyVariable(storeId)){
            errormessage = true;
            error['store'] = "Select any Store"
        }

        if(isEmptyVariable(stockId)){
            errormessage = true;
            error['stock'] = "Select any Stock"
        }
        if(errormessage){
            setStoreStockValidation(error)
            setTimeout(()=>{
                setStoreStockValidation({})
            },4000);
        }else{
            new APIRequest.Builder()
                .post()
                .setReqId(BOOKSTOCK_MAP_FIELDS)
                .reqURL(`bookstock/getBookStockMapFields`)
                .jsonParams({
                    storeTypeId: storeId,
                    stockTypeId: stockId,
                    assignmentId: assignmentId
                })
                .response(onResponse)
                .error(onError)
                .build()
                .doRequest();
        }
    }

    return(
        <>
            <Modal show={addBookstockDialog}>
                <Modal.Header>
                    <Modal.Title className="m-auto h2">Add Single Bookstock</Modal.Title>
                    <div
                        onClick={() => {
                            handleOnClose();
                            setSelectedStoreType("Select Store Type");
                            setSelectedStockType("Select Stock Type");
                            setStoreId("");
                            setStockId("");
                            setLoading(false);
                        }}
                        data-dismiss="modal">
                        <i className="fa fa-close" aria-hidden="true"></i>
                    </div>
                </Modal.Header>
                <Modal.Body>
                    <div className='container'>
                        <div className='row mb-2'>
                            <div className='col-md-6 dropdown_width'>
                                <label>Store Type</label>
                                <RegularDropDown 
                                    placeholder={selectedStoreType}
                                    dropdownArr={storeOptions}
                                    labelParam="label"
                                    onDropDownItemClick={handleStoreChange}
                                    isFocusRequired={true}
                                    show={isShowStoreTypeDropDownItem}
                                    onToggle={(isOpen) => showStoreTypeDropdownItem(isOpen)}
                                    defaultPlaceholderDropDown={"Select Store Type"}
                                />
                                {!isEmptyVariable(storeStockValidation['store']) &&
                                    <span className="error">{storeStockValidation.store}</span>}
                            </div>
                            <div className='col-md-6 dropdown_width'>
                                <label>Stock Type</label>
                                <RegularDropDown 
                                    placeholder={selectedStockType}
                                    dropdownArr={stockOptions}
                                    labelParam="label"
                                    onDropDownItemClick={handleStockChange}
                                    isFocusRequired={true}
                                    show={isShowStockTypeDropDownItem}
                                    onToggle={(isOpen) => showStockTypeDropdownItem(isOpen)}
                                    defaultPlaceholderDropDown={"Select Stock Type"}
                                />
                                {!isEmptyVariable(storeStockValidation['stock']) &&
                                    <span className="error">{storeStockValidation.stock}</span>}
                            </div>
                        </div>
                    </div>
                </Modal.Body>
                <Modal.Footer>
                    <Button
                        variant="secondary"
                        onClick={() => {
                            handleOnClose();
                            setSelectedStoreType("Select Store Type");
                            setSelectedStockType("Select Stock Type");
                            setStoreId("");
                            setStockId("");
                            setLoading(false);
                            setIsEditVisible(false);
                        }}>
                        Cancel
                    </Button>
                    <Button variant="primary" type="submit" onClick={() => handleSubmit()}>
                        {loading ? <Spinner animation="border" variant="light" className='spinner-border-sm' /> : "Submit"}
                    </Button>
                </Modal.Footer>
            </Modal>
            <Modal show={mapFieldModal} size="xl">
                <Modal.Header>
                    <Modal.Title className="m-auto h2">Add Single Bookstock</Modal.Title>
                    <div
                        onClick={() => {
                            setMapFieldModal(false);
                            setSelectedStoreType("Select Store Type")
                            setSelectedStockType("Select Stock Type")
                            setStoreId("");
                            setStockId("");
                            setData([]);
                            setIsDuplicate("N");
                            setIsLoosely("N");
                            setLoading(false);
                            setIsEditVisible(false);
                            setIsEdit(false);
                            setOldData({});
                        }}
                        data-dismiss="modal">
                        <i className="fa fa-close" aria-hidden="true"></i>
                    </div>
                </Modal.Header>
                <Modal.Body>
                    <div className='container'>
                        <div className="row">
                            <div className="col-md-11">
                                <h6 className="store_stock_font_weight">Store Type: {selectedStoreType}</h6>
                                <h6 className="store_stock_font_weight">Stock Type: {selectedStockType}</h6>
                            </div>
                            {isEditVisible &&
                                <div className="col-md-1 justify-content-end">
                                    <Button
                                        variant="primary"
                                        className="d-flex-end"
                                        onClick={() => {
                                            setIsEdit(false);
                                            setData([]);
                                        }}
                                    >
                                        <i className="fa fa-pencil me-1" aria-hidden="true"></i>
                                        Edit
                                    </Button>
                                </div>
                            }
                        </div>
                        <hr/>
                        <div className='row mb-2'>
                            {mapField?.map((item, key) =>
                            <>
                                <div className="col-md-5 mb-3">
                                    <label key={key} className='d-block mx-auto'>{item.displayName}</label>
                                    <input
                                        className="form-control mx-auto"
                                        id={item.originalName}
                                        name={item.originalName}
                                        readOnly={isEdit}
                                        onKeyDown={(e) => {
                                            if(item.originalName == "lastReceiptDate" || item.originalName == "lastIssueDate"){
                                                e.preventDefault()
                                            }
                                        }} // Prevent manual typing
                                        onPaste={(e) => {
                                            if(item.originalName == "lastReceiptDate" || item.originalName == "lastIssueDate"){
                                                e.preventDefault()
                                            }
                                        }} // Prevent pasting
                                        max={new Date().toISOString().split('T')[0]}
                                        onWheel={e => e.currentTarget.blur()}
                                        type={item.originalName == "value" || item.originalName == "rate" || item.originalName == "quantity" ? "number" : item.originalName == "lastReceiptDate" || item.originalName == "lastIssueDate" ? "date" : "text"}
                                        onChange={(e) => {
                                            let data = e?.target?.value;
                                            if(item.originalName == "quantity"){
                                                selectedColumns[item.originalName] = isNaN(parseFloat(data)) ? null : parseFloat(data);
                                            }else if(item.originalName == "value" || item.originalName == "rate"){
                                                selectedColumns[item.originalName] = isNaN(parseInt(data))  ? null : parseInt(data);
                                            }else{
                                                selectedColumns[item.originalName] = data;
                                            }
                                            setSelectedColumns({ ...selectedColumns });
                                        }}
                                    />
                                    {item.originalName == "partNo1" && validation['partNo1'] ?
                                        <span className="error">{validation?.partNo1}</span> : 
                                    null}
                                    {item.originalName == "materialName" && validation['materialName']  ?
                                        <span className="error">{validation?.materialName}</span> : 
                                    null}
                                    {item.originalName == "uom"  && validation['Uom'] ?
                                        <span className="error">{validation?.Uom}</span> : 
                                    null}
                                    {item.originalName == "quantity" && validation['Qty'] ?
                                        <span className="error">{validation?.Qty}</span> : 
                                    null}
                                </div>
                                <div className="col-md-1 mb-3"></div>
                                </>
                            )}
                        </div>
                        <div className="table-responsive pt-2 pb-3">
                        {data?.length > 0 && (
                            <div className={mapField.length > 6 || data.length > 15 ? "fixed-bookstock-table-height" : ""}>
                            <Table className='customized-table-bookstock position-relative'>
                                <thead>
                                    <tr>
                                        <th className='table-header'>S.No</th>
                                        {data.filter(item => !isEmptyVariable(item.partNo1)).length > 0 && <th className='table-header'>Part No 1</th>}
                                        {data.filter(item => !isEmptyVariable(item.partNo2)).length > 0 && <th className='table-header'>Part No 2</th>}
                                        {data.filter(item => !isEmptyVariable(item.partNo3)).length > 0 && <th className='table-header'>Part No 3</th>}
                                        {data.filter(item => !isEmptyVariable(item.partNo4)).length > 0 && <th className='table-header'>Part No 4</th>}
                                        {data.filter(item => !isEmptyVariable(item.partNo5)).length > 0 && <th className='table-header'>Part No 5</th>}
                                        {data.filter(item => !isEmptyVariable(item.materialName)).length > 0 && <th className='table-header'>Material Name</th>}
                                        {data.filter(item => !isEmptyVariable(item.materialName1)).length > 0 && <th className='table-header'>Material Name 1</th>}
                                        {data.filter(item => !isEmptyVariable(item.materialName2)).length > 0 && <th className='table-header'>Material Name 2</th>}
                                        {data.filter(item => !isEmptyVariable(item.materialName3)).length > 0 && <th className='table-header'>Material Name 3</th>}
                                        {data.filter(item => !isEmptyVariable(item.materialName4)).length > 0 && <th className='table-header'>Material Name 4</th>}
                                        <th className='table-header'>Last Issue Date</th>
                                        <th className='table-header'>Last Receipt Date</th>
                                        <th className='table-header'>Quantity</th>
                                        <th className='table-header'>Rate</th>
                                        <th className='table-header'>Value</th>
                                        <th className='table-header'>UOM</th>
                                        {data.filter(item => !isEmptyVariable(item.location1)).length > 0 && <th className='table-header'>Location 1</th>}
                                        {data.filter(item => !isEmptyVariable(item.location2)).length > 0 && <th className='table-header'>Location 2</th>}
                                        {data.filter(item => !isEmptyVariable(item.location3)).length > 0 && <th className='table-header'>Location 3</th>}
                                        {data.filter(item => !isEmptyVariable(item.location4)).length > 0 && <th className='table-header'>Location 4</th>}
                                        {data.filter(item => !isEmptyVariable(item.location5)).length > 0 && <th className='table-header'>Location 5</th>}
                                    </tr>
                                </thead>
                                <tbody>
                                    {data.map((item, index) => {
                                        return(
                                            <tr>
                                                <td>{index + 1}</td>
                                                {data.filter(item => !isEmptyVariable(item.partNo1)).length > 0 ? !isEmptyVariable(item.partNo1) ? <td>{item.partNo1}</td> : <td>-</td> : "" }
                                                {data.filter(item => !isEmptyVariable(item.partNo2)).length > 0 ? !isEmptyVariable(item.partNo2) ? <td>{item.partNo2}</td> : <td>-</td> : ""}
                                                {data.filter(item => !isEmptyVariable(item.partNo3)).length > 0 ? !isEmptyVariable(item.partNo3) ? <td>{item.partNo3}</td> : <td>-</td> : ""}
                                                {data.filter(item => !isEmptyVariable(item.partNo4)).length > 0 ? !isEmptyVariable(item.partNo4) ? <td>{item.partNo4}</td> : <td>-</td> : ""}
                                                {data.filter(item => !isEmptyVariable(item.partNo4)).length > 0 ? !isEmptyVariable(item.partNo5) ? <td>{item.partNo5}</td> : <td>-</td> : ""}
                                                {data.filter(item => !isEmptyVariable(item.materialName)).length > 0 ? !isEmptyVariable(item.materialName) ? <td>{item.materialName}</td> : <td>-</td> : ""}
                                                {data.filter(item => !isEmptyVariable(item.materialName1)).length > 0 ? !isEmptyVariable(item.materialName1) ? <td>{item.materialName1}</td> : <td>-</td> : ""}
                                                {data.filter(item => !isEmptyVariable(item.materialName2)).length > 0 ? !isEmptyVariable(item.materialName2) ? <td>{item.materialName2}</td> : <td>-</td> : ""}
                                                {data.filter(item => !isEmptyVariable(item.materialName3)).length > 0 ? !isEmptyVariable(item.materialName3) ? <td>{item.materialName3}</td> : <td>-</td> : ""}
                                                {data.filter(item => !isEmptyVariable(item.materialName4)).length > 0 ? !isEmptyVariable(item.materialName4) ? <td>{item.materialName4}</td> : <td>-</td> : ""}
                                                <td>{isEmptyVariable(item.lastIssueDate) ? "-" : moment(item.lastIssueDate).utc().format('DD-MM-YYYY')}</td>
                                                <td>{isEmptyVariable(item.lastReceiptDate) ? "-" : moment(item.lastReceiptDate).utc().format('DD-MM-YYYY')}</td>
                                                <td>{isEmptyVariable(item.quantity) ? "-" : item.quantity}</td>
                                                <td>{isEmptyVariable(item.rate) ? "-" : item.rate}</td>
                                                <td>{isEmptyVariable(item.value) ? "-" : item.value}</td>
                                                <td>{isEmptyVariable(item.uom) ? "-" : item.uom}</td>
                                                {data.filter(item => !isEmptyVariable(item.location1)).length > 0 ? !isEmptyVariable(item.location1) ? <td>{item.location1}</td> : <td>-</td> : ""}
                                                {data.filter(item => !isEmptyVariable(item.location2)).length > 0 ? !isEmptyVariable(item.location2) ? <td>{item.location2}</td> : <td>-</td> : ""}
                                                {data.filter(item => !isEmptyVariable(item.location3)).length > 0 ? !isEmptyVariable(item.location3) ? <td>{item.location3}</td> : <td>-</td> : ""}
                                                {data.filter(item => !isEmptyVariable(item.location4)).length > 0 ? !isEmptyVariable(item.location4) ? <td>{item.location4}</td> : <td>-</td> : ""}
                                                {data.filter(item => !isEmptyVariable(item.location5)).length > 0 ? !isEmptyVariable(item.location5) ? <td>{item.location5}</td> : <td>-</td> : ""}
                                            </tr>
                                        )
                                    })}
                                </tbody>
                            </Table>
                            </div>
                        )}
                        </div>
                        
                    </div>
                </Modal.Body>
                <Modal.Footer>
                    <Button
                        variant="secondary"
                        onClick={() => {
                            setMapFieldModal(false);
                            setSelectedStoreType("Select Store Type")
                            setSelectedStockType("Select Stock Type")
                            setStoreId("");
                            setStockId("");
                            setData([]);
                            setIsDuplicate("N");
                            setIsLoosely("N");
                            setLoading(false);
                            setIsEditVisible(false);
                            setIsEdit(false);
                            setOldData({});
                        }}>
                        Cancel
                    </Button>
                    {(isDuplicate == "N" || isLoosely == "Y") || !isEdit ?
                    <Button variant="primary" type="submit" onClick={addBookStock}>
                        {loading ? <Spinner animation="border" variant="light" className='spinner-border-sm' /> : "Submit"}
                    </Button> : ""}
                </Modal.Footer>
            </Modal>
        </>
    )
}
export default AddBookStock;