import React, { Component } from "react";
import { Modal } from "antd";
import CommonButton from "../button/commonButton";

class CommonModal extends Component {
    constructor(props) {
        super(props);
        // Define the default style properties for the modal
        this.modalStyle = {
            
        };
    }

    // Merges the given style properties with the existing style properties of the modal.
    addStylePropertiesToModal(styleProperties) {
        Object.entries(this.modalStyle).map(([key, value]) => {
            if(!styleProperties[key]){
                styleProperties[key] = value
            }
        });
        return styleProperties;
    }

    getDropdownCustomStyle() {
        // If the modal has a prop 'style' then check if it exists
        if(this.props.style){
            return this.addStylePropertiesToModal(this.props.style);
        }else{
            return this.modalStyle;
        }
    }

    render() {
        return (
            <Modal
                open={this.props.onOpen}
                title={this.props.title ?? ""}
                onOk={this.props.handleRightButton}
                className={this.props.className ?? ""}
                style={this.getDropdownCustomStyle()}
                closable={this.props.closable ?? false}
                onCancel={this.props.onClose}
                footer={[
                    <>
                        <CommonButton
                            loading={this.props.leftButtonLoading}
                            label={this.props.leftButtonName ?? "Cancel"}
                            onClick={() => this.props.handleLeftButton()}
                        />
                        <CommonButton
                            loading={this.props.rightButtonLoading}
                            label={this.props.rightButtonName ?? "Ok"}
                            onClick={() => this.props.handleRightButton()}
                        />
                    </>
                ]}
            >
                <p style={this.props.contentStyle ?? {marginInline:17, marginTop: this.props.closable ? 25 : 0}}>{this.props.content}</p>
            </Modal>
        );
    }
}
export default CommonModal;