import React, { useState, useEffect } from 'react';
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import { useFormik } from 'formik';
import './textstyle.css';
import * as Yup from "yup";
import { Modal } from 'react-bootstrap';
import Spinner from "react-bootstrap/Spinner";
import { APIRequest, OBSERVATIONS_CREATE, ADD_ANNEXURE, UPLOAD_FILES, ACTUALSTOCK_FILE_DELETE, ACTUALSTOCK_DATA, GENERATE_FILE_URL } from '../../api'
import { toast } from 'react-toastify';
import { Instances } from '../Table/instances';
import { useParams } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { logout } from '../../redux/action';
import * as Constants from '../common/Global/constants';
import ReusableModal from '../../Pages/Modal/reusablemodel';
import { isEmptyVariable } from '../common/Global/commonFunctions';
import CommonButton from '../../commonComponent/button/commonButton';

function ObservationsTableEdit({ observationsEditModalShow, editObservationsData, setObservationsDetailsShow, handleEditObservationClose }) {
    const { assignmentId } = useParams();
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const [data, setData] = useState([])
    const [summery, Setsummery] = useState(false);
    const [suggestion, setSuggestion] = useState(false);
    const user = useSelector((state) => state?.user);
    const [fileName, setFileName] = useState();
    const [oldObservationData, setOladdObservationData] = useState()
    const [loading, setLoading] = useState(false);
    const [selectedFiles, setSelectedFiles] = useState([]);
    const [addDescriptionArray, setaddDescriptionArray] = useState([]);
    const [descriptionArray, setDescriptionArray] = useState([]);
    const [supprotingSelectedFiles, setSupprotingSelectedFiles] = useState([]);
    const [supprotingAddDescriptionArray, setSupprotingAddDescriptionArray] = useState([]);
    const [supprotingDescriptionArray, setSupprotingDescriptionArray] = useState([]);
    const [editDescriptionArray, seteditDescriptionArray] = useState([]);
    const [observationData, setObservationData] = useState([]);
    const [editObservation, isEditObservation] = useState(false);
    const [editSupprotingDescriptionArray, setSupprotingeditDescriptionArray] = useState([]);
    const id = editObservationsData?.assignmentObservationsId;
    const annexureFilesData = observationData?.uploadedFiles?.filter(e => e?.uploadedFileType == "annexure");
    const supportingFilesData = observationData?.uploadedFiles?.filter(e => e?.uploadedFileType == "supporting");
    const [fileId, setFileId] = useState(false);
    const [confrimDialog, setConfrimDialog] = useState(false);
    const [focusObj, setFocusObj] = useState({})
    const [observationHeading, setObservationHeading] = useState("");
    const [observationDetails, setObservationDetails] = useState("");
    const [executiveSummary, setExecutiveSummary] = useState("");
    const [observationSuggestion, setObservationSuggestion] = useState("");
    const [riskImpact, setRiskImpact] = useState("");
    const [deleteFileLoading, setDeleteFileLoading] = useState(false);

    let item1 = {
        fieldValue: [["", "", "", "", "", "Action"], ["", "", "", "", "", "Action"], ["", "", "", "", "", "Action"], ["", "", "", "", "", "Action"]]
    }

    const [table, SetTable] = useState(item1?.fieldValue);
    function removeValueAfterHash(filename) {
        const cleanedFilename = filename.replace(/-_-.+?\./, '.');
        return cleanedFilename;
    }

    useEffect(() => {
        getObservationData();
        if (editObservationsData?.instances) {
            const data = JSON.parse(editObservationsData.instances);
            const updatedFieldValue = data.map((arr) => {
                return [...arr, "Action"];
            });

            const item = {
                fieldValue: updatedFieldValue
            }
            SetTable(item.fieldValue)
        }
    }, [])

    const FileUpload = (e) => {
        e.preventDefault();
        for (let i = 0; i < e.target.files.length; i++) addDescriptionArray.push({ fileName: e.target.files[i].name, description: "", uploadedFileType: "annexure" });
        setSelectedFiles([...selectedFiles, ...e.target.files]);
    }
    const supportingFileUpload = (e) => {
        e.preventDefault();
        for (let i = 0; i < e.target.files.length; i++) supprotingAddDescriptionArray.push({ fileName: e.target.files[i].name, description: "", uploadedFileType: "supporting" });
        setSupprotingSelectedFiles([...supprotingSelectedFiles, ...e.target.files]);
    }
    useEffect(() => {
        if(observationData){
            const annexureFiles = observationData?.uploadedFiles?.filter(e => e.uploadedFileType == "annexure");
            const supportingFiles = observationData?.uploadedFiles?.filter(e => e.uploadedFileType == "supporting");
            if(annexureFiles){
                seteditDescriptionArray(annexureFiles.map(e => ({
                    fileName: e.uploadedFilePath,
                    description: e.description,
                    uploadedFileId: e.uploadedFileId,
                    uploadedFileType: "annexure"
                })));
            }

            if(supportingFiles){
                setSupprotingeditDescriptionArray(supportingFiles.map(e => ({
                    fileName: e.uploadedFilePath,
                    description: e.description,
                    uploadedFileId: e.uploadedFileId,
                    uploadedFileType: "supporting"
                })));
            }
        }
    }, [observationData])

    const getObservationData = () => {
        if (id) {
            new APIRequest.Builder()
                .get()
                .setReqId(ACTUALSTOCK_DATA)
                .reqURL(`assignments/getOneObservation/${id}`)
                .response(onResponse)
                .error(onError)
                .build()
                .doRequest();
        }
    }

    const generateFileUrl = (filename, type) => {
        new APIRequest.Builder()
            .post()
            .setReqId(GENERATE_FILE_URL)
            .jsonParams({
                fileName: filename,
                type: type
            })
            .reqURL("generateFileUrl")
            .response(onResponse)
            .error(onError)
            .build()
            .doRequest()
    }

    const observationsTableform = useFormik({
        enableReinitialize: true,
        initialValues: {
            assignmentId: editObservationsData?.assignmentId,
            observationHeading: !isEmptyVariable(observationHeading) ? observationHeading : editObservationsData?.observationHeading ?? "",
            observationDetails: !isEmptyVariable(observationDetails) ? observationDetails : editObservationsData?.observationDetailswihtoutremovehtml ?? "",
            executiveSummary: !isEmptyVariable(executiveSummary) ? executiveSummary : editObservationsData?.executiveSummary ?? "",
            suggestion: !isEmptyVariable(observationSuggestion) ? observationSuggestion : editObservationsData?.suggestion,
            riskImpact: !isEmptyVariable(riskImpact) ? riskImpact : editObservationsData?.riskImpact,
            instances: table,
            createdBy: editObservationsData?.createdBy, 
        },
        validationSchema: Yup.object().shape({
            observationHeading: Yup.string().required('Observation Heading Must Require'),
            observationDetails: Yup.string().required('Observation Details Must Require')
        }),
        onSubmit: values => {
            const updatedFieldValue = table.map((arr) => {
                return arr.slice(0, -1); // Remove the last element ("Action")
            });
            const files = [...selectedFiles, ...supprotingSelectedFiles];
            const filteredDescriptions1 = editDescriptionArray.filter(item => item.description !== "" || item.uploadedFileId !== "");
            const mergedArray = [...addDescriptionArray, ...filteredDescriptions1];
            const filteredDescriptions = editSupprotingDescriptionArray.filter(item => item.description !== "" || item.uploadedFileId !== "");
            const mergedArray2 = [...supprotingAddDescriptionArray, ...filteredDescriptions];
            const descriptions = [...mergedArray, ...mergedArray2];
            var observationData = new FormData();
            observationData.append('assignmentId', parseInt(assignmentId));
            observationData.append('assignmentObservationsId', editObservationsData?.assignmentObservationsId);
            observationData.append('observationHeading', values.observationHeading);
            observationData.append('observationDetails', values.observationDetails);
            observationData.append('executiveSummary', values.executiveSummary);
            observationData.append('suggestion', values.suggestion);
            observationData.append('riskImpact', values.riskImpact);
            observationData.append('updatedBy', user.userId);
            observationData.append('instances', JSON.stringify(updatedFieldValue))
            observationData.append('descriptions', JSON.stringify(descriptions));
            files.forEach((file) => {
                observationData.append('obsFiles', file);
            });
            setLoading(true);
            new APIRequest.Builder()
                .post()
                .setReqId(OBSERVATIONS_CREATE)
                .reqURL(`assignments/updateObservatoin`)
                .jsonParams(observationData)
                .response(onResponse)
                .error(onError)
                .build()
                .doRequest()
        },
    });

   

    const onResponse = (response, reqId) => {
        switch (reqId) {
            case ACTUALSTOCK_DATA:
                setObservationData(response?.data?.result);
                break;
            case OBSERVATIONS_CREATE:
                setLoading(false);
                setData(response.data.data)
                toast.success(response?.data?.message);
                handleEditObservationClose(true);
                setObservationsDetailsShow(false);
                observationsTableform.resetForm();
                break;
            case ACTUALSTOCK_FILE_DELETE:
                toast.success(response?.message);
                setConfrimDialog(false);
                getObservationData();
                isEditObservation(true);
                setDeleteFileLoading(false);
                break;
            case GENERATE_FILE_URL:
                const link = document.createElement('a');
                link.href = response.data.data;
                document.body.appendChild(link);
                link.click();
                document.body.removeChild(link);
                break;    
            default:
                break;
        }
    }

    const onError = (response, reqId) => {
        switch (reqId) {
            case ACTUALSTOCK_FILE_DELETE:
                if (response?.data?.status === Constants.status.codeAccessTokenUnauthorized) {
                    localStorage.clear();
                    navigate("/Login");
                    dispatch(logout());
                } else {
                    setLoading(false);
                    setConfrimDialog(false);
                    toast.error(response?.message);
                    setDeleteFileLoading(false);
                }
                break;
            case ACTUALSTOCK_DATA:
                if (response?.data?.status === Constants.status.codeAccessTokenUnauthorized) {
                    localStorage.clear();
                    navigate("/Login");
                    dispatch(logout());
                } else {
                    setLoading(false);
                    toast.error(response?.message);
                }
                break;
            case OBSERVATIONS_CREATE:
                if (response?.data?.status === Constants.status.codeAccessTokenUnauthorized) {
                    localStorage.clear();
                    navigate("/Login");
                    dispatch(logout());
                } else {
                    setLoading(false);
                    toast.error(response?.data?.message);
                }
                break;
            case GENERATE_FILE_URL:
                if (response.data?.status === Constants.status.codeAccessTokenUnauthorized) {
                    localStorage.clear();
                    navigate("/Login");
                    dispatch(logout());
                } else {
                    toast.error(response?.message);
                }
                break;
            default:
                break;
        }
    }
    const handleChangeInput = (e, index) => {
        const updatedArray = [...addDescriptionArray];
        updatedArray[index].description = e.target.value;
        setaddDescriptionArray([...updatedArray]);
    }

    const handleChangeInput1 = (e, index, data) => {
        const updatedArray = [...editDescriptionArray];
        updatedArray[index].description = e.target.value;
        seteditDescriptionArray([...updatedArray]);
    }
    const handleSupportingChangeInput1 = (e, index, data) => {
        const updatedArray = [...editSupprotingDescriptionArray];
        updatedArray[index].description = e.target.value;
        setSupprotingeditDescriptionArray([...updatedArray]);
    }

    const handleChangeSupportingInput = (e, index) => {
        const updatedArray = [...supprotingAddDescriptionArray];
        updatedArray[index].description = e.target.value;
        setSupprotingAddDescriptionArray([...updatedArray]);
    }

    const deleteFilesTamp = (i) => {
        const row = [...selectedFiles];
        row.splice(i, 1);
        const darray = [...addDescriptionArray];
        darray.splice(i, 1);
        setaddDescriptionArray(darray);
        setSelectedFiles(row);
    }
    const deleteSupportingFilesTamp = (i) => {
        const row = [...supprotingSelectedFiles];
        row.splice(i, 1);
        const darray = [...supprotingDescriptionArray];
        darray.splice(i, 1);
        setSupprotingDescriptionArray(darray);
        setSupprotingSelectedFiles(row);
    }
    const deleteFiles = (uplodedfileId) => {
        setFileId(uplodedfileId);
        setConfrimDialog(true);
    }
    const deleteUplodedFiles=() =>{
        setDeleteFileLoading(true);
        new APIRequest.Builder()
        .delete()
        .setReqId(ACTUALSTOCK_FILE_DELETE)
        .reqURL(`bookstock/uploadedFiles/${fileId}`)
        .response(onResponse)
        .error(onError)
        .build()
        .doRequest();
    }
    const modules = {
		toolbar: [
			[{ header: [1, 2, 3, 4, false] }],
			["bold", "italic", "underline", "strike"],
			[{ list: "ordered" }, { list: "bullet" }, { 'indent': '-1'}, { 'indent': '+1' }, { 'align': [] }],
            ["blockquote", { 'font': [] }],
			["clean"],
		],
	};

    const handleFocus = (focusedFieldName) => {
		if (focusedFieldName ==  "") {
			return {
				observationHeading_isFocused: false,
				detailedObservation_isFocused: false,
				executiveSummary_isFocused: false,
				riskImpact_isFocused: false,
				suggestion_isFocused: false,
			};
		} else {
			return {
				observationHeading_isFocused: false,
				detailedObservation_isFocused: false,
				executiveSummary_isFocused: false,
				riskImpact_isFocused: false,
				suggestion_isFocused: false,

				[focusedFieldName]: true,
			};
		}
	};

    const onObsHeadingFocus = () => {
        let focusObj = handleFocus("observationHeading_isFocused");
        setFocusObj({...focusObj});
    };

    const onDetailedObsFocus = () => {
		let focusObj = handleFocus("detailedObservation_isFocused");
		setFocusObj({...focusObj});
	};

    const onExecutiveSummaryFocus = () => {
		let focusObj = handleFocus("executiveSummary_isFocused");
		setFocusObj({...focusObj});
	};

    const onRiskImpactFocus = () => {
        let focusObj = handleFocus("riskImpact_isFocused");
		setFocusObj({...focusObj});
    };

    const onSuggestionFocus = () => {
		let focusObj = handleFocus("suggestion_isFocused");
        setFocusObj({...focusObj});
	};

    return (
        <>
            <Modal size='lg' show={observationsEditModalShow} className="scrollable-modal">
                <Modal.Header>
                    <Modal.Title>Observations Edit</Modal.Title>
                    <div 
                        onClick={() => {
                            if(editObservation){
                                getObservationData();
                            }
                            handleEditObservationClose();
                            observationsTableform.resetForm();
                            setaddDescriptionArray([]);
                            setDescriptionArray([]);
                            setSelectedFiles([]);
                            setSupprotingSelectedFiles([]);
                            setSupprotingAddDescriptionArray([]);
                            setSupprotingDescriptionArray([]);
                            seteditDescriptionArray([]);
                            setSupprotingeditDescriptionArray([]);
                            SetTable(item1.fieldValue);
                        }}
                        data-dismiss="modal">
                        <i className="fa fa-close" aria-hidden="true"></i>
                    </div>
                </Modal.Header>
                <Modal.Body className="modal-body">
                    <form onSubmit={observationsTableform.handleSubmit}>
                        <div className='observations_tittle'>
                            <label>Observation heading*</label>
                        </div>
                        <div>
                            <ReactQuill
                                className={focusObj.observationHeading_isFocused?"two-lines-focus":"two-lines"}
								value={observationsTableform.values.observationHeading} 
								onChange={(event) => {
                                    observationsTableform.setFieldValue('observationHeading', event)
                                    setObservationHeading(event)
                                }}
                                onFocus={onObsHeadingFocus}
								modules={modules}
							/>
                        </div>
                        {observationsTableform.touched.observationHeading && observationsTableform.errors.observationHeading ? (
                            <span className="error">{observationsTableform.errors.observationHeading}</span>
                        ) : null}
                        <div className='observations_tittle'>
                            <label>Observation Details*</label>
                        </div>
                        <div>
                            <ReactQuill
                                className={focusObj.detailedObservation_isFocused?"two-lines-focus":"two-lines"}
								value={observationsTableform.values.observationDetails} 
								onChange={(event) => {
                                    observationsTableform.setFieldValue('observationDetails', event)
                                    setObservationDetails(event)
                                }}
                                onFocus={onDetailedObsFocus}
								modules={modules}
							/>
                        </div>
                        {observationsTableform.touched.observationDetails && observationsTableform.errors.observationDetails ? (
                            <span className="error">{observationsTableform.errors.observationDetails}</span>
                        ) : null}
                        <div className='observations_tittle'>
                            <label>Executive Summary</label>
                            <div className="form-check">
                                <input className="form-check-input" type="checkbox" value="" id="flexCheckDefault2"
                                    onClick={(e) => { if (e.target.checked) { Setsummery(true) } else { Setsummery(false) }; }} />
                                <label className="form-check-label" htmlFor="flexCheckDefault2">
                                    Not Required
                                </label>
                            </div>
                        </div>

                        <div className='mt-1'>
                            <ReactQuill
                                className={focusObj.executiveSummary_isFocused?"two-lines-focus":"two-lines"}
                                readOnly={summery}
								value={observationsTableform.values.executiveSummary}
                                name="executiveSummary"
								onChange={(event) => {
                                    observationsTableform.setFieldValue('executiveSummary', event)
                                    setExecutiveSummary(event)
                                }}
                                onFocus={onExecutiveSummaryFocus}
								modules={modules}
							/>
                        </div>
                        <div className='observations_tittle'>
                            <label>Risk/Impact</label>
                        </div>
                        <div className='mt-1'>
                            <ReactQuill
                                className={focusObj.riskImpact_isFocused?"two-lines-focus":"two-lines"}
								value={observationsTableform.values.riskImpact} 
								onChange={(event) => {
                                    observationsTableform.setFieldValue('riskImpact', event)
                                    setRiskImpact(event)
                                }}
                                onFocus={onRiskImpactFocus}
								modules={modules}
							/>
                        </div>
                        <Instances table={table} SetTable={SetTable} />

                        <div className='observations_tittle'>
                            <label>Suggestion</label>
                            <div className="form-check">
                                <input className="form-check-input" type="checkbox" value="" id="flexCheckDefault2"
                                    onClick={(e) => { if (e.target.checked) { setSuggestion(true) } else { setSuggestion(false) }; }} />
                                <label className="form-check-label" htmlFor="flexCheckDefault2">
                                    Not Required
                                </label>
                            </div>
                        </div>
                        <div className='mt-1'>
                            <ReactQuill
                                className={focusObj.suggestion_isFocused?"two-lines-focus":"two-lines"}
                                readOnly={suggestion}
								value={observationsTableform.values.suggestion}
                                name="suggestion"
								onChange={(event) => {
                                    observationsTableform.setFieldValue('suggestion', event)
                                    setObservationSuggestion(event)
                                }}
                                onFocus={onSuggestionFocus}
								modules={modules}
							/>
                        </div>
                        <div className='row'>
                            <div className='col-md-6'>
                                <label>Annexure</label>
                                <input type="file"
                                    name="annexure"
                                    className='form-control mb-2 border'
                                    id="annexure"
                                    multiple
                                    onChange={FileUpload} 
                                />
                                {selectedFiles?.map((file, index) => (
                                    <>
                                        <div className='row d-flex'>
                                            <div className='col-md-4'>
                                                <p style={{ fontSize: "12px", color: "black" }}>{file.name}</p>

                                            </div>
                                            <div className='col-md-6'>
                                                <input
                                                    type="text"
                                                    name={`descriptions.${index}.description`}
                                                    onChange={(e) => handleChangeInput(e, index)}
                                                    className="form-control mb-2  m-auto"
                                                />
                                            </div>
                                            <div className='col-md-2'>
                                                <span id="list" onClick={() => deleteFilesTamp(index)}>
                                                    <i className="fa fa-trash ms-1"></i>
                                                </span>
                                            </div>
                                        </div>
                                    </>
                                ))}
                               {annexureFilesData?.length > 0 && <div>
                                    <table className='table'>
                                        <thead>
                                            <tr>
                                                <th>File Name</th>
                                                <th>Description</th>
                                                <th>Action</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {annexureFilesData?.map((e, index) => {

                                                if (e?.uploadedFileType == "annexure") {
                                                    return (<>
                                                        <tr key={index}>
                                                            <td>
                                                                <a href="javascript:void(0);" onClick={() => generateFileUrl(e.uploadedFilePath,Constants.fileType.annexure)} style={{ fontSize: "12px", color: "purple", textDecoration: "none", wordWrap: "break-word", textAlign: "left"}}>{removeValueAfterHash(e.uploadedFilePath)}</a>
                                                            </td>
                                                            <td>
                                                                <input
                                                                    type="text"
                                                                    name={e?.description}
                                                                    defaultValue={e?.description}
                                                                    onChange={(event) => handleChangeInput1(event, index, e)}
                                                                    className="form-control"
                                                                />
                                                            </td>
                                                            <td>
                                                                <span onClick={() => deleteFiles(e?.uploadedFileId)}>
                                                                    <i className="fa fa-trash ms-1"></i>
                                                                </span>
                                                            </td>
                                                        </tr>

                                                    </>)
                                                }
                                            }
                                            )}
                                        </tbody>
                                    </table>
                                </div>}
                            </div>
                            <div className='col-md-6'>
                                <label>Supporting</label>
                                <input type="file"
                                    className="form-control mb-2 border"
                                    id="supporting"
                                    name="supporting"
                                    multiple
                                    onChange={supportingFileUpload}
                                />
                            
                                {supprotingSelectedFiles?.map((file, index) => (
                                    <>
                                        <div className='row d-flex'>
                                            <div className='col-md-4'>
                                                <p style={{ fontSize: "12px", color: "black" }}>{file.name}</p>

                                            </div>
                                            <div className='col-md-6'>
                                                <input
                                                    type="text"
                                                    name={`descriptions.${index}.description`}
                                                    onChange={(e) => handleChangeSupportingInput(e, index)}
                                                    className="form-control mb-2  m-auto"
                                                />
                                            </div>
                                            <div className='col-md-2'>
                                                <span id="list" onClick={() => deleteSupportingFilesTamp(index)}>
                                                    <i className="fa fa-trash ms-1"></i>
                                                </span>
                                            </div>
                                        </div>
                                    </>
                                ))}

                                {supportingFilesData?.length > 0 &&  <div>
                                    <table className='table'>
                                        <thead>
                                            <tr>
                                                <th>File Name</th>
                                                <th>Description</th>
                                                <th>Action</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {
                                                supportingFilesData?.map((e, index) => {
                                                    if (e.uploadedFileType == "supporting") {
                                                        return (<>
                                                            <tr key={index}>
                                                                <td>
                                                                    <a href="javascript:void(0);" onClick={() => generateFileUrl(e.uploadedFilePath,Constants.fileType.supportings)} style={{ fontSize: "12px", color: "purple", textDecoration: "none", wordWrap: "break-word", textAlign: "left"}}>{removeValueAfterHash(e.uploadedFilePath)}</a>
                                                                </td>
                                                                <td>
                                                                    <input
                                                                        type="text"
                                                                        name={e.description}
                                                                        defaultValue={e.description}
                                                                        onChange={(event) => handleSupportingChangeInput1(event, index, e)}
                                                                        className="form-control"
                                                                    />
                                                                </td>
                                                                <td>
                                                                    <span onClick={() => deleteFiles(e.uploadedFileId)}>
                                                                        <i className="fa fa-trash ms-1"></i>
                                                                    </span>
                                                                </td>
                                                            </tr>
                                                        </>)
                                                    }
                                                })
                                            }
                                        </tbody>
                                    </table>
                                </div>}
                            </div>
                        </div>
                    </form>
                </Modal.Body>
                <Modal.Footer>
                    <CommonButton
                        style={{marginRight: 10}}
                        label={'Save'}
                        loading={loading}
                        onClick={observationsTableform.handleSubmit}
                    />
                    <CommonButton
                        label={'Cancel'}
                        isLoading={loading}
                        onClick={() => {
                            if(editObservation){
                                getObservationData();
                            }
                            handleEditObservationClose();
                            observationsTableform.resetForm();
                            setaddDescriptionArray([]);
                            setDescriptionArray([]);
                            setSelectedFiles([]);
                            setSupprotingSelectedFiles([]);
                            setSupprotingAddDescriptionArray([]);
                            setSupprotingDescriptionArray([]);
                            seteditDescriptionArray([]);
                            setSupprotingeditDescriptionArray([]);
                            SetTable(item1.fieldValue);
                        }}
                    />
                </Modal.Footer>
            </Modal>

            <ReusableModal
                show={confrimDialog}
                onClose={() => setConfrimDialog(false)}
                loading={deleteFileLoading}
                onConfirm={deleteUplodedFiles}
                message={"Are you sure, you want to delete this file?"}
            />
        </>
    );
}

export default ObservationsTableEdit;