import React from 'react';
import '../UploadBookStock/bookstyle.css'
import { useState } from 'react';
import {
    APIRequest, BOOKSTOCK_FILE_HISTORY, DELETE_UPLOAD_BOOKSTOCK
} from '../../api'
import { toast } from 'react-toastify';
import { useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import moment from "moment";
import { useDispatch, useSelector } from 'react-redux';
import { logout } from '../../redux/action';
import ReusableModal from '../Modal/reusablemodel';
import * as Constants from "../../Component/common/Global/constants";
import { randomStringGenerator } from '../../Component/common/Global/commonFunctions';
import { DeleteOutlined } from '@ant-design/icons';
import CommonButton from '../../commonComponent/button/commonButton';
import CommonTable from '../../commonComponent/table/commonTable';
import { Badge } from 'antd';
import CommonLoader from '../../commonComponent/loader/commonLoader';

function UploadedFiles() {
   const dispatch = useDispatch();
    const navigate = useNavigate();
    const user = useSelector((state) => state.user);
    const { assignmentId } = useParams();
    const [bookstockFileHistory, setBookstockFileHistory] = useState({});
    const [messageDialog, setMessageDialog] = useState(false);
    const [confrimDialog, setConfrimDialog] = useState(false);
    const [deleteFileName, setDeleteFileName] = useState(false);
    const [modalButtonLoading, isModalButtonLoading] = useState(false);
    const [currentPage, setCurrentPage] = useState(1);
    const [total, setTotal] = useState(0);
    const [bookstockFileHistoryDataLoading, setBookstockFileHistoryDataLoading] = useState(false);
    const [componentDidMountFlag, isComponentDidMountFlag] = useState(false);

    // Fetch the book stock file history when the component mounts
    useEffect(() => {
        getBookStockFileHistory(currentPage);
    }, []);

    // getBookStockFileHistory function is used to fetch the book stock file history from the server.
    const getBookStockFileHistory = (currentPage) => {
        setBookstockFileHistoryDataLoading(true);
        new APIRequest.Builder()
            .post()
            .setReqId(BOOKSTOCK_FILE_HISTORY)
            .reqURL(`bookstock/getBookStockFileHistory?page=${currentPage}&limit=${Constants.pageSize}`)
            .jsonParams({ assignmentId: JSON.parse(assignmentId), userId: user.userId})
            .response(onResponse)
            .error(onError)
            .build()
            .doRequest();
    }

    const onResponse = (response, reqId) => {
        switch (reqId) {
            case BOOKSTOCK_FILE_HISTORY:
                setTotal(response.data?.data?.total)
                setBookstockFileHistory(response.data?.data);
                setBookstockFileHistoryDataLoading(false);
                isComponentDidMountFlag(true);
                break;
            case DELETE_UPLOAD_BOOKSTOCK:
                toast.success(response?.data?.data);
                getBookStockFileHistory(currentPage);
                setConfrimDialog(false);
                isModalButtonLoading(false);
                break;
            default:
                break;
        }
    };

    const onError = (response, reqId) => {
        switch (reqId) {
            case DELETE_UPLOAD_BOOKSTOCK:
                if (response.data?.status === Constants.status.codeAccessTokenUnauthorized) {
                    localStorage.clear();
                    navigate("/Login");
                    dispatch(logout());
                } else {
                    setConfrimDialog(false);
                    isModalButtonLoading(false);
                }
                break;
            case BOOKSTOCK_FILE_HISTORY:
                if (response.data?.status === Constants.status.codeAccessTokenUnauthorized) {
                    localStorage.clear();
                    navigate("/Login");
                    dispatch(logout());
                } else if(response.data?.errorStatus === Constants.status.codeNotAccess){
                    setBookstockFileHistoryDataLoading(false);
                    navigate(`/Client/ongoingassignment/${assignmentId}/bookstock/${randomStringGenerator(30)}`);
                }else{
                    isComponentDidMountFlag(true);
                    setBookstockFileHistoryDataLoading(false);
                }
                break;
            default:
                break;
        }
    };

    // Deletes the uploaded bookstock file from the list.
    const deleteUploadBookstockFile = (fileName) => {
        setDeleteFileName(fileName);
        setMessageDialog(true);
    }

    // Handles the click event for deleting the uploaded bookstock file.
    const handleDeleteBookStockByUploadedFile = () => {
        // Show the loading indicator for the modal button.
        isModalButtonLoading(true);
        new APIRequest.Builder()
            .post()
            .setReqId(DELETE_UPLOAD_BOOKSTOCK)
            .jsonParams({ "fileName": deleteFileName })
            .reqURL("bookstock/deleteBookStockByUploadedFile")
            .response(onResponse)
            .error(onError)
            .build()
            .doRequest();
    }

    // Removes any value after the hash symbol in a filename.
    function removeValueAfterHash(filename) {
        const updatedFilename = filename.replace(/-_-.+?\./, '.');
        return updatedFilename;
    }

    // Handles the pagination change event for the book stock file history table.
    const handlePaginationChange = (currentPage, pageSize) => {
        setCurrentPage(currentPage);
        getBookStockFileHistory(currentPage);
    };

    // Renders the action button for a given book stock record.
    const handleAction = (record) => {
        if(Object.keys(record.queueProcessData).length == 0 || record?.queueProcessData?.status == 'verified'){
            return(
                record.isActive == "true" ?
                <CommonButton
                    style={{
                        marginRight: 10,
                        backgroundColor: 'transparent',
                        boxShadow: '0 0 0 rgba(0, 0, 0, 0.045)'
                    }}
                    onClick={() => deleteUploadBookstockFile(record?.fileName)}
                    icon={<DeleteOutlined
                        style={{
                            verticalAlign: 'middle',
                            color: 'rgba(0, 0, 0, 0.85)',
                            paddingLeft: 10,
                            paddingRight: 10
                        }}
                    />}
                />
                : 'Deleted'
            )
        }else if(record.queueProcessData.status == 'inProcess'){
            return null;
        }else if(record.queueProcessData.status == 'failed'){
            return (
                <CommonButton 
                    style={{
                        marginRight: 10,
                        backgroundColor: 'transparent',
                        boxShadow: '0 0 0 rgba(0, 0, 0, 0.045)'
                    }}
                    onClick={() => deleteUploadBookstockFile(record?.fileName)}
                    icon={<DeleteOutlined
                        style={{
                            verticalAlign: 'middle',
                            color: 'rgba(0, 0, 0, 0.85)',
                            paddingLeft: 10,
                            paddingRight: 10
                        }}
                    />}
                />
            );
        }
    }

    // An array of column objects for the upload file table.
    const uploadFileColumn = [
        {
            dataIndex: 'index',
            title: 'S.No',
            key: 'sr_no',
            width: '8%'
        },
        {
            dataIndex: 'uploadedFileName',
            title: 'File Name',
            key: 'fileName',
        },
        {
            dataIndex: 'createdAt',
            title: 'Date & Time',
            key: 'createdAt',
        },
        {
            dataIndex: 'storeTypeName',
            title: 'Store',
            key: 'storeTypeName',
        },
        {
            dataIndex: 'name',
            title: 'Uploaded By',
            key: 'name',
        },
        {
            title: 'Status',
            key: 'status',
            render: (record) => {
                return <Badge status={handleQueueProcessBadgeStatus(record.queueProcessData)} text={handleQueueProcessStatus(record.queueProcessData)} />
            }
        },
        {
            title: 'Action',
            key: 'isActive',
            width: '8%',
            render: (record) => {
                return handleAction(record);
            }
        }
    ];

    // Returns the badge status based on the queue process data.
    const handleQueueProcessBadgeStatus = (queueProcessData) => {
        if(Object.keys(queueProcessData).length == 0){
            return '-';
        }else if(queueProcessData.status == 'inQueue' || queueProcessData.status == 'inProcess'){
            return Constants.badgeStatus.blue;
        }else if(queueProcessData.status == 'error' || queueProcessData.status == 'failed'){
            return  Constants.badgeStatus.red;
        }else if(queueProcessData.status == 'completed'){
            return Constants.badgeStatus.lime;
        }else if(queueProcessData.status == 'verified'){
            return Constants.badgeStatus.green
        }
    }

    // Returns the status string based on the queue process data.
    const handleQueueProcessStatus = (queueProcessData) => {
        if(Object.keys(queueProcessData).length == 0){
            return '-';
        }else if(queueProcessData.status == 'inQueue' || queueProcessData.status == 'inProcess' || queueProcessData.status == 'error'){
            return Constants.status.inProcess;
        }else if(queueProcessData.status == 'failed'){
            return Constants.status.failed;
        }else if(queueProcessData.status == 'completed'){
            return Constants.status.completed;
        }else if(queueProcessData.status == 'verified'){
            return Constants.status.verified;
        }
    }

    return (
        <>
            {componentDidMountFlag &&
                <CommonTable
                    columns={uploadFileColumn}
                    dataSource={bookstockFileHistory?.records?.map((item, index) => {
                        return {
                            ...item,
                            key: (index + 1),
                            index: (bookstockFileHistory?.page - 1) * bookstockFileHistory.limit + index + 1,
                            uploadedFileName: removeValueAfterHash(item?.fileName),
                            createdAt: moment(item?.createdAt).utc().format('DD-MM-YYYY'),
                            storeTypeName: item?.StoreType?.storeTypeName,
                            name: item?.User?.title + "" + item?.User?.firstName + " " + item?.User?.lastName, 
                        };
                    })}
                    loading={bookstockFileHistoryDataLoading}
                    pagination={{
                        total: total,
                        pageSize: Constants.pageSize,
                        onChange: handlePaginationChange,
                    }}
                />
            }
            {bookstockFileHistoryDataLoading && !componentDidMountFlag &&
                <CommonLoader loading={bookstockFileHistoryDataLoading} />
            }
            {messageDialog &&
                <ReusableModal
                    show={messageDialog}
                    buttonOk={"Ok"}
                    onClose={() => setMessageDialog(false)}
                    loading={modalButtonLoading}
                    onConfirm={() => {
                        isModalButtonLoading(false);
                        setMessageDialog(false)
                        setConfrimDialog(true)
                    }}
                    message={"All the stock uploaded through this file will be deleted. You will not be able to retrieve any data belonging to this file."}
                />
            }
            {confrimDialog &&
                <ReusableModal
                    show={confrimDialog}
                    buttonOk={"Submit"}
                    onClose={() => setConfrimDialog(false)}
                    loading={modalButtonLoading}
                    onConfirm={handleDeleteBookStockByUploadedFile}
                    message={"Are you sure, you want to delete records related to the file?"}
                />
            }
        </>

    );
}

export default UploadedFiles;