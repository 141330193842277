import React, { useState, useEffect, useRef } from 'react'
import '../../../../OngoingAssignment/assignmentstyle.css';
import { toast } from 'react-toastify';
import { useNavigate, useParams } from 'react-router-dom';
import * as Constants from '../../../../../Component/common/Global/constants';
import { logout } from '../../../../../redux/action';
import { useDispatch, useSelector } from 'react-redux';
import Bookstockitem from '../../../../OngoingAssignment/Detailspage/bookstockitem';
import { isEmptyArray, isEmptyVariable } from '../../../../../Component/common/Global/commonFunctions';
import { Divider, Modal } from 'antd';
import CommonButton from '../../../../../commonComponent/button/commonButton';
import CommonTable from '../../../../../commonComponent/table/commonTable';
import {CheckOutlined, InfoCircleOutlined} from '@ant-design/icons';

const ActualStockModal = ({ showActualStockModal, handleActualStockModalClose, bookStockDetails, clientName }) => {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const user = useSelector((state) => state?.user);
    const token = useSelector((state) => state.token);
    const { assignmentId } = useParams();
    const [actualStocksData, setActualStocksData] = useState({});
    const [bookStockDetailsShow, setBookStockDetailsShow] = useState(false);
    const [uploadedFileDescriptionArray, setUploadedFileDescriptionArray] = useState([]);

    // Fetch actual stock data when the modal is opened
    useEffect(() => {
        if (showActualStockModal){ 
            handleClearData();
            if(!isEmptyVariable(bookStockDetails?.actualStockId)){
                getCompletedSingleActualData();
            }
        }
    }, [showActualStockModal]);

    // Fetch actual stock data from the API
    const getCompletedSingleActualData = () => {
        fetch(Constants.API_URL.getCompletedSingleActualData, {
            method:"POST",
            mode:"cors",
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${token}`,
            },
            body: JSON.stringify({
                actualStockId: bookStockDetails?.actualStockId,
                assignmentId: parseInt(assignmentId),
                userId: user.userId,
            })
        })
        .then(response => { return response.json(); } )
        .then(data => {
            if(data.status === Constants.status.codeAccessTokenUnauthorized){
                localStorage.clear();
                navigate("/Login");
                dispatch(logout());
            }else if(data.status === Constants.status.success){
                let actualStockData = data?.data;
                setActualStocksData(actualStockData)
                const updatedArray = actualStockData.uploadedFiles.map((item, index) => {
                    return { 
                        description: item.description,
                        uploadedFileId: item?.uploadedFileId ? item.uploadedFileId : '-',
                    };
                })
                setUploadedFileDescriptionArray(updatedArray);
            }else{
                toast.error(`${data?.message}`);
            }
        });
    }

    // Generate file URL for download
    const generateFileUrl = (fileName) => {
        fetch(Constants.API_URL.generateFileUrl, {
            method:"POST",
            mode:"cors",
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${token}`,
            },
            body: JSON.stringify({
                fileName:fileName,
                type:Constants.fileType.actuals
            })
        })
        .then(response => { return response.json(); } )
        .then(data => {
            if(data.status === Constants.status.codeAccessTokenUnauthorized){
                localStorage.clear();
                navigate("/Login");
                dispatch(logout());
            }else if(data.status === Constants.status.success){
                const link = document.createElement('a');
                link.href = data.data;
                document.body.appendChild(link);
                link.click();
                document.body.removeChild(link);
            }else{
                toast.error(`${data?.message}`);
            }
        });
    }
    
    // Remove unwanted characters from the file name
    function removeValueAfterHash(fileName) {
        const cleanedFilename = fileName?.replace(/-_-.+?\./, '.');
        return cleanedFilename;
    }

    // Clear all input fields
    const handleClearData = () => {
        setActualStocksData({});
        setUploadedFileDescriptionArray([]);
    }

    // Handle cancel button click
    const handleCancel = () => {
        handleClearData();
        handleActualStockModalClose();
    }

    const clientDataSource = [
        {
            key: '1',
            title: 'Client',
            value: clientName
        },
        {
            key: '2',
            title: 'Part Number',
            value: !isEmptyVariable(bookStockDetails?.partNo1) ? 
            bookStockDetails?.partNo1 + "  " : " - "
        },
        {
            key: '3',
            title: 'Material',
            value: bookStockDetails?.materialName,
            isLast: true
        }
    ];

    const bookQtyDataSource = [
        {
            key: '1',
            title: 'Book Qty',
            value: bookStockDetails?.quantity,
            bold: true
        },
        {
            key: '2',
            title: 'Sample',
            value: actualStocksData?.records?.isSample === 1 ?
            <CheckOutlined style={{color: '#552b84'}}/>: '-'
        }
    ];

    const actualQtyDataSource = [
        {
            key: '1',
            title: 'Actual Qty',
            value: !isEmptyVariable(actualStocksData?.records?.actualStockQuantity) ? actualStocksData?.records?.actualStockQuantity : '-',
            bold: true
        },
        {
            key: '2',
            title: 'Reconciled Quantity',
            value: isEmptyVariable(actualStocksData?.records?.totalRecoQty) 
            ? "-" : actualStocksData?.records?.totalRecoQty
        },
        {
            key: '4',
            title: 'Remarks',
            value: !isEmptyVariable(actualStocksData?.records?.remarks) ? actualStocksData?.records?.remarks : '-'
        }
    ];

    if(!isEmptyVariable(bookStockDetails?.quantity)){
        actualQtyDataSource.splice(2, 0, {
            key: '3',
            title: 'Difference',
            value: (!isEmptyVariable(actualStocksData?.records?.actualStockQuantity) ? 
            (actualStocksData?.records?.actualStockQuantity - (bookStockDetails?.quantity) + 
            (isEmptyVariable(actualStocksData?.records?.totalRecoQty) ? 0 : Number(actualStocksData?.records?.totalRecoQty))) : '-')
        })
    }
    
    const columns = [
        {
            title: 'Title',
            dataIndex: 'title',
            key: 'title',
            width:'40%',
            render: (text, record) => (
                <div style={{display:'flex'}}>
                    <div className={record.bold ? 'heading-item-bold' : 'heading-item'}>
                        {record.title}
                    </div>
                    <span className='separator'>:</span>
                </div>
            )
        },
        {
            title: 'Value',
            dataIndex: 'value',
            key: 'value',
            render: (text, record) => (
                <span className={record.bold ? 'value-bold' : ''} style={{flex:1}}>
                    {record.value} 
                    {record.isLast && 
                        <InfoCircleOutlined
                            onClick={() => setBookStockDetailsShow(true)}
                            style={{ 
                                marginLeft: 5,
                                verticalAlign: 'middle',
                                color: '#552b84',
                                fontSize: 14
                            }} 
                        />
                    }
                </span>
            ),
        },
    ];

    const uploadedFilesColumn = [
        {
            title:  'Uploaded File Name',
            key: 'fileName',
            width: '50%',
            render: (record) => {
                return(
                    <a
                        href="javascript:void(0);" 
                        onClick={() => {
                            generateFileUrl(record.uploadedFilePath)
                        }}
                        style={{ fontSize: "12px", color: "purple", textDecoration: "none", wordWrap: "break-word"}}>
                        {removeValueAfterHash(record.uploadedFilePath)}
                    </a>
                )
            }
        },
        {
            title:  'Description',
            key: 'description',
            dataIndex: 'description',
            width: '50%'
        }
    ];

    return (
        <>
            <Modal 
                open={showActualStockModal}
                title={"Actual Stock"}
                className="custom-modal"
                onCancel={handleCancel}
                footer={null}>
                <div className="table-container">
                    <CommonTable
                        dataSource={clientDataSource} 
                        columns={columns}
                        pagination={false}
                        showHeader={false}
                        size="small"
                        style={{ width: '280px' }}
                        className={'custom-borderless-table'}
                    />
                </div>
                <Divider type="horizontal"/>
                <div className="table-container">
                    <CommonTable
                        dataSource={bookQtyDataSource} 
                        columns={columns}
                        pagination={false}
                        showHeader={false}
                        size="small"
                        style={{ width: '280px' }}
                        className={'custom-borderless-table'}
                    />
                </div>
                <Divider type="horizontal" />
                <div className="table-container">
                    <CommonTable
                        dataSource={actualQtyDataSource} 
                        columns={columns}
                        pagination={false}
                        showHeader={false}
                        size="small"
                        style={{ width: '280px' }}
                        className={'custom-borderless-table'}
                    />
                </div>
                <Divider type="horizontal" />
                {!isEmptyArray(actualStocksData?.uploadedFiles) &&
                    <CommonTable
                        dataSource={actualStocksData?.uploadedFiles?.map((file, index) => {
                            return {
                                ...file,
                                key: index + 1,
                                index:index,
                                description: uploadedFileDescriptionArray.find((item, itemIndex) => itemIndex == index)?.description,
                            }
                        })} 
                        columns={uploadedFilesColumn}
                        pagination={false}
                    />
                }
            </Modal>
            {bookStockDetailsShow &&
                <Bookstockitem
                    bookStockDetailsShow={bookStockDetailsShow}
                    setBookStockDetailsShow={setBookStockDetailsShow}
                    details={bookStockDetails}
                    actualquantity={bookStockDetails?.actualquantity}
                />
            }
        </>
    )
}

export default ActualStockModal;