import React from 'react';
import { Modal, Button, Spinner } from 'react-bootstrap';
import './reusablemodal.css';
import CommonButton from '../../commonComponent/button/commonButton';
import { CloseOutlined } from '@ant-design/icons';

const ReusableModal = ({ show, onClose, onConfirm, message, buttonOk, buttonCancel, confirmButton, loading }) => {
  return (
    <Modal show={show} className='modal-width'>
      <Modal.Body style={{paddingRight: '5px', paddingTop: '5px'}}>
      <div style={{textAlign:'end'}}>
        <CommonButton
          style={{
              backgroundColor: 'transparent',
              boxShadow: '0 0 0 rgba(0, 0, 0, 0.045)'
          }}
          onClick={onClose}
          icon={<CloseOutlined
              style={{
                  fontSize: '15px',
                  verticalAlign: 'middle',
                  color: 'rgba(0, 0, 0, 0.85)',
                  paddingLeft: 10,
                  paddingRight: 10,
              }}
          />}
        />
        </div>
          <p style={{textAlign:'center', marginLeft:5, marginRight:10, fontSize: 16}}>{message}</p>
          <div style={{textAlign:'center', marginTop:20}}>
            {confirmButton ?
                <CommonButton
                  label={'Ok'}
                  onClick={onConfirm}
                />
              :
              <>
                <CommonButton
                  style={{backgroundColor: '#bbbbbb', color:'#fff'}}
                  label={buttonCancel ? buttonCancel : 'Cancel'}
                  onClick={onClose}
                />
                <CommonButton
                  style={{marginLeft: '10px'}}
                  loading={loading}
                  label={buttonOk ? buttonOk : 'Yes'}
                  onClick={onConfirm}
                />
              </>
            }
          </div>
      </Modal.Body>
    </Modal >
  );
};

export default ReusableModal;
